import { FILTER_STATUS_OPTIONS } from "constants/hygieneManage";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { get, isEmpty, isNull } from "lodash";
import moment from "moment";
import i18n from "../../../i18n/i18n";
import { CommonService } from "services/common-service";
import Auth from "helpers/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const commonService = new CommonService();

const getValueNotNull = (obj, key) => {
  const value = get(obj, key);
  if (isNull(value)) return "";
  return value;
};

const Detail = () => {
  const [report, setReport] = useState({});
  const [criterias, setCriterias] = useState([]);
  const [commentTemplates, setCommentTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const params = useParams();
  const id = get(params, "id");
  const history = useHistory();

  const formatReport = (rawReport) => {
    const attributes = get(rawReport, "attributes", {});
    const id = get(rawReport, "id");
    if (isEmpty(attributes)) {
      return {};
    } else {
      const company = {
        id: get(attributes, "company.data.id"),
        name: get(attributes, "company.data.attributes.name"),
      };
      const location = {
        id: get(attributes, "location.data.id"),
        name: get(attributes, "location.data.attributes.name"),
      };
      const createdAt = get(attributes, "createdAt", "");
      const confirmedDate = get(attributes, "confirmed_date");
      const totalPoint = get(attributes, "total_point");
      const statusValue = get(attributes, "status");
      const status = FILTER_STATUS_OPTIONS.find((s) => s.value === statusValue);
      const overview = get(attributes, "overview");
      const criterias = get(attributes, "report_criteria_links.data");
      return {
        id,
        company,
        location,
        confirmedDate: isNull(confirmedDate)
          ? ""
          : moment(confirmedDate).format("YYYY-MM-DD"),
        createdAt: moment(createdAt).format("YYYY/MM/DD"),
        totalPoint,
        status,
        overview,
        criterias,
      };
    }
  };

  const getParams = () => {
    const confirmed_date = report?.confirmedDate;
    const status = report?.status?.value;
    const overview = report?.overview;
    const currentUser = Auth.user().id;

    let totalPoint = 0;
    for (let i = 0; i < criterias.length; i++) {
      let point = get(criterias[i], "attributes.point", 0);
      if (point) {
        totalPoint += Number(point);
      }
    }

    const data = {
      confirmed_date,
      status,
      overview,
      confirmed_by: currentUser,
      total_point: totalPoint,
    };

    return {
      data,
    };
  };

  const handleChangeConfirmedDate = (e) => {
    const { value } = e.target;
    setReport({
      ...report,
      confirmedDate: value,
    });
  };

  const handleChangeStatus = (e) => {
    const { value } = e.target;
    const selectedStatus = FILTER_STATUS_OPTIONS.find(
      (status) => status.value === +value
    );
    setReport({
      ...report,
      status: selectedStatus,
    });
  };

  const handleChangeOverview = (e) => {
    const { value } = e.target;
    setReport({
      ...report,
      overview: value,
    });
  };

  const handleChangeCriteria = (e, id, field) => {
    const { value, max, min } = e.target;
    if (min && +value < min) return;
    if (max && +value > +max) return;
    const newCriterias = criterias.map((criteria) => {
      if (criteria.id === id) {
        return {
          ...criteria,
          attributes: {
            ...criteria.attributes,
            [field]: value,
          },
        };
      }
      return criteria;
    });
    setCriterias(newCriterias);
  };

  const handleChangeTemplates = (e, id) => {
    const { value } = e.target;
    const selectedTemplate = commentTemplates.find((c) => c.id === +value);
    if (selectedTemplate) {
      let newSelectedTemplates = [...selectedTemplates];
      const existItemIndex = newSelectedTemplates.findIndex(
        (t) => t.criteriaId === id
      );
      newSelectedTemplates.splice(existItemIndex, 1, {
        criteriaId: id,
        selectedId: selectedTemplate.id,
        content: selectedTemplate?.attributes?.content,
      });
      setSelectedTemplates(newSelectedTemplates);
    }
  };

  const handleChangeCriteriaComment = (id) => {
    const selectedTemplate = selectedTemplates.find(
      (template) => template.criteriaId === id
    );
    if (selectedTemplate) {
      const newCriterias = criterias.map((criteria) => {
        if (criteria.id === id) {
          return {
            ...criteria,
            attributes: {
              ...criteria.attributes,
              comment: selectedTemplate.content,
            },
          };
        }
        return criteria;
      });
      setCriterias(newCriterias);
    }
  };

  const handleUpdateCriterias = async () => {
    const promises = criterias.map((criteria) => {
      const id = criteria.id;
      const param = {
        data: {
          point: Number(criteria.attributes.point),
          comment: criteria.attributes.comment,
        },
      };
      const updateCriteria = new Promise((resolve, reject) => {
        resolve(commonService.updateReportCriteria(id, param));
      });
      return updateCriteria;
    });
    await Promise.all(promises);
  };

  const handleSubmit = async () => {
    const param = getParams();
    await commonService.updateReport(id, param);
    handleUpdateCriterias();
    history.push("/admin/manage-hygiene");
  };

  const fetchReportDetail = useCallback(async () => {
    const result = await commonService.getReport(
      `${id}?populate=location, company, report_criteria_links`
    );
    const data = get(result, "data.data", "");
    const formattedReport = formatReport(data);
    setReport(formattedReport);
    setCriterias(formattedReport.criterias);
  }, [id]);

  const fetchCommentTemplate = async () => {
    const result = await commonService.getCommentTemplates();
    const data = get(result, "data.data", "");
    setCommentTemplates(data);
  };

  useEffect(() => {
    fetchReportDetail();
  }, [id, fetchReportDetail]);

  useEffect(() => {
    fetchCommentTemplate();
  }, []);

  const renderImages = (images) => {
    return images.map((image) => (
      <div
        key={image.id}
        className="w-20 mx-2 image-box d-flex align-items-center bg-lighter"
      >
        <a href={image.attributes.url} target="_blank" rel="noreferrer">
          <img src={image.attributes.url} alt="" className="w-100" />
        </a>
      </div>
    ));
  };
  const renderCriteria = () => {
    if (!criterias.length) return null;
    return criterias.map((criteria, idx) => {
      const point = getValueNotNull(criteria, "attributes.point");
      const comment = getValueNotNull(criteria, "attributes.comment");
      const name = get(criteria, "attributes.name");
      const images = get(criteria, "attributes.report_images.data");
      const maxPoint = get(criteria, "attributes.maxPoint", 5);
      return (
        <div key={criteria.id}>
          <p className="font-weight-bold">{`${idx + 1}. ${name}`}</p>
          <div className="d-flex">{renderImages(images)}</div>
          <div className="mx-2 my-3">
            <label className="form-control-label">{i18n.t("point")}</label>
            <Input
              type="number"
              value={point}
              max={maxPoint}
              min={0}
              onChange={(e) => handleChangeCriteria(e, criteria.id, "point")}
              onInput={(e) => +e.target.value === Math.abs(+e.target.value)}
            ></Input>
          </div>
          <div className="mx-2 my-3">
            <label className="form-control-label">{i18n.t("comment")}</label>
            <div className="d-flex mb-2">
              <Input
                type="select"
                className="w-75 mr-2"
                onChange={(e) => handleChangeTemplates(e, criteria.id)}
              >
                <option>{ i18n.t("selectComment") }</option>
                {commentTemplates.map((comment) => (
                  <option key={comment.id} value={comment.id}>
                    {comment?.attributes?.title}
                  </option>
                ))}
              </Input>
              <Button
                className="bg-primary shadow-none border-0 text-white"
                onClick={() => handleChangeCriteriaComment(criteria.id)}
              >
                {i18n.t("insert")}
              </Button>
            </div>
            <Input
              type="textarea"
              value={comment}
              onChange={(e) => handleChangeCriteria(e, criteria.id, "comment")}
            ></Input>
          </div>
        </div>
      );
    });
  };
  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col className="text-center mb-4">
                  <h3 className="mb-0">
                    {i18n.t("screenTitle.hygieneManage")}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col xs="8" className="d-flex">
                  <div>
                    <label className="form-control-label">
                      {i18n.t("filter.location")}
                    </label>
                    <span className="font-weight-bold">
                      {report?.location?.name}
                    </span>
                  </div>
                  <div className="ml-4">
                    <label className="form-control-label">
                      {i18n.t("uploadDate")}：
                    </label>
                    <span className="font-weight-bold">
                      {getValueNotNull(report, "createdAt")}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="4">
                  <label className="form-control-label">
                    {i18n.t("confirmDate")}
                  </label>
                  <div>
                    <Input
                      type="date"
                      value={getValueNotNull(report, "confirmedDate")}
                      onChange={handleChangeConfirmedDate}
                    ></Input>
                  </div>
                </Col>
                <Col xs="5">
                  <label className="form-control-label">
                    {i18n.t("status")}
                  </label>
                  <Input
                    type="select"
                    value={report?.status?.value}
                    onChange={handleChangeStatus}
                  >
                    <option>{ i18n.t("status") }</option>
                    {FILTER_STATUS_OPTIONS.map((option) => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <label className="form-control-label">
                    {i18n.t("overview")}
                  </label>
                  <div>
                    <Input
                      type="textarea"
                      value={getValueNotNull(report, "overview")}
                      onChange={handleChangeOverview}
                    ></Input>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>{renderCriteria()}</Col>
              </Row>
            </CardHeader>
            <CardFooter className="text-center">
              <Button
                className="bg-success border-0 text-white"
                onClick={handleSubmit}
                disabled={report.confirmedDate === ""}
              >
                {i18n.t("save")}
              </Button>
            </CardFooter>
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default Detail;
