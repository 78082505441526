/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import Validator from '../../helpers/validator.js';
import { CommonService } from "../../services/common-service";
import i18n from '../../i18n/i18n';

const Login = () => {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [remember, setRemember] = useState('')
  const [invalidEmailText, setInvalidEmailText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const commonService = new CommonService();
  const history = useHistory();

  const handeLogin = async (e) => {
    e.preventDefault();
    if (validate()) {
      const param = {
        identifier: email,
        password: password
      }
      try {
        const result = await commonService.login(param);
        const jwt = get(result, "data.jwt", "");
        const userInfo = get(result, "data.user", "");

        if (jwt) {
          sessionStorage.setItem('jwt', jwt);
          sessionStorage.setItem('user', JSON.stringify(userInfo));

          if (remember) {
            localStorage.setItem('jwt', jwt);
            localStorage.setItem('user', JSON.stringify(userInfo));
          }

          window.location.href = "/admin/index";
        }
      } catch (err) {
        const errorMessage = get(err.response.data, "error.message");
        setErrorMessage(errorMessage);
      }
    }
  }

  const validate = () => {
    const mailValidator = Validator.checkEmail(email);
    const { isValidEmail, invalidEmailText } = mailValidator;

    setInvalidEmailText(invalidEmailText)

    return isValidEmail;
  }

  useEffect(() => {
    const jwt = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
    if (jwt) {
      history.push('/admin/index');
    }
  });

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>{i18n.t('singinTitle')}</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <br /><small style={{ color: "#b00" }}>{errorMessage}</small>
            </div>
            <Form role="form" onSubmit={handeLogin}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <small style={{ color: "#b00" }}>{invalidEmailText}</small>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="has-danger"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                  onChange={(e) => setRemember(e.target.checked)}
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">{i18n.t('rememberLogin')}</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  {i18n.t('login')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
