import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import i18n from "../../i18n/i18n";

const ModalConfirm = ({
  isOpen,
  onCancel,
  onDelete,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
    >
      <ModalHeader
        toggle={onCancel}
      >
        { i18n.t("confirmDelete") }
      </ModalHeader>
      <ModalBody>
        { i18n.t("contentConfirmDelete") }
      </ModalBody>
      <ModalFooter>
        <Button
          className="mt-4"
          color="secondary"
          type="button"
          onClick={onCancel}
        >
          { i18n.t("cancel") }
        </Button>
        <Button
          className="mt-4"
          color="primary"
          type="button"
          onClick={onDelete}
        >
          { i18n.t("confirmDelete") }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalConfirm;