import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import Proptypes from "prop-types";

CustomPagination.prototype = {
  pagination: Proptypes.object.isRequired,
  onPageChange: Proptypes.func,
};

CustomPagination.defaultProps = {
  onPageChange: null,
};

export default function CustomPagination(props) {
  const { pagination, onPageChange } = props;
  const { page, pageCount } = pagination;

  const handlePageChange = (e, newPage) => {
    e.preventDefault();

    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const RenderPageNumber = () => {
    const pageValue = {
      left: page >= pageCount ? page - 2 : page === 1 ? page : page - 1,
      center: page >= pageCount ? page - 1 : page === 1 ? page + 1 : page,
      right: page >= pageCount ? page : page === 1 ? page + 2 : page + 1,
    };
    if (pageCount >= 3) {
      return (
        <>
          <PaginationItem active={page === 1} key={Math.random().toString(36).substring(7)}>
            <PaginationLink onClick={(e) => handlePageChange(e, pageValue.left)}>{pageValue.left}</PaginationLink>
          </PaginationItem>
          <PaginationItem active={page < pageCount && page > 1} key={Math.random().toString(36).substring(7)}>
            <PaginationLink onClick={(e) => handlePageChange(e, pageValue.center)}>{pageValue.center}</PaginationLink>
          </PaginationItem>
          <PaginationItem active={page >= pageCount} key={Math.random().toString(36).substring(7)}>
            <PaginationLink onClick={(e) => handlePageChange(e, pageValue.right)}>{pageValue.right}</PaginationLink>
          </PaginationItem>
        </>
      );
    }
    return (
      <>
        {[...Array(pageCount)].map((key, value) => {
          return (
            <PaginationItem key={value} active={page === value + 1}>
              <PaginationLink
                onClick={(e) => {
                  handlePageChange(e, value + 1);
                }}
              >
                {value + 1}
              </PaginationLink>
            </PaginationItem>
          );
        })}
      </>
    );
  };

  return (
    <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
      <PaginationItem disabled={page <= 1}>
        <PaginationLink
          onClick={(e) => {
            handlePageChange(e, page - 1);
          }}
          tabIndex="-1"
        >
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
      <RenderPageNumber />
      <PaginationItem disabled={page >= pageCount}>
        <PaginationLink
          onClick={(e) => {
            handlePageChange(e, page + 1);
          }}
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
}
