import UserHeader from "../../../components/Headers/UserHeader";
import { Switch, Redirect, Route } from "react-router-dom";
import List from "./List"
import CreateEdit from "./CreateEdit"


const Companies = () => {
    const pathname = '/admin/company';
    return (
        <>
            <UserHeader />
            {/* Page content */}
            <Switch>
                <Route path={`${pathname}/list`} component={List}/>
                <Route path={`${pathname}/create`} component={CreateEdit}/>
                <Route path={`${pathname}/edit/:id`} component={CreateEdit}/>
                <Redirect from="*" to={`${pathname}/list`} />
            </Switch>
        </>
    )
};

export default Companies;