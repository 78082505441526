import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  Col,
  Button,
  Table,
  CardFooter,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import _, { get, isEqual } from "lodash";
import { useHistory } from "react-router-dom";

import CustomPagination from "../../../components/Pagination";
import { CommonService } from "../../../services/common-service";
import i18n from "../../../i18n/i18n";
import { CONSTANT } from "../../../constants/constant";
import ModalConfirm from "../../../components/Modal/Modal";
import InputAutocomplete from "../../../components/Input/InputAutocomplete";
import InputText from "../../../components/Input/InputText";

const commonService = new CommonService();
const pathname = "/admin/brand";

const OPTION_ALL = {
  value: -1,
  label: i18n.t("optionAll"),
};

const ListStore = () => {
  const history = useHistory();

  const [listStore, setListStore] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 1,
    pageCount: 2,
    total: 2,
  });
  const [filters, setFilters] = useState({
    pageSize: CONSTANT.PAGE_SIZE_LIMIT,
    page: 1,
  });
  const [isShowModal, setIsShowModal] = useState(false);
  const [storeIdDelete, setStoreIdDelete] = useState("");
  const [companySelected, setCompanySelected] = useState(OPTION_ALL);
  const [locationSelected, setLocationSelected] = useState(OPTION_ALL);
  const [isDisableLocationSelected, setIsDisableLocationSelected] = useState(true);
  const [storeName, setStoreName] = useState("");

  const fetchListStore = async () => {
    let params = {
      populate: "locations",
    };
    const { pageSize, page, company, location, name } = filters;
    params[`pagination[pageSize]`] = pageSize;
    params[`pagination[page]`] = page;

    if (company !== -1) {
      params['filters[locations][company][id][$eq]'] = company;
    }
    if (location !== -1) {
      params['filters[locations][id][$eq]'] = location;
    }
    if (name) {
      params['filters[name][$contains]'] = name;
    }
    
    const res = await commonService.getStores(params);
    const data = _.get(res, "data.data", []);
    const meta = _.get(res, "data.meta.pagination", {});

    setListStore(data);
    setPagination(meta);
  };

  const handlePageChange = (newPage) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  const handleCreateStore = () => {
    history.push(`${pathname}/create`);
  };

  const handleEdit = (id) => {
    history.push({
      pathname: `${pathname}/edit/${id}`,
    });
  };

  const handleDelete = async () => {
    await commonService.deleteStore(storeIdDelete);
    await fetchListStore();
    setIsShowModal(false);
  };

  const handleOpenModal = (id) => {
    setIsShowModal(true);
    setStoreIdDelete(id);
  };

  const onCancel = () => {
    setIsShowModal(false);
  };

  useEffect(() => {
    fetchListStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    let newFilters = {
      ...filters,
    };
    if (companySelected.value) {
      newFilters.company = companySelected.value;
    }
    if (locationSelected.value) {
      newFilters.location = locationSelected.value;
    }
    if (storeName) {
      newFilters.name = storeName;
    } else {
      delete newFilters.name;
    }
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySelected.value, locationSelected.value, storeName]);

  const formatOptions = (rawOptions) => {
    const data = get(rawOptions, "data.data", []);
    const formattedOptions = data.map((item) => {
      return {
        value: get(item, "id"),
        label: get(item, "attributes.name"),
      };
    });
    return {
      options: [OPTION_ALL, ...formattedOptions],
      hasMore: false,
    };
  };

  const fetchCompanies = async (search) => {
    let result;
    if (search) {
      result = await commonService.getCompanies({
        populate: "locations",
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getCompanies({
        populate: "locations",
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return formatOptions(result);
  };

  const fetchLocations = async (search) => {
    let result;
    if (search) {
      result = await commonService.getLocations({
        populate: "company",
        "filters[company][id][$in]": companySelected.value,
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getLocations({
        populate: "company",
        "filters[company][id][$in]": companySelected.value,
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return formatOptions(result);
  };

  const handleSelectCompany = (company) => {
    setCompanySelected(company);
    setIsDisableLocationSelected(false);
    setLocationSelected(OPTION_ALL);
  };

  const renderFilter = () => {

    return (
      <div className="mt-3">
        <InputAutocomplete
          label="filter.company"
          value={companySelected}
          loadOptions={fetchCompanies}
          onChange={handleSelectCompany}
          isHorizontal
          className="mb-3"
        />
        <InputAutocomplete
          key={companySelected.value}
          label="filter.location"
          value={locationSelected}
          loadOptions={fetchLocations}
          onChange={(location) => setLocationSelected(location)}
          isHorizontal
          isDisabled={isDisableLocationSelected}
          className="mb-3"
        />
        <InputText
          label="label.search"
          isFormGroup={false}
          isHorizontal
          value={storeName}
          onChange={setStoreName}
          placeholder="filter.placeholder"
        />
      </div>
    )
  };

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">{i18n.t("screenTitle.storeList")}</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={handleCreateStore}
                  >
                    {i18n.t("register")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="8">{ renderFilter() }</Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">{i18n.t("store.name")}</th>
                  <th scope="col">{i18n.t("store.affiliatedCompany")}</th>
                  <th scope="col">{i18n.t("store.baseLocation")}</th>
                  <th scope="col">{i18n.t("store.service")}</th>
                  <th scope="col">{i18n.t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {listStore.map((store, index) => {
                  return (
                    <tr key={store.id}>
                      <th>
                        <Media className="align-items-center">
                          <span className="mb-0 text-sm">
                            {store.attributes.name}
                          </span>
                        </Media>
                      </th>
                      <td>
                        { _.get(store, "attributes.companies.data", []).map((company) => {
                          return (
                            <div key={company.id}>{ _.get(company, "attributes.name") }</div>
                          )
                        })}
                      </td>
                      <td>
                        { _.get(store, "attributes.locations.data", []).map((location) => {
                          return (
                            <div key={location.id}>{ _.get(location, "attributes.name") }</div>
                          )
                        })}
                      </td>
                      <td>
                        { _.get(store, "attributes.ubereats_shop_name", "") && (
                          <div>
                            { i18n.t("store.uberEats") }
                          </div>
                        ) }
                        { _.get(store, "attributes.demaecan_id", "") && (
                          <div>
                            { i18n.t("store.demaecan") }
                          </div>
                        ) }
                        { _.get(store, "attributes.didi_id", "") && (
                          <div>
                            { i18n.t("store.didi") }
                          </div>
                        ) }
                        { _.get(store, "attributes.menu_id", "") && (
                          <div>
                            { i18n.t("store.menu") }
                          </div>
                        ) }
                        { _.get(store, "attributes.doordash_id", "") && (
                          <div>
                            { i18n.t("store.doordash") }
                          </div>
                        ) }
                      </td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              // href="#pablo"
                              onClick={(e) => {
                                handleEdit(store.id);
                              }}
                            >
                              {i18n.t("change")}
                            </DropdownItem>
                            <DropdownItem
                              // href="#pablo"
                              onClick={(e) => {
                                handleOpenModal(store.id);
                              }}
                            >
                              {i18n.t("delete")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <nav aria-label="...">
                <CustomPagination
                  pagination={pagination}
                  onPageChange={handlePageChange}
                />
              </nav>
            </CardFooter>
          </Card>
        </div>
      </Row>
      <ModalConfirm
        isOpen={isShowModal}
        onCancel={onCancel}
        onDelete={handleDelete}
      />
    </Container>
  );
};

export default ListStore;
