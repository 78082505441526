import { Line } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
} from "reactstrap";
import i18n from "./../../i18n/i18n";
import { OPTIONS } from "../../variables/charts";

const LineChart = ({ data, chartType, isShow, description }) => {
  return (
    <Card className='shadow'>
      <CardHeader className='bg-transparent'>
        <Row className='justify-content-center'>
          <h3 className='text-black mb-0'>
            {i18n.t("chart." + chartType)}
          </h3>
        </Row>
        { description && (
          <Row className='justify-content-center'>
            <h3 className='text-black mb-0'>
              { description }
            </h3>
          </Row>
        )}
      </CardHeader>
      <CardBody>
        <div className='chart'>
          <Row className='justify-content-center'>
            {isShow && (
              <Line
                data={data}
                options={OPTIONS}
                key={Math.random().toString(36).substring(7)}
              />
            )}
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};
export default LineChart;
