import axios from "axios";

export class HttpClient {
  axiosInstance;
  constructor() {
    const jwt = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
    if (jwt) {
      headers['Authorization'] = "Bearer " + jwt
    }
    
    let configs = {
      baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:1337/api',
      headers: headers,
      timeout: 60000,
      transformRequest: [
        (data, headers) => {
          if (data instanceof FormData) {
            if (headers) {
              delete headers["Content-Type"];
            }
            return data;
          }
          return JSON.stringify(data);
        },
      ],
    };
    this.axiosInstance = axios.create(configs);
  }
}
