import { Container, Row, Col } from "reactstrap";
import { CARD_TYPE } from "../../constants/card";
import CardDashBoard from "../Card/CardDashBoard";
import PropTypes from "prop-types";
import currencyFormatter from "currency-formatter";

const DashboardHeader = (props) => {
  const {
    totalActualTimeActive, storeReview,
    storeNumber, averageTime,
    wrongOrderRate, shipperReviewRate,
    prepTimeOrder, onlineRate,
    numberPrepTimeIncrease, salesTotal,
    storeNumberStandard,
    lossDueToNotWorking8Hours,
    targetTimeActive, predictSalesTotal, loading,
    salesTotalUberEats, salesTotalDemaecan,
    predictSalesTotalDemaecan, predictSalesTotalUberEats,
    locationName, brandName,
  } = props;

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col md="12">
                <span className="mb-0 text-lg font-weight-bold text-white">
                  {locationName}
                  { brandName ? " / " + brandName : "" }
                </span>
              </Col>
            </Row><br />
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["SUCCESSIVE_SALES_TOTAL_PREDICT"]}
                  data={currencyFormatter.format(predictSalesTotal, { code: "ja-JP" })}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["SUCCESSIVE_SALES_TOTAL_PREDICT_UBEREATS"]}
                  data={currencyFormatter.format(predictSalesTotalUberEats, { code: "ja-JP" })}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["SUCCESSIVE_SALES_TOTAL_PREDICT_DEMAECAN"]}
                  data={currencyFormatter.format(predictSalesTotalDemaecan, { code: "ja-JP" })}
                  loading={loading}
                />
              </Col>
            </Row><br />
            <Row>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["SUCCESSIVE_SALES_TOTAL"]}
                  data={currencyFormatter.format(salesTotal, { code: "ja-JP" })}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["SUCCESSIVE_SALES_TOTAL_UBEREATS"]}
                  data={currencyFormatter.format(salesTotalUberEats, { code: "ja-JP" })}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["SUCCESSIVE_SALES_TOTAL_DEMAECAN"]}
                  data={currencyFormatter.format(salesTotalDemaecan, { code: "ja-JP" })}
                  loading={loading}
                />
              </Col>
            </Row><br />
            <Row>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["AVERAGE_TIME"]}
                  data={averageTime + "h"}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["STORE_NUMBER"]}
                  data={storeNumber}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["STORE_NUMBER_STANDARD"]}
                  data={storeNumberStandard}
                  loading={loading}
                />
              </Col>
            </Row><br />
            <Row>
              <Col lg="6" xl="4">
                <CardDashBoard
                  data={totalActualTimeActive + 'h'}
                  cardType={CARD_TYPE["ACTUAL_TIME_ACTIVE"]}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["STORE_REVIEW"]}
                  data={storeReview}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["WRONG_ORDER_RATE"]}
                  data={wrongOrderRate + "%"}
                  loading={loading}
                />
              </Col>
            </Row><br />
            <Row>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["PREPTIME_ORDER"]}
                  data={prepTimeOrder + 'm'}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["TARGET_TIME_ACTIVE"]}
                  data={targetTimeActive + 'h'}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["SHIPPER_REVIEW"]}
                  data={shipperReviewRate + "%"}
                  loading={loading}
                />
              </Col>
            </Row><br />
            <Row>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["ONLINE_RATE"]}
                  data={onlineRate + "%"}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["LOSS_DUE_TO_NOT_WORKING_8_HOURS"]}
                  data={currencyFormatter.format(lossDueToNotWorking8Hours, { code: "ja-JP" })}
                  loading={loading}
                />
              </Col>
              <Col lg="6" xl="4">
                <CardDashBoard
                  cardType={CARD_TYPE["NUMBER_OF_TIMES_TO_EXTEND_PREP_TIME"]}
                  data={numberPrepTimeIncrease}
                  loading={loading}
                />
              </Col>
            </Row><br />
          </div>
        </Container>
      </div>
    </>
  );
};

PropTypes.propTypes = {
  averageTime: PropTypes.number.isRequired,
  storeNumber: PropTypes.number.isRequired,
  totalActualTimeActive: PropTypes.number.isRequired,
  storeReview: PropTypes.number.isRequired,
  wrongOrderRate: PropTypes.number.isRequired,
  shipperReviewRate: PropTypes.number.isRequired,
  prepTimeOrder: PropTypes.number.isRequired,
  onlineRate: PropTypes.number.isRequired,
  numberPrepTimeIncrease: PropTypes.number.isRequired,
  salesTotal: PropTypes.number.isRequired,
  storeNumberStandard: PropTypes.number.isRequired,
  lossDueToNotWorking8Hours: PropTypes.number.isRequired,
  targetTimeActive: PropTypes.number.isRequired,
  predictSalesTotal: PropTypes.number.isRequired,
  salesTotalUberEats: PropTypes.number.isRequired,
  salesTotalDemaecan: PropTypes.number.isRequired,
  predictSalesTotalDemaecan: PropTypes.number.isRequired,
  predictSalesTotalUberEats: PropTypes.number.isRequired,
  locationName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
};

export default DashboardHeader;
