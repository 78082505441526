import Dashboard from "./Dashboard";
import { Switch, Route, Redirect } from "react-router-dom";

const DashBoard = () => {
  const pathname = "/admin/dashboard";
  return (
    <>
      {/* Page content */}
      <Switch>
        <Route exact path={`${pathname}/all`} component={Dashboard} />
        <Route path={`${pathname}/location/:location_id`} component={Dashboard} />
        <Route path={`${pathname}/brand/:location_id/:brand_id`} component={Dashboard} />
        <Redirect from="*" to={`${pathname}/all`} />
      </Switch>
    </>
  );
};

export default DashBoard;
