import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Card, CardHeader, Col, Button, CardBody, Table, CardFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import i18n from "../../../i18n/i18n";
import CustomPagination from "../../../components/Pagination";
import { CONSTANT, ROLE } from "../../../constants/constant";
import { STATUS_OPTIONS } from "../../../constants/hygieneManage";
import { CommonService } from "../../../services/common-service";
import ModalConfirm from "../../../components/Modal/Modal";
import Auth from "../../../helpers/auth";

const commonService = new CommonService();

const List = () => {
  const history = useHistory();
  const [reports, setReports] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 1,
    pageCount: 2,
    total: 2,
  });
  const [filters, setFilters] = useState({
    pageSize: CONSTANT.PAGE_SIZE_LIMIT,
    page: 1,
  });
  const [isShowModal, setIsShowModal] = useState(false);
  const [reportIdDelete, setReportIdDelete] = useState("");

  const fetchReports = useCallback(async () => {
    try {
      let paramsProfile = {
        populate: "users_permissions_user, companies, locations",
        "filters[users_permissions_user][id][$eq]": Auth.user().id,
      };
      const profile = await commonService.getListProfile(paramsProfile);
      const roleName = _.get(profile, "data.data[0].attributes.role.data.attributes.name", "");

      let paramsReport = {
        populate: "company, location, report_criteria_links",
      };
      if (roleName === ROLE.MANAGE_COMPANY) {
        const companies = _.get(profile, "data.data[0].attributes.companies.data", []);
        let companyIds = [];

        for (let i = 0; i < companies.length; i++) {
          let id = _.get(companies[i], "id", "");
          companyIds.push(id);
        }

        paramsReport = {
          ...paramsReport,
          "filters[company][id][$in]": companyIds,
        };
      }

      if (roleName === ROLE.MANAGE_LOCATION) {
        const locations = _.get(profile, "data.data[0].attributes.locations.data", []);
        let locationIds = [];
        for (let i = 0; i < locations.length; i++) {
          let id = _.get(locations[i], "id", "");
          locationIds.push(id);
        }
        paramsReport = {
          ...paramsReport,
          "filters[location][id][$in]": locationIds,
        };
      }

      const { pageSize, page } = filters;
      paramsReport[`pagination[pageSize]`] = pageSize;
      paramsReport[`pagination[page]`] = page;

      const res = await commonService.getReports(paramsReport);
      const data = _.get(res, "data.data", []);
      const meta = _.get(res, "data.meta.pagination", "");

      setPagination(meta);
      setReports(data);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }, [filters]);

  useEffect(() => {
    fetchReports();
  }, [filters, fetchReports]);

  const handlePageChange = (newPage) => {
    const newFilters = {
      ...filters,
      page: newPage,
    };
    setFilters(newFilters);
  };

  const renderStatus = (status) => {
    switch (status) {
      case STATUS_OPTIONS.UPLOADED_NOT_YET_EVALUATE:
        return i18n.t("status.uploadedNotYetEvaluate");
      case STATUS_OPTIONS.WAIT_EVALUATE:
        return i18n.t("status.waitEvaluate");
      case STATUS_OPTIONS.EVALUATED:
        return i18n.t("status.evaluated");
      default:
        return "";
    }
  };

  const handleDeleteReport = async () => {
    await commonService.deleteReport(reportIdDelete);
    await fetchReports();
    setIsShowModal(false);
  };

  const handleOpenModal = (id) => {
    setReportIdDelete(id);
    setIsShowModal(true);
  };

  const handleCancel = () => {
    setIsShowModal(false);
  };

  const renderAction = (status, value) => {
    const id = _.get(value, "id", "");

    switch (status) {
      case STATUS_OPTIONS.UPLOADED_NOT_YET_EVALUATE:
        return (
          <Button className="bg-danger px-3 py-1 rounded text-white border-0 shadow-none shadow-none--hover" onClick={() => handleOpenModal(id)}>
            {i18n.t("delete")}
          </Button>
        );
      case STATUS_OPTIONS.WAIT_EVALUATE:
        return "";
      case STATUS_OPTIONS.EVALUATED:
        return (
          <Button onClick={() => history.push(`/admin/hygiene/detail/${id}`)} className="bg-success px-3 py-1 rounded text-white border-0 shadow-none shadow-none--hover">
            {i18n.t("detail")}
          </Button>
        );
      default:
        return "";
    }
  };

  const renderTable = () => {
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">{i18n.t("locationName")}</th>
            <th scope="col">{i18n.t("point")}</th>
            <th scope="col">{i18n.t("uploadDate")}</th>
            <th scope="col">{i18n.t("confirmDate")}</th>
            <th scope="col">{i18n.t("status")}</th>
            <th scope="col">{i18n.t("action")}</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((value, index) => {
            return (
              <tr key={index}>
                <td>{_.get(value, "attributes.location.data.attributes.name", "")}</td>
                <td>{_.get(value, "attributes.total_point", "") ? _.get(value, "attributes.total_point", "") : "-"}</td>
                <td>{_.get(value, "attributes.createdAt", "") ? moment(_.get(value, "attributes.createdAt", "")).format("YYYY/MM/DD") : "-"}</td>
                <td>{_.get(value, "attributes.confirmed_date", "") ? moment(_.get(value, "attributes.confirmed_date", "")).format("YYYY/MM/DD") : "-"}</td>
                <td>{renderStatus(_.get(value, "attributes.status", ""))}</td>
                <td>{renderAction(_.get(value, "attributes.status", ""), value)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">{i18n.t("screenTitle.hygieneList")}</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>{renderTable()}</CardBody>
            <CardFooter className="py-4">
              <nav aria-label="...">
                <CustomPagination pagination={pagination} onPageChange={handlePageChange} />
              </nav>
            </CardFooter>
          </Card>
        </div>
      </Row>
      <ModalConfirm isOpen={isShowModal} onCancel={handleCancel} onDelete={handleDeleteReport} />
    </Container>
  );
};

export default List;
