import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import Auth from "../../helpers/auth";
import i18n from "../../i18n/i18n";
import ModalChangePassword from "../Modal/ModalChangePassword";

const AdminNavbar = (props) => {
  const history = useHistory();
  const [isOpenModalChangePass, setIsOpenModalChangePass] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.replace("/auth/login");
  };

  const handleOpenChangePassword = () => {
    setIsOpenModalChangePass(true);
  };

  const onCancelModalChangePassword = () => {
    setIsOpenModalChangePass(false);
  };

  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <span
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block text-lg"
        >
          {props.brandText}
        </span>
        <div></div>
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <i className="fa fa-cog text-dark"></i>
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold text-darker">
                    { Auth.user().username }
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem onClick={handleOpenChangePassword}>
                <i className='fas fa-key'></i>
                <span>{ i18n.t("changePassword") }</span>
              </DropdownItem>
              <DropdownItem onClick={handleLogout}>
                <i className="ni ni-user-run" />
                <span>{ i18n.t("logout") }</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
      { isOpenModalChangePass && (
        <ModalChangePassword
          isOpen={isOpenModalChangePass}
          onCancel={onCancelModalChangePassword}
        />
      ) }
    </Navbar>
  );
};

export default AdminNavbar;
