import { ServiceBase } from "./core/service-base";
import { API_ROUTE } from "../constants/routes";

export class CommonService extends ServiceBase {
  // Implement method call API
  login = async (params) => {
    return await this.post(API_ROUTE.LOGIN, params);
  };

  getMe = async () => {
    return await this.get(API_ROUTE.GET_ME);
  };

  getCompanies = async (params) => {
    return await this.get(API_ROUTE.GET_COMPANIES, params);
  };

  getCompany = async (params) => {
    return await this.get(API_ROUTE.GET_COMPANY + "/" + params);
  };

  createCompany = async (params) => {
    return await this.post(API_ROUTE.CREATE_COMPANY, params);
  };

  editCompany = async (id, params) => {
    return await this.put(API_ROUTE.EDIT_COMPANY + "/" + id, params);
  };

  deleteCompany = async (params) => {
    return await this.delete(API_ROUTE.DELETE_COMPANY + "/" + params);
  };

  getLocations = async (params) => {
    return await this.get(API_ROUTE.GET_LOCATIONS, params);
  };

  getLocation = async (params) => {
    return await this.get(API_ROUTE.GET_LOCATION + "/" + params);
  };

  createLocation = async (params) => {
    return await this.post(API_ROUTE.CREATE_LOCATION, params);
  };

  editLocation = async (id, params) => {
    return await this.put(API_ROUTE.EDIT_LOCATION + "/" + id, params);
  };

  deleteLocation = async (params) => {
    return await this.delete(API_ROUTE.DELETE_LOCATION + "/" + params);
  };

  importCSVLocation = async (companyId, params) => {
    return await this.post(API_ROUTE.IMPORT_CSV_LOCATION + "/" + companyId, params);
  };

  getStores = async (params) => {
    return await this.get(API_ROUTE.GET_STORES, params);
  };

  getStore = async (params) => {
    return await this.get(API_ROUTE.GET_STORE + "/" + params);
  };

  createStore = async (params) => {
    return await this.post(API_ROUTE.CREATE_STORE, params);
  };

  editStore = async (id, params) => {
    return this.put(API_ROUTE.EDIT_STORE + "/" + id, params);
  };

  deleteStore = async (params) => {
    return this.delete(API_ROUTE.DELETE_STORE + "/" + params);
  };

  getRoles = async (params) => {
    return await this.get(API_ROUTE.GET_ROLES, params);
  };

  createUser = async (params) => {
    return await this.post(API_ROUTE.CREATE_USER, params);
  };

  getListUser = async (params) => {
    return await this.get(API_ROUTE.GET_USERS, params);
  };

  editUser = async (id, params) => {
    return this.put(API_ROUTE.EDIT_USERS + "/" + id, params);
  };

  deleteUser = async (params) => {
    return await this.delete(API_ROUTE.DELETE_USERS + "/" + params);
  };

  getListProfile = async (params) => {
    return await this.get(API_ROUTE.GET_PROFILES, params);
  };

  createProfile = async (params) => {
    return await this.post(API_ROUTE.CREATE_PROFILE, params);
  };

  getProfile = async (params) => {
    return await this.get(API_ROUTE.GET_PROFILE + "/" + params);
  };

  editProfile = async (id, params) => {
    return this.put(API_ROUTE.EDIT_PROFILE + "/" + id, params);
  };

  deleteProfile = async (params) => {
    return await this.delete(API_ROUTE.DELETE_PROFILE + "/" + params);
  };

  getBrands = async (params) => {
    return await this.get(API_ROUTE.GET_BRANDS, params);
  };

  createReport = async (params) => {
    return await this.post(API_ROUTE.CREATE_REPORT, params);
  };

  getReports = async (params) => {
    return await this.get(API_ROUTE.GET_REPORTS, params);
  };

  getReport = async (params) => {
    return await this.get(API_ROUTE.GET_REPORT + "/" + params);
  };

  updateReport = async (id, params) => {
    return this.put(API_ROUTE.UPDATE_REPORT + "/" + id, params);
  };

  deleteReport = async (id) => {
    return this.delete(API_ROUTE.DELETE_REPORT + "/" + id);
  };

  updateReportCriteria = async (id, params) => {
    return this.put(API_ROUTE.UPDATE_REPORT_CRITERIA + "/" + id, params);
  };

  getCommentTemplates = async (params) => {
    return await this.get(API_ROUTE.GET_COMMENT_TEMPLATES, params);
  };
  
  createUploadImage = async (params) => {
    return await this.post(API_ROUTE.CREATE_UPLOAD, params);
  };

  createReportImage = async (params) => {
    return await this.post(API_ROUTE.CREATE_REPORT_IMAGE, params)
  };

  deleteReportImage = async (id) => {
    return await this.delete(API_ROUTE.DELETE_REPORT_IMAGE + "/" + id);
  };

  createReportCriteriaLink = async (params) => {
    return await this.post(API_ROUTE.CREATE_REPORT_CRITERIA_LINKS, params);
  };

  deleteReportCriteriaLink = async (id) => {
    return await this.delete(API_ROUTE.DELETE_REPORT_CRITERIA_LINK + "/" + id);
  }

  getReportCriterias = async (params) => {
    return await this.get(API_ROUTE.GET_REPORT_CRITERIAS, params);
  };

  getReportImages = async (params) => {
    return await this.get(API_ROUTE.GET_REPORT_IMAGES, params);
  };

  changePassword = async (params) => {
    return await this.post(API_ROUTE.CHANGE_PASSWORD, params);
  };
  
}
