import moment from "moment";

export const getChartMonths = () => {
  const months = [];

  let month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  let date = new Date();
  date.setDate(1);
  for (let i = 0; i <= 11; i++) {
    months.push(date.getFullYear() + "/" + month[date.getMonth()])
    date.setMonth(date.getMonth() - 1);
  }
  months.reverse();
  return months;
};

export const getMonthInfo = (month) => {
  const dayInMonths = moment(month).daysInMonth();
  const startDate = moment(month).startOf("month").format();
  const endDate = moment(month).endOf("month").format();
  return [startDate, endDate, dayInMonths];
};
