export const API_ROUTE = {
  LOGIN: "auth/local",
  GET_COMPANIES: "companies",
  GET_COMPANY: "companies",
  CREATE_COMPANY: "companies",
  EDIT_COMPANY: "companies",
  DELETE_COMPANY: "companies",
  GET_LOCATIONS: "locations",
  GET_LOCATION: "locations",
  CREATE_LOCATION: "locations",
  EDIT_LOCATION: "locations",
  DELETE_LOCATION: "locations",
  IMPORT_CSV_LOCATION: "locations/import-csv",
  GET_STORES: "stores",
  GET_STORE: "stores",
  CREATE_STORE: "stores",
  EDIT_STORE: "stores",
  DELETE_STORE: "stores",
  GET_ROLES: "users-permissions/roles",
  CREATE_USER: "users",
  GET_USERS: "users",
  EDIT_USERS: "users",
  DELETE_USERS: "users",
  GET_BRANDS: "brands",
  GET_PROFILES: "profiles",
  CREATE_PROFILE: "profiles",
  GET_PROFILE: "profiles",
  EDIT_PROFILE: "profiles",
  DELETE_PROFILE: "profiles",
  GET_ME: "/users/me",
  GET_REPORTS: "reports",
  GET_REPORT: "reports",
  UPDATE_REPORT: "reports",
  UPDATE_REPORT_CRITERIA: "report-criteria-links",
  GET_COMMENT_TEMPLATES: "comment-templates",
  CREATE_UPLOAD: "upload",
  CREATE_REPORT: "reports",
  CREATE_REPORT_IMAGE: "report-images",
  CREATE_REPORT_CRITERIA_LINKS: "report-criteria-links",
  GET_REPORT_CRITERIAS: "report-criterias",
  DELETE_REPORT: "reports",
  DELETE_REPORT_IMAGE: "report-images",
  DELETE_REPORT_CRITERIA_LINK: "report-criterias",
  GET_REPORT_IMAGES: "report-images",
  CHANGE_PASSWORD: "profiles/change-password",
};
