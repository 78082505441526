import { get, isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import CustomPagination from "../../../components/Pagination";
import { CommonService } from "../../../services/common-service";
import i18n from "../../../i18n/i18n";
import { CONSTANT } from "../../../constants/constant";
import Auth from "../../../helpers/auth";
import ModalConfirm from "../../../components/Modal/Modal";
import InputAutocomplete from "../../../components/Input/InputAutocomplete";
import InputText from "../../../components/Input/InputText";

const commonService = new CommonService();

const OPTION_ALL = {
  value: -1,
  label: i18n.t("optionAll"),
};

const List = () => {
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 1,
    pageCount: 2,
    total: 2,
  });
  const [filters, setFilters] = useState({
    pageSize: CONSTANT.PAGE_SIZE_LIMIT,
    page: 1,
  });
  const [userIdDelete, setUserIdDelete] = useState("");
  const [profileIdDelete, setProfileIdDelete] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [companySelected, setCompanySelected] = useState(OPTION_ALL);
  const [accountName, setAccountName] = useState("");
  const pathname = "/admin/accounts";

  const getListProfiles = useCallback(async () => {
    let params = {
      populate: "users_permissions_user, companies",
    };
    const { pageSize, page, name, company } = filters;
    params[`pagination[pageSize]`] = pageSize;
    params[`pagination[page]`] = page;

    if (company !== -1) {
      params[`filters[companies][id][$eq]`] = company;
    }
    if (name) {
      params[`filters[users_permissions_user][username][$contains]`] = name;
    }

    const result = await commonService.getListProfile(params);
    const data = get(result, "data.data", []);
    const meta = get(result, "data.meta.pagination", {});

    setUserList(data);
    setPagination(meta);
  }, [filters]);

  const handleRegister = () => {
    history.push("/admin/accounts/register");
  };

  const handlePageChange = (newPage) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  const handleEdit = (id) => {
    history.push({
      pathname: `${pathname}/edit/${id}`,
    });
  };

  const handleDelete = async () => {
    await commonService.deleteUser(userIdDelete);
    await commonService.deleteProfile(profileIdDelete);
    getListProfiles();
    setIsShowModal(false);
  };

  const handleOpenModal = async (profile) => {
    const profileId = get(profile, 'id');
    const userId = get(profile, 'attributes.users_permissions_user.data.id');
    setUserIdDelete(userId);
    setProfileIdDelete(profileId);
    setIsShowModal(true);
  };

  const onCancel = () => {
    setIsShowModal(false);
  };

  useEffect(() => {
    getListProfiles();
  }, [filters, getListProfiles]);

  const renderUserStatus = (status) => {
    const statusText = status ? i18n.t("inactive") : i18n.t("active")
    const statusClassname = `bg-${!status ? 'success': 'danger'}`
    return (
      <td>
        <Badge color='' className='badge-dot mr-4'>
          <i className={statusClassname} />
          {statusText}
        </Badge>
      </td>
    )
  };

  const renderUsers = (users) => {
    return users.map((user) => {
      const userInfo = get(user, "attributes.users_permissions_user.data.attributes", {});
      const email = get(userInfo, "email");
      const userName = get(userInfo, "username");
      const status = get(userInfo, "blocked");
      const userId = get(user, "attributes.users_permissions_user.data.id", "");
      
      return (
        <tr key={user.id}>
          <th scope='row'>
            <Media className='align-items-center'>
              <Media>
                <span className='mb-0 text-sm'>{userName}</span>
              </Media>
            </Media>
          </th>
          <td>
            {get(user, "attributes.companies.data", []).map(
              (company, index) => {
                return (
                  <div key={index}>{get(company, "attributes.name", "")}</div>
                );
              }
            )}
          </td>
          <td>{email}</td>
          {renderUserStatus(status)}
          <td>
            <UncontrolledDropdown>
              <DropdownToggle
                className='btn-icon-only text-light'
                href='#pablo'
                role='button'
                size='sm'
                color=''
                onClick={(e) => e.preventDefault()}
              >
                <i className='fas fa-ellipsis-v' />
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-arrow' right>
                <DropdownItem
                  onClick={(e) => {
                    handleEdit(user.id);
                  }}
                >
                  {i18n.t("change")}
                </DropdownItem>
                { userId !== Auth.user().id && (
                  <DropdownItem
                    href='#pablo'
                    onClick={(e) => {
                      handleOpenModal(user);
                    }}
                  >
                    {i18n.t("delete")}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };

  useEffect(() => {
    let newFilters = {
      ...filters,
    };
    if (companySelected.value) {
      newFilters.company = companySelected.value;
    }
    if (accountName) {
      newFilters.name = accountName;
    } else {
      delete newFilters.name;
    }
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySelected.value, accountName]);

  const formatOptions = (rawOptions) => {
    const data = get(rawOptions, "data.data", []);
    const formattedOptions = data.map((item) => {
      return {
        value: get(item, "id"),
        label: get(item, "attributes.name"),
      };
    });
    return {
      options: [OPTION_ALL, ...formattedOptions],
      hasMore: false,
    };
  };

  const fetchCompanies = async (search) => {
    let result;
    if (search) {
      result = await commonService.getCompanies({
        populate: "locations",
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getCompanies({
        populate: "locations",
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return formatOptions(result);
  };

  const handleSelectCompany = (company) => {
    setCompanySelected(company);
  };

  const renderFilter = () => {
    return (
      <div className="mt-3">
        <InputAutocomplete
          label="filter.company"
          isHorizontal
          isFormGroup={false}
          value={companySelected}
          loadOptions={fetchCompanies}
          onChange={handleSelectCompany}
          className="mb-3"
        />
        <InputText
          label="label.search"
          isHorizontal
          isFormGroup={false}
          value={accountName}
          onChange={setAccountName}
          placeholder="filter.placeholder"
        />
      </div>
    )
  };

  return (
    <>
      <Container className='mt--7' fluid>
        {/* Table */}
        <Row>
          <div className='col'>
            <Card className='shadow'>
              <CardHeader className='border-0'>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>
                      {i18n.t("screenTitle.accountList")}
                    </h3>
                  </Col>
                  <Col className='text-right' xs='4'>
                    <Button
                      color='primary'
                      onClick={handleRegister}
                      type='button'
                      size='sm'
                    >
                      {i18n.t("register")}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs='8'>{ renderFilter() }</Col>
                </Row>
              </CardHeader>
              <Table className='align-items-center table-flush' responsive>
                <thead className='thead-light'>
                  <tr>
                    <th scope='col'>{i18n.t("username")}</th>
                    <th scope='col'>{i18n.t("affiliatedCompany")}</th>
                    <th scope='col'>{i18n.t("email")}</th>
                    <th scope='col'>{i18n.t("status")}</th>
                    <th scope='col'>{i18n.t("action")}</th>
                  </tr>
                </thead>
                <tbody>{renderUsers(userList)}</tbody>
              </Table>
              <CardFooter className='py-4'>
                <nav aria-label='...'>
                  <CustomPagination
                    pagination={pagination}
                    onPageChange={handlePageChange}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <ModalConfirm
        isOpen={isShowModal}
        onCancel={onCancel}
        onDelete={handleDelete}
      />
    </>
  );
};
export default List;
