import Index from "views/Index.js";
import Dashboard from "views/admin/dashboard";
import Login from "views/auths/Login.js";
import Accounts from "views/admin/account/Accounts.js";
import Companies from "views/admin/company";
import Locations from "views/admin/location";
import Stores from "views/admin/store";
import PhotoUpload from "views/admin/photo-upload";
import Hygienes from "views/admin/hygiene";
import i18n from "i18n/i18n";
import { ROLE } from "./constants/constant";
import ManageHygiene from "views/admin/manageHygiene";

var routes = [
  {
    path: "/index",
    name: i18n.t("sideBar.home"),
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    type: "sideBar",
    roles: [ROLE.SUPER_ADMIN, ROLE.MANAGE_COMPANY, ROLE.MANAGE_LOCATION],
  },
  {
    path: "/manage-hygiene",
    name: i18n.t("sideBar.hygieneManage"),
    icon: "fas fa-tasks",
    component: ManageHygiene,
    layout: "/admin",
    type: "sideBar",
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    path: "/dashboard",
    name: i18n.t("sideBar.saleReport"),
    icon: "ni ni-chart-bar-32 text-primary",
    component: Dashboard,
    layout: "/admin",
    type: "sideBar",
    // roles: [ROLE.MANAGE_COMPANY, ROLE.MANAGE_LOCATION],
    roles: [],
  },
  {
    name: i18n.t("sideBar.hygieneManagement"),
    icon: "ni ni-bullet-list-67 text-red",
    layout: "/admin",
    type: "sideBar",
    roles: [ROLE.MANAGE_COMPANY, ROLE.MANAGE_LOCATION],
    subNav: [
      {
        path: "/photo-upload",
        name: i18n.t("screen.photoUpload"),
        icon: "fa fa-upload",
        component: PhotoUpload,
        layout: "/admin",
      },
      {
        path: "/hygiene",
        name: i18n.t("screen.hygiene"),
        icon: "fa fa-hand-sparkles",
        component: Hygienes,
        layout: "/admin",
      }
    ]
  },
  {
    name: i18n.t('sideBar.merchantManagement'),
    icon: "ni ni-bullet-list-67 text-red",
    layout: "/admin",
    roles: [ROLE.SUPER_ADMIN],
    subNav: [
      {
        path: "/company",
        name: i18n.t("screen.company"),
        icon: "ni ni-building",
        component: Companies,
        layout: "/admin",
      },
      {
        path: "/location",
        name: i18n.t("screen.location"),
        icon: "ni ni-world",
        component: Locations,
        layout: "/admin",
      },
      {
        path: "/brand",
        name: i18n.t("screen.store"),
        icon: "fa fa-store",
        component: Stores,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/accounts",
    name: i18n.t("sideBar.account"),
    icon: "ni ni-circle-08",
    component: Accounts,
    layout: "/admin",
    roles: [ROLE.SUPER_ADMIN],
  },
  {
    path: "/login",
    name: i18n.t("singinTitle"),
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
];
export default routes;
