import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UserHeader from "../../../components/Headers/UserHeader";
import PhotoUpload from "./PhotoUpload";

const Upload = () => {
  const pathname = "/admin/photo-upload";
  return (
    <>
      <UserHeader />
      <Switch>
        <Route path={pathname} component={PhotoUpload} />
        <Redirect from="*" to={pathname} />
      </Switch>
    </>
  )
};

export default Upload;