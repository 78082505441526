import React, { useState }  from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import _ from "lodash";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";

import i18n from "i18n/i18n";
import { CommonService } from "../../../services/common-service";
import { CONSTANT, HEADER_CSV_LOCATION, DATA_CSV_LOCATION_EXAMPLE } from "../../../constants/constant";
import Validator from "../../../helpers/validator";
import Auth from "../../../helpers/auth";
import "./styles.scss";

const commonService = new CommonService();

const ImportCSV = () => {
  const history = useHistory();

  const [companySelected, setCompanySelected] = useState({});
  const [fileCSV, setFileCSV] = useState();
  const [fileName, setFileName] = useState(i18n.t("chooseFile"));
  const [errorTypes, setErrorTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");

  const handleSelectCompany = (props) => {
    setCompanySelected(props);
  };

  async function companyLoadOptions(search, loadOptions) {
    let result;
    if (search) {
      result = await commonService.getCompanies({
        populate: "locations",
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getCompanies({
        populate: "locations",
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return handleLoadOption(result);
  };

  const handleLoadOption = async (result) => {
    const data = _.get(result, "data.data", "");
    let list = [];
    if (data) {
      data.map((item) => {
        list.push({
          value: item["id"],
          label: item["attributes"]["name"],
        });
        return list;
      });
    }
    return {
      options: list,
      hasMore: false,
    };
  };

  const validate = (params) => {
    let errors = {};
    for (const item in params) {
      let errorType = Validator.emptyValue(item, params);
      if (errorType) {
        errors[item] = errorType;
      }
    }
    setErrorTypes(errors);
    return _.isEmpty(errors);
  };

  const handleChangeFile = (e) => {
    setFileCSV(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleSubmit = async () => {
    let params = {
      company: companySelected.value,
      files: fileCSV
    };
    const isValid = validate(params);
    if (isValid) {
      handleImportCSV();
    }
  };

  const handleImportCSV = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("files", fileCSV);
      formData.append("user_created_id", Auth.user().id);

      await commonService.importCSVLocation(companySelected.value, formData);

      setLoading(false);
      history.push("/admin/location/list");
    } catch (error) {
      const errorMessage = _.get(error, "response.data.error.message", "");
      console.log("error", errorMessage);
      setErrorResponse(errorMessage);
      setLoading(false);
    }
  };

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className='align-items-center'>
                <Col xs='8'>
                  <h3 className='mb-0'>{i18n.t("screenTitle.importCSVLocation")}</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form role='form'>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-company"
                        >
                          { i18n.t("affiliatedCompany") }
                        </label>
                        <AsyncPaginate
                          placeholder={i18n.t("placeholderCompany")}
                          value={companySelected}
                          loadOptions={companyLoadOptions}
                          onChange={(e) => handleSelectCompany(e)}
                          styles={{ menu: (styles) => {
                            return {
                              ...styles,
                              zIndex: 10
                            }
                          }}}
                        />
                        <small style={{ color: "#b00" }}>
                          {i18n.t(errorTypes["company"]).replace("(param1)", i18n.t("affiliatedCompany"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-locationCSV"
                        >
                          { i18n.t("locationCSV") }
                        </label>
                        <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">{ i18n.t("upload") }</span>
                        </div>
                        <div className="custom-file">
                          <Input accept=".csv" type="file" className="custom-file-input" id="inputGroupFile01" onChange={handleChangeFile} lang="ja" />
                          <label className="custom-file-label" htmlFor="inputGroupFile01">{ fileName }</label>
                        </div>
                      </div>
                      <small style={{ color: "#b00" }}>
                        {i18n.t(errorTypes["files"]).replace("(param1)", i18n.t("locationCSV"))}
                      </small>
                      <small style={{ color: "#b00" }}>
                        {errorResponse}
                      </small>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <CSVLink
                        headers={HEADER_CSV_LOCATION}
                        data={DATA_CSV_LOCATION_EXAMPLE}
                        filename={"example-data.csv"}
                      >
                        { i18n.t("downloadCSV") }
                      </CSVLink>
                    </Col>
                  </Row>
                </div>

                <div className='text-center'>
                  <Button
                    className='mt-4'
                    color='primary'
                    type='button'
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    { loading ? (<Spinner animation="border" size="sm" />) : `${i18n.t("save")}`}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Container>
  )
}

export default ImportCSV;
