/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import _ from "lodash";
import moment from "moment";
import currencyFormatter from "currency-formatter";
import LineChart from "../../../components/Chart/LineChart";
import { CHART_TYPE } from "../../../constants/charts";
import DashboardHeader from "../../../components/Headers/DashboardHeader.js";
import Loading from "../../../components/Loading/Loading";
import { useParams } from "react-router-dom";
import { CommonService } from "../../../services/common-service";
import { getChartMonths } from "helpers/date";
import { CHART_DATA } from "../../../constants/charts";
import { getMonthInfo } from "helpers/date";
import {
  getUberEatsInactiveTimes,
  getUbereatsFeedbackServices,
  getUbereatsOrderProblems,
  getUbereatsPaymentInfos,
  getUbereatsOrderHistories,
  getUbereatsFeedbackMenus,
  getDemaecanOrderInfos,
} from "../../../services/graphql/query";
import i18n from "i18n/i18n";
import Auth from "../../../helpers/auth";
import { CONSTANT } from "../../../constants/constant";

const commonService = new CommonService();

const Dashboard = () => {
  const { location_id, brand_id } = useParams();
  const [storeIds, setStoreIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saleTrendsChartData, setSaleTrendsChartData] = useState({});
  const [isShowSaleTrendsChart, setIsShowSaleTrendsChart] = useState(false);
  const [ratingStoreChartData, setRatingStoreChartData] = useState({});
  const [isShowRatingStoreChartData, setIsShowRatingStoreChartData] =
    useState(false);
  const [operationQuality1ChartData, setOperationQuality1ChartData] = useState(
    {}
  );
  const [isShowOperationQuality1Chart, setIsShowOperationQuality1Chart] =
    useState(false);
  const [operationQuality2ChartData, setOperationQuality2ChartData] = useState(
    {}
  );
  const [isShowOperationQuality2Chart, setIsShowOperationQuality2Chart] =
    useState(false);
  const [operationQuality3ChartData, setOperationQuality3ChartData] = useState(
    {}
  );
  const [isShowOperationQuality3Chart, setIsShowOperationQuality3Chart] =
    useState(false);
  const [makeMistakeChartData, setMakeMistakeChartData] = useState({});
  const [isShowMakeMistakeChart, setIsShowMakeMistakeChart] = useState(false);
  const [activeTimeChartData, setActiveTimeChartData] = useState({});
  const [isShowChartActiveTime, setIsShowChartActiveTime] = useState(false);
  const [orderTimeChartData, setOrderTimeChartData] = useState({});
  const [isShowOrderTimeChart, setIsShowOrderTimeChart] = useState(false);
  const months = useMemo(() => getChartMonths(), []);
  const [averageTime, setAverageTime] = useState(0);
  const [storeNumber, setStoreNumber] = useState(0);
  const [storeNumberActive, setStoreNumberActive] = useState(0);
  const [storeNumberStandard, setStoreNumberStandard] = useState(0);
  const [totalActualTimeActive, setTotalActualTimeActive] = useState(0);
  const [storeReview, setStoreReview] = useState(0);
  const [targetTimeActive, setTargetTimeActive] = useState(0);
  const [wrongOrderRate, setWrongOrderRate] = useState(0);
  const [shipperReviewRate, setShiperReviewRate] = useState(0);
  const [prepTimeOrder, setPrepTimeOrder] = useState(0);
  const [onlineRate, setOnlineRate] = useState(0);
  const [productReviewRate, setProductReviewRate] = useState(0);
  const [numberPrepTimeIncrease, setNumberPrepTimeIncrease] = useState(0);
  const [predictSalesTotal, setPredictSalesTotal] = useState(0);
  const [salesTotal, setSalesTotal] = useState(0);
  const [lossDueToNotWorking8Hours, setLossDueToNotWorking8Hours] = useState(0);
  const [salesTotalUberEats, setSalesTotalUberEats] = useState(0);
  const [salesTotalDemaecan, setSalesTotalDemaecan] = useState(0);
  const [predictSalesTotalUberEats, setPredictSalesTotalUberEats] = useState(0);
  const [predictSalesTotalDemaecan, setPredictSalesTotalDemaecan] = useState(0);
  const [locationName, setLocationName] = useState("");
  const [brandName, setBrandName] = useState("");

  const setDataDefault = () => {
    setAverageTime(0);
    setStoreNumber(0);
    setStoreNumberActive(0);
    setStoreNumberStandard(0);
    setTotalActualTimeActive(0);
    setStoreReview(0);
    setTargetTimeActive(0);
    setWrongOrderRate(0);
    setShiperReviewRate(0);
    setPrepTimeOrder(0);
    setOnlineRate(0);
    setProductReviewRate(0);
    setNumberPrepTimeIncrease(0);
    setPredictSalesTotal(0);
    setSalesTotal(0);
    setLossDueToNotWorking8Hours(0);
    setSalesTotalUberEats(0);
    setSalesTotalDemaecan(0);
    setPredictSalesTotalUberEats(0);
    setPredictSalesTotalDemaecan(0);
    setSaleTrendsChartData({});
    setRatingStoreChartData({});
    setOperationQuality1ChartData({});
    setOperationQuality2ChartData({});
    setOperationQuality3ChartData({});
    setMakeMistakeChartData({});
    setActiveTimeChartData({});
    setOrderTimeChartData({});
  }

  const getLocationById = async (id) => {
    try {
      const res = await commonService.getLocation(`${id}?populate=stores`);
      const stores = _.get(res, "data.data.attributes.stores.data", []);

      let arrStoreIdsInLocation = [];
      for (let i = 0; i < stores.length; i++) {
        arrStoreIdsInLocation = [...arrStoreIdsInLocation, stores[i].id];
      }

      setStoreIds([...arrStoreIdsInLocation]);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const getAllChartData = async (storeIds) => {
    await Promise.all([
      getSaleTrendsChartData(storeIds),
      getRatingStoreChartData(storeIds),
      getOperationQuality1ChartData(storeIds),
      getOperationQuality2ChartData(storeIds),
      getOperationQuality3ChartData(storeIds),
      getMakeMistakeChartData(storeIds),
      getActiveTimeChartData(storeIds),
      getOrderTimeChartData(storeIds),
    ]);
  };

  const tableStatistical = async (arrStoreIds) => {
    if (!arrStoreIds.length) return;
    setLoading(true);
    const startDayInMonth = moment().startOf("month").format();
    const endDayInMonth = moment().endOf("month").format();

    const storeNumber = calculateStoreNumber(arrStoreIds);
    setStoreNumber(storeNumber);

    const actualTime = await calculateActualActivityTime(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setTotalActualTimeActive(actualTime.toFixed(2));

    const storeReview = await calculateStoreReview(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setStoreReview(storeReview);

    const averageTimeOfMonth =
      calculateAverageTime(actualTime).toFixed(2);
    setAverageTime(averageTimeOfMonth);

    const wrongOrderRate = await calculateWrongOrderRate(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setWrongOrderRate(wrongOrderRate);

    const shipperReviewRate = await calculateShipperReviewRate(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setShiperReviewRate(shipperReviewRate);

    const prepTimeOrder = await calculatePrepTimeOrder(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setPrepTimeOrder(prepTimeOrder);

    const onlineRate = await calculateOnlineRate(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setOnlineRate(onlineRate);

    const productReviewRate = await calculateProductReviewRate(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setProductReviewRate(productReviewRate);

    const numberPrepTimeIncrease = await calculateNumberPrepTimeIncrease(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setNumberPrepTimeIncrease(numberPrepTimeIncrease);

    const { predictSalesTotal, predictSalesTotalUbereats, predictSalesTotalDemaecan } = await calculateSalesTotalPredict(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setPredictSalesTotal(predictSalesTotal);
    setPredictSalesTotalUberEats(predictSalesTotalUbereats);
    setPredictSalesTotalDemaecan(predictSalesTotalDemaecan);

    const { total, totalUberEats, totalDemaecan } = await calculateSalesTotal(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setSalesTotal(total);
    setSalesTotalUberEats(totalUberEats);
    setSalesTotalDemaecan(totalDemaecan);

    const storeNumberActive = await calculateNumberStoreActive(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setStoreNumberActive(storeNumberActive);

    const storeNumberStandard = await calculateStoreStandard(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setStoreNumberStandard(storeNumberStandard);

    const lossDueToNotWorking8Hours = await calculateLossDueToNotWorking8Hours(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setLossDueToNotWorking8Hours(lossDueToNotWorking8Hours);

    const targetTimeActive = calculateTargetTimeActive(
      arrStoreIds,
      startDayInMonth,
      endDayInMonth
    );
    setTargetTimeActive(targetTimeActive);
    setLoading(false);
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (unit: number)
   */
  const calculateSalesTotalPredict = async (
    arrStoreIds,
    startDate,
    endDate
  ) => {
    try {
      let params = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
      };

      const res = await getUberEatsInactiveTimes(params);
      const datas = _.get(res, "data.data.ubereatsInactiveTimes.data", []);
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      if (isMultipleTime) {
        let total = await calculateSalesTotal(arrStoreIds, startDate, endDate);
        let m = startDateTime;
        let result = [];
        let i = 0;
        while (endDateTime.diff(m, "months") >= 0) {
          let predictSalesTotal = 0;
          const data = datas.filter((d) =>
            m.isSame(d.attributes.date, "month")
          );
          let arrDate = [];
          for (let i = 0; i < data.length; i++) {
            let date = _.get(data[i], "attributes.date", "");
            arrDate = [...arrDate, moment(date).format("YYYY-MM-DD")];
          }
          let totalDayActive = new Set(arrDate).size;
          const totalDaysInMonth = m.daysInMonth();
          if (totalDayActive !== 0) {
            predictSalesTotal = (total[i] / totalDayActive) * totalDaysInMonth;
          }
          i++;
          result.push(predictSalesTotal);
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        let arrDate = [];
        let { total, totalUberEats, totalDemaecan } = await calculateSalesTotal(arrStoreIds, startDate, endDate);
        for (let i = 0; i < datas.length; i++) {
          let date = _.get(datas[i], "attributes.date", "");
          arrDate = [...arrDate, moment(date).format("YYYY-MM-DD")];
        }
        let totalDayActive = new Set(arrDate).size;
        const totalDaysInMonth =
          moment(endDate).diff(moment(startDate), "days") + 1;
          
        let predictSalesTotalUbereats = 0;
        let predictSalesTotalDemaecan = 0;
        let predictSalesTotal = 0;

        if (totalDayActive !== 0) {
          predictSalesTotal = (total / totalDayActive) * totalDaysInMonth;
          predictSalesTotalUbereats = (totalUberEats / totalDayActive) * totalDaysInMonth;
          predictSalesTotalDemaecan = (totalDemaecan / totalDayActive) * totalDaysInMonth;
        }
        return {
          predictSalesTotal,
          predictSalesTotalUbereats,
          predictSalesTotalDemaecan
        };
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (unit: number)
   */
  const calculateSalesTotal = async (arrStoreIds, startDate, endDate) => {
    let total = 0;
    try {
      let params1 = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
      };
      let params2 = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
        productPriceWithtax: 0,
      };
      const res1 = await getDemaecanOrderInfos(params1);
      const res2 = await getUbereatsPaymentInfos(params2);
      const data1 = _.get(res1, "data.data.demaecanOrderInfos.data", []);
      const data2 = _.get(res2, "data.data.ubereatsPaymentInfos.data", []);
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          let totalDemaecan = 0;
          let totalUberEats = 0;
          const demaecanOrderInfos = data1.filter((d) =>
            m.isSame(d.attributes.order_time, "month")
          );
          const ubereatsPaymentInfos = data2.filter((d) =>
            m.isSame(d.attributes.payment_date, "month")
          );
          totalDemaecan = demaecanOrderInfos.reduce((acc, curr) => {
            let order_total = _.get(curr, "attributes.order_total", 0);
            acc =
              acc +
              currencyFormatter.unformat(order_total, {
                code: "ja-JP",
              });
            return acc;
          }, totalDemaecan);
          totalUberEats = ubereatsPaymentInfos.reduce((acc, curr) => {
            let productPriceWithtax = _.get(
              curr,
              "attributes.product_price_withtax",
              0
            );
            acc += productPriceWithtax;
            return acc;
          }, totalUberEats);
          total = totalDemaecan + totalUberEats;
          result.push(total);
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        let totalDemaecan = 0;
        let totalUberEats = 0;
        for (let i = 0; i < data1.length; i++) {
          let order_total = _.get(data1[i], "attributes.order_total", 0);
          totalDemaecan += currencyFormatter.unformat(order_total, {
            code: "ja-JP",
          });
        }
        for (let i = 0; i < data2.length; i++) {
          let product_price_withtax = _.get(
            data2[i],
            "attributes.product_price_withtax",
            0
          );
          totalUberEats += product_price_withtax;
        }
        total = totalDemaecan + totalUberEats;
        return {
          total,
          totalUberEats,
          totalDemaecan
        }
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (unit: number)
   */
  const calculateNumberStoreActive = async (
    arrStoreIds,
    startDate,
    endDate
  ) => {
    let numberStore = 0;
    for (let i = 0; i < arrStoreIds.length; i++) {
      const actualTime = await calculateActualActivityTime(
        [arrStoreIds[i]],
        startDate,
        endDate
      );
      if (actualTime > 0) {
        numberStore += 1;
      }
    }
    return numberStore;
  };

  const calculateStoreStandard = async (arrStoreIds, startDate, endDate) => {
    let numberStore = 0;
    for (let i = 0; i < arrStoreIds.length; i++) {
      const pointStoreReview = await calculateStoreReview(
        [arrStoreIds[i]],
        startDate,
        endDate
      );
      const pointProductReview = await calculateProductReviewRate(
        [[arrStoreIds[i]]],
        startDate,
        endDate
      );
      const actualActivityTime = await calculateActualActivityTime(
        [[arrStoreIds[i]]],
        startDate,
        endDate
      );
      const onlineRate = await calculateOnlineRate(
        [[arrStoreIds[i]]],
        startDate,
        endDate
      );
      const shipperReview = await calculateShipperReviewRate(
        [[arrStoreIds[i]]],
        startDate,
        endDate
      );
      const wrongOrderRate = await calculateWrongOrderRate(
        [[arrStoreIds[i]]],
        startDate,
        endDate
      );
      const prepTimeOrder = await calculatePrepTimeOrder(
        [[arrStoreIds[i]]],
        startDate,
        endDate
      );
      const numberPrepTimeIncrease = await calculateNumberPrepTimeIncrease(
        [[arrStoreIds[i]]],
        startDate,
        endDate
      );

      if (
        pointStoreReview >= 4.7 &&
        pointProductReview >= 90 &&
        actualActivityTime >= 8 &&
        onlineRate === 100 &&
        shipperReview >= 90 &&
        wrongOrderRate < 1 &&
        prepTimeOrder < 5 &&
        numberPrepTimeIncrease <= 0
      ) {
        numberStore += 1;
      }
    }

    return numberStore;
  };

  /**
   *
   * @param {number} actualActivityTime (unit: h)
   * @returns
   */
  const calculateAverageTime = (
    actualActivityTime
  ) => {
    const startDayInMonth = moment().startOf("month").format();
    const currentDayInMonth = moment().format();
    const totalDays =
      moment(currentDayInMonth).diff(moment(startDayInMonth), "days") + 1;
    return actualActivityTime / totalDays;
  };

  const calculateStoreNumber = (arrStoreIds) => {
    return arrStoreIds.length;
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns: (unit: h)
   */
  const calculateActualActivityTime = async (
    arrStoreIds,
    startDate,
    endDate
  ) => {
    let totalTime = 0;
    try {
      let params = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
      };
      const res = await getUberEatsInactiveTimes(params);
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      const datas = _.get(res, "data.data.ubereatsInactiveTimes.data", []);
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          let totalTime = 0;
          const data = datas.filter((d) =>
            m.isSame(d.attributes.date, "month")
          );
          for (let i = 0; i < data.length; i++) {
            let restaurant_online = _.get(
              data[i],
              "attributes.restaurant_online",
              0
            );
            totalTime += restaurant_online;
          }
          result.push(totalTime / 60);
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        for (let i = 0; i < datas.length; i++) {
          let restaurant_online = _.get(
            datas[i],
            "attributes.restaurant_online",
            0
          );
          totalTime += restaurant_online;
        }
        return totalTime / 60;
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns: (unit: point 0 -> 5)
   */
  const calculateStoreReview = async (arrStoreIds, startDate, endDate) => {
    let storeReview = 0;
    try {
      let params = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
        ratingType: "customer_to_restaurant",
      };
      const res = await getUbereatsFeedbackServices(params);
      const datas = _.get(res, "data.data.ubereatsFeedbackServices.data", []);
      const count = _.get(
        res,
        "data.data.ubereatsFeedbackServices.meta.pagination.total",
        0
      );
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          let storeReview = 0;
          let totalStoreReview = 0;
          const data = datas.filter((d) =>
            m.isSame(d.attributes.rating_date, "month")
          );
          const total = data.length;
          for (let i = 0; i < data.length; i++) {
            let rating_score = _.get(data[i], "attributes.rating_score", 0);
            totalStoreReview += rating_score;
          }
          if (total !== 0) {
            storeReview = totalStoreReview / total;
          }
          result.push(storeReview.toFixed(2));
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        let totalStoreReview = 0;
        for (let i = 0; i < datas.length; i++) {
          let rating_score = _.get(datas[i], "attributes.rating_score", 0);
          totalStoreReview += rating_score;
        }
        if (count !== 0) {
          storeReview = totalStoreReview / count;
        }
        return storeReview.toFixed(2);
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (unit: h)
   */
  const calculateTargetTimeActive = (arrStoreIds, startDate, endDate) => {
    const totalDaysInMonth =
      moment(endDate).diff(moment(startDate), "days") + 1;
    return 8 * totalDaysInMonth;
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (rate: %)
   */
  const calculateWrongOrderRate = async (arrStoreIds, startDate, endDate) => {
    let wrongOrderRate = 0;
    try {
      let params1 = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
        orderProblem: `["FOOD_QUALITY", "INCOMPLETE", "MISSING_ITEMS", "PARTIAL_MISSING_ITEMS", "MISSING"]`
      };
      let params2 = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        orderStatus: `["完了", "completed"]`,
        date: `["${startDate}", "${endDate}"]`,
      };
      const resUberEatsOrderProblems = await getUbereatsOrderProblems(params1);
      const resUberEatsPaymentInfo = await getUbereatsPaymentInfos(params2);
      const dataOrderProblems = _.get(
        resUberEatsOrderProblems,
        "data.data.ubereatsOrderProblems.data",
        []
      );
      const totalOrderProblems = _.get(
        resUberEatsOrderProblems,
        "data.data.ubereatsOrderProblems.meta.pagination.total",
        0
      );
      const totalOrder = _.get(
        resUberEatsPaymentInfo,
        "data.data.ubereatsPaymentInfos.meta.pagination.total",
        0
      );
      const dataPaymentInfos = _.get(
        resUberEatsPaymentInfo,
        "data.data.ubereatsPaymentInfos.data",
        []
      );
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          let wrongOrderRate = 0;
          const orderProblemsInMonth = dataOrderProblems.filter((d) =>
            m.isSame(d.attributes.order_time, "month")
          );
          const paymentInfosInMonth = dataPaymentInfos.filter((d) =>
            m.isSame(d.attributes.payment_date, "month")
          );
          const orderProblemsTotalInMonth = orderProblemsInMonth.length;
          const paymentInfosTotalInMonth = paymentInfosInMonth.length;
          if (paymentInfosTotalInMonth > 0) {
            wrongOrderRate =
              orderProblemsTotalInMonth / paymentInfosTotalInMonth;
          }
          result.push((wrongOrderRate * 100).toFixed(2));
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        if (totalOrder !== 0) {
          wrongOrderRate = totalOrderProblems / totalOrder;
        }
        return (wrongOrderRate * 100).toFixed(2);
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (rate: %)
   */
  const calculateShipperReviewRate = async (
    arrStoreIds,
    startDate,
    endDate
  ) => {
    let shipperReviewRate = 0;
    try {
      let params1 = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
        ratingType: "courier_to_restaurant",
        ratingScore: 1,
      };
      let params2 = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        orderStatus: `["完了", "completed"]`,
        date: `["${startDate}", "${endDate}"]`,
      };

      const resUberEatsFeedbackService = await getUbereatsFeedbackServices(
        params1
      );
      const resUberEatsPaymentInfo = await getUbereatsPaymentInfos(params2);
      const dataFeedbackServices = _.get(
        resUberEatsFeedbackService,
        "data.data.ubereatsFeedbackServices.data",
        []
      );
      const totalRecordFeedbackShipper = _.get(
        resUberEatsFeedbackService,
        "data.data.ubereatsFeedbackServices.meta.pagination.total"
      );
      const dataPaymentInfos = _.get(
        resUberEatsPaymentInfo,
        "data.data.ubereatsPaymentInfos.data",
        []
      );
      const totalOrder = _.get(
        resUberEatsPaymentInfo,
        "data.data.ubereatsPaymentInfos.meta.pagination.total",
        0
      );
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          let shipperReviewRate = 0;
          const feedbackServicesInMonth = dataFeedbackServices.filter((d) =>
            m.isSame(d.attributes.rating_date, "month")
          );
          const paymentInfosInMonth = dataPaymentInfos.filter((d) =>
            m.isSame(d.attributes.payment_date, "month")
          );
          const feedbackServicesTotalInMonth = feedbackServicesInMonth.length;
          const paymentInfosTotalInMonth = paymentInfosInMonth.length;
          if (paymentInfosTotalInMonth > 0) {
            shipperReviewRate =
              feedbackServicesTotalInMonth / paymentInfosTotalInMonth;
          }
          result.push((shipperReviewRate * 100).toFixed(2));
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        if (totalOrder !== 0) {
          shipperReviewRate = totalRecordFeedbackShipper / totalOrder;
        }
        return (shipperReviewRate * 100).toFixed(2);
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns ( unit: minute)
   */
  const calculatePrepTimeOrder = async (arrStoreIds, startDate, endDate) => {
    let prepTimeOrder = 0;
    try {
      let params = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
      };

      const res = await getUbereatsOrderHistories(params);
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      const datas = _.get(res, "data.data.ubereatsOrderHistories.data", []);
      const total = _.get(
        res,
        "data.data.ubereatsOrderHistories.meta.pagination.total",
        0
      );
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          let prepTimeOrder = 0;
          const data = datas.filter((d) =>
            m.isSame(d.attributes.order_date, "month")
          );
          const total = data.length;
          let totalReceiveTime = 0;
          for (let i = 0; i < data.length; i++) {
            let receive_time = _.get(data[i], "attributes.receive_time", 0);
            totalReceiveTime += receive_time;
          }
          if (total !== 0) {
            prepTimeOrder = (totalReceiveTime / total) * 60;
          }
          result.push(prepTimeOrder);
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        let totalReceiveTime = 0;
        for (let i = 0; i < datas.length; i++) {
          let receive_time = _.get(datas[i], "attributes.receive_time", 0);
          totalReceiveTime += receive_time;
        }
        if (total !== 0) {
          prepTimeOrder = (totalReceiveTime / total) * 60;
        }
        return prepTimeOrder.toFixed(2);
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (unit: %)
   */
  const calculateOnlineRate = async (arrStoreIds, startDate, endDate) => {
    let onlineRate = 0;
    try {
      let params = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
      };
      const res = await getUberEatsInactiveTimes(params);
      const datas = _.get(res, "data.data.ubereatsInactiveTimes.data", []);
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          let totalRestaurantOnline = 0;
          let totalMenu = 0;
          let onlineRate = 0;
          const data = datas.filter((d) =>
            m.isSame(d.attributes.date, "month")
          );
          for (let i = 0; i < data.length; i++) {
            const inactiveTime = data[i];
            let restaurant_online = _.get(
              inactiveTime,
              "attributes.restaurant_online",
              0
            );
            let menu = _.get(inactiveTime, "attributes.menu", 0);
            totalRestaurantOnline += restaurant_online;
            totalMenu += menu;
          }
          if (totalMenu !== 0) {
            onlineRate = totalRestaurantOnline / totalMenu;
          }
          result.push((onlineRate * 100).toFixed(2));
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        let totalRestaurantOnline = 0;
        let totalMenu = 0;
        for (let i = 0; i < datas.length; i++) {
          const inactiveTime = datas[i];
          let restaurant_online = _.get(
            inactiveTime,
            "attributes.restaurant_online",
            0
          );
          let menu = _.get(inactiveTime, "attributes.menu", 0);
          totalRestaurantOnline += restaurant_online;
          totalMenu += menu;
        }

        if (totalMenu !== 0) {
          onlineRate = totalRestaurantOnline / totalMenu;
        }
        return (onlineRate * 100).toFixed(2);
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (unit: %)
   */
  const calculateProductReviewRate = async (
    arrStoreIds,
    startDate,
    endDate
  ) => {
    let productReviewRate = 0;
    try {
      let params = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
      };
      const res = await getUbereatsFeedbackMenus(params);
      const dataFeedbackMenus = _.get(
        res,
        "data.data.ubereatsFeedbackMenus.data",
        []
      );
      const recordHasRatingScoreEQ1 = dataFeedbackMenus.filter(
        (d) => d.attributes.rating_score === 1
      );
      const totalRecordHasRatingScoreEQ1 = recordHasRatingScoreEQ1.length;
      const totalRecord = _.get(
        res,
        "data.data.ubereatsFeedbackMenus.meta.pagination.total",
        0
      );
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          let productReviewRate = 0;
          const feedbackMenusInMonth = dataFeedbackMenus.filter((d) =>
            m.isSame(d.attributes.rating_time, "month")
          );
          const feedbackMenuRating1InMonth = feedbackMenusInMonth.filter(
            (fb) => fb.attributes.rating_score === 1
          );
          const totalFeedbackMenusInMonth = feedbackMenusInMonth.length;
          const totalFeedbackMenusRating1InMonth =
            feedbackMenuRating1InMonth.length;
          if (totalFeedbackMenusInMonth !== 0) {
            productReviewRate =
              totalFeedbackMenusRating1InMonth / totalFeedbackMenusInMonth;
          }
          result.push((productReviewRate * 100).toFixed(2));
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        if (totalRecord !== 0) {
          productReviewRate = totalRecordHasRatingScoreEQ1 / totalRecord;
        }
        return (productReviewRate * 100).toFixed(2);
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /**
   *
   * @param {array} arrStoreIds
   * @param {datetime} startDate
   * @param {datetime} endDate
   * @returns (unit: number)
   */
  const calculateNumberPrepTimeIncrease = async (
    arrStoreIds,
    startDate,
    endDate
  ) => {
    try {
      let params = {
        arrVradminStoreIds: `[${arrStoreIds}]`,
        date: `["${startDate}", "${endDate}"]`,
        prepTimeIncrease: 0,
      };
      const res = await getUbereatsOrderHistories(params);
      const dataOrderHistories = _.get(
        res,
        "data.data.ubereatsOrderHistories.data",
        []
      );
      const total = _.get(
        res,
        "data.data.ubereatsOrderHistories.meta.pagination.total",
        0
      );
      const startDateTime = moment(startDate);
      const endDateTime = moment(endDate);
      const isMultipleTime = endDateTime.diff(startDateTime, "months") > 1;
      if (isMultipleTime) {
        let m = startDateTime;
        let result = [];
        while (endDateTime.diff(m, "months") >= 0) {
          const orderHistoriesInMonth = dataOrderHistories.filter((d) =>
            m.isSame(d.attributes.order_date, "month")
          );
          const orderHistoriesTotal = orderHistoriesInMonth.length;
          result.push(orderHistoriesTotal);
          m = startDateTime.add(1, "month");
        }
        return result;
      } else {
        return total;
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const calculateLossDueToNotWorking8Hours = async (
    arrStoreIds,
    startDate,
    endDate
  ) => {
    const { total } = await calculateSalesTotal(
      arrStoreIds,
      startDate,
      endDate
    );

    const actualActivityTime = await calculateActualActivityTime(
      arrStoreIds,
      startDate,
      endDate
    );

    const totalDaysInMonth =
      moment(endDate).diff(moment(startDate), "days") + 1;

    let totalSales1Hours = 0;
    if (actualActivityTime !== 0) {
      totalSales1Hours = total / actualActivityTime;
    }

    return (totalSales1Hours * 8 * totalDaysInMonth - total).toFixed(2);
  };

  const fetchLocation = async (id) => {
    try {
      const res = await commonService.getLocation(`${id}?populate=stores`);
      const stores = _.get(res, "data.data.attributes.stores.data", []);
      const locationName = _.get(res, "data.data.attributes.name", "");
      setLocationName(locationName);
      const storeIndex = stores.findIndex(store => store.id === Number(brand_id));
      const storeName = _.get(stores[storeIndex], "attributes.name", "");
      setBrandName(storeName);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const fetchDataBrandInCompany = async () => {
    try {
      let params = {
        populate: "users_permissions_user, companies, locations",
        "filters[users_permissions_user][id][$eq]": Auth.user().id,
      }
      const profile = await commonService.getListProfile(params);
      const companies = _.get(profile, "data.data[0].attributes.companies.data", []);
      let arrCompanyIds = [];
      for (let i = 0; i < companies.length; i++) {
        arrCompanyIds = [...arrCompanyIds, companies[i].id];
      }
      
      let params2 = {
        populate: "company, stores",
        "filters[company][id][$in]": arrCompanyIds,
        "pagination[limit]": CONSTANT.LIST_LIMIT
      }

      const res = await commonService.getLocations(params2);
      
      const locations = _.get(res, "data.data", []);
      let arrStoreIds = [];
      for (let i = 0; i < locations.length; i++) {
        let stores = _.get(locations[i], "attributes.stores.data", []);
        
        for (let j = 0; j < stores.length; j++) {
          let id = _.get(stores[j], "id", "");
          arrStoreIds.push(id);
        }
      }

      setStoreIds(arrStoreIds);
      
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  useEffect(() => {
    if (!brand_id && location_id) {
      getLocationById(location_id);
    } else if (brand_id) {
      setStoreIds([Number(brand_id)]);
    } else {
      setLocationName(i18n.t("sideBar.dashboard.all"));
      setBrandName("");
      fetchDataBrandInCompany();
    }
    if (location_id) {
      fetchLocation(location_id);
    }
  }, [location_id, brand_id]);

  useEffect(() => {
    setDataDefault();
    if (storeIds.length > 0) {
      getAllChartData(storeIds);
      tableStatistical(storeIds);
    }
  }, [storeIds]);

  const getSaleTrendsChartData = async (storeIds) => {
    setIsShowSaleTrendsChart(false);
    const startMonth = months[0];
    const lastMonth = months[months.length - 1];
    const [startDateFirt] = getMonthInfo(startMonth);
    const [ , endDateLast, ] = getMonthInfo(lastMonth);
    const [dataSaleTotal, dataSalePredict] = await Promise.all([
      calculateSalesTotal(storeIds, startDateFirt, endDateLast),
      calculateSalesTotalPredict(storeIds, startDateFirt, endDateLast),
    ]);
    const datasets = CHART_DATA[CHART_TYPE["SALE_TRENDS"]];
    datasets.labels = months;
    datasets.datasets[0].data = dataSaleTotal;
    datasets.datasets[1].data = dataSalePredict;
    setSaleTrendsChartData(datasets);
    setIsShowSaleTrendsChart(true);
  };

  const getRatingStoreChartData = async (storeIds) => {
    setIsShowRatingStoreChartData(false);
    const startMonth = months[0];
    const lastMonth = months[months.length - 1];
    const [startDateFirt] = getMonthInfo(startMonth);
    const [, endDateLast, ] = getMonthInfo(lastMonth);
    const data = await calculateStoreReview(
      storeIds,
      startDateFirt,
      endDateLast
    );
    const datasets = CHART_DATA[CHART_TYPE["RATING_STORE"]];
    datasets.labels = months;
    datasets.datasets[1].data = data;
    setRatingStoreChartData(datasets);
    setIsShowRatingStoreChartData(true);
  };

  const getOperationQuality1ChartData = async (storeIds) => {
    setIsShowOperationQuality1Chart(false);
    const startMonth = months[0];
    const lastMonth = months[months.length - 1];
    const [startDateFirt] = getMonthInfo(startMonth);
    const [, endDateLast, ] = getMonthInfo(lastMonth);
    const [dataShipperReview, dataProductReview] = await Promise.all([
      calculateShipperReviewRate(storeIds, startDateFirt, endDateLast),
      calculateProductReviewRate(storeIds, startDateFirt, endDateLast),
    ]);
    const datasets = CHART_DATA[CHART_TYPE["OPERATION_QUALITY_1"]];
    datasets.labels = months;
    datasets.datasets[1].data = dataShipperReview;
    datasets.datasets[2].data = dataProductReview;
    setOperationQuality1ChartData(datasets);
    setIsShowOperationQuality1Chart(true);
  };

  const getOperationQuality3ChartData = async (storeIds) => {
    setIsShowOperationQuality3Chart(false);
    const startMonth = months[0];
    const lastMonth = months[months.length - 1];
    const [startDateFirt] = getMonthInfo(startMonth);
    const [, endDateLast, ] = getMonthInfo(lastMonth);
    const datas = await calculateNumberPrepTimeIncrease(
      storeIds,
      startDateFirt,
      endDateLast
    );
    const datasets = CHART_DATA[CHART_TYPE["OPERATION_QUALITY_3"]];
    datasets.labels = months;
    datasets.datasets[1].data = datas;
    setOperationQuality3ChartData(datasets);
    setIsShowOperationQuality3Chart(true);
  };

  const getOperationQuality2ChartData = async (storeIds) => {
    setIsShowOperationQuality2Chart(false);
    const startMonth = months[0];
    const lastMonth = months[months.length - 1];
    const [startDateFirt] = getMonthInfo(startMonth);
    const [, endDateLast, ] = getMonthInfo(lastMonth);
    const datas = await calculateOnlineRate(
      storeIds,
      startDateFirt,
      endDateLast
    );
    const datasets = CHART_DATA[CHART_TYPE["OPERATION_QUALITY_2"]];
    datasets.labels = months;
    datasets.datasets[1].data = datas;
    setOperationQuality2ChartData(datasets);
    setIsShowOperationQuality2Chart(true);
  };

  const getMakeMistakeChartData = async (storeIds) => {
    setIsShowMakeMistakeChart(false);
    const startMonth = months[0];
    const lastMonth = months[months.length - 1];
    const [startDateFirt] = getMonthInfo(startMonth);
    const [, endDateLast, ] = getMonthInfo(lastMonth);
    const datas = await calculateWrongOrderRate(
      storeIds,
      startDateFirt,
      endDateLast
    );
    const datasets = CHART_DATA[CHART_TYPE["MAKE_MISTAKE"]];
    datasets.labels = months;
    datasets.datasets[1].data = datas;
    setMakeMistakeChartData(datasets);
    setIsShowMakeMistakeChart(true);
  };

  const getActiveTimeChartData = async (storeIds) => {
    setIsShowChartActiveTime(false);
    let data = [];
    const startMonth = months[0];
    const lastMonth = months[months.length - 1];
    const [startDateFirt] = getMonthInfo(startMonth);
    const [, endDateLast, ] = getMonthInfo(lastMonth);
    const activeTimes = await calculateActualActivityTime(
      storeIds,
      startDateFirt,
      endDateLast
    );
    for (let i = 0; i < months.length; i++) {
      const month = months[i];
      const [, , dayInMonths] = getMonthInfo(month);
      const averageActiveTime = Math.floor(activeTimes[i] / dayInMonths);
      data.push(averageActiveTime);
    }
    const datasets = CHART_DATA[CHART_TYPE["ACTIVE_TIME"]];
    datasets.labels = months;
    datasets.datasets[1].data = data;
    setActiveTimeChartData(datasets);
    setIsShowChartActiveTime(true);
  };

  const getOrderTimeChartData = async (storeIds) => {
    setIsShowOrderTimeChart(false);
    const startMonth = months[0];
    const lastMonth = months[months.length - 1];
    const [startDateFirt] = getMonthInfo(startMonth);
    const [, endDateLast, ] = getMonthInfo(lastMonth);
    const data = await calculatePrepTimeOrder(
      storeIds,
      startDateFirt,
      endDateLast
    );
    const datasets = CHART_DATA[CHART_TYPE["ORDER_TIME"]];
    datasets.labels = months;
    datasets.datasets[1].data = data;
    setOrderTimeChartData(datasets);
    setIsShowOrderTimeChart(true);
  };

  return (
    <>
      <Loading disappear={loading} />
      <DashboardHeader
        averageTime={averageTime}
        storeNumber={storeNumber}
        totalActualTimeActive={totalActualTimeActive}
        storeReview={storeReview}
        wrongOrderRate={wrongOrderRate}
        shipperReviewRate={shipperReviewRate}
        prepTimeOrder={prepTimeOrder}
        onlineRate={onlineRate}
        productReviewRate={productReviewRate}
        numberPrepTimeIncrease={numberPrepTimeIncrease}
        salesTotal={salesTotal}
        storeNumberActive={storeNumberActive}
        storeNumberStandard={storeNumberStandard}
        lossDueToNotWorking8Hours={lossDueToNotWorking8Hours}
        targetTimeActive={targetTimeActive}
        predictSalesTotal={predictSalesTotal}
        loading={loading}
        salesTotalUberEats={salesTotalUberEats}
        salesTotalDemaecan={salesTotalDemaecan}
        predictSalesTotalDemaecan={predictSalesTotalDemaecan}
        predictSalesTotalUberEats={predictSalesTotalUberEats}
        locationName={locationName}
        brandName={brandName}
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="6">
            <LineChart
              isShow={isShowSaleTrendsChart}
              data={saleTrendsChartData}
              chartType={CHART_TYPE["SALE_TRENDS"]}
            />
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
            <LineChart
              isShow={isShowRatingStoreChartData}
              data={ratingStoreChartData}
              chartType={CHART_TYPE["RATING_STORE"]}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mb-5 mb-xl-0" xl="6">
            <LineChart
              isShow={isShowOperationQuality1Chart}
              data={operationQuality1ChartData}
              chartType={CHART_TYPE["OPERATION_QUALITY_1"]}
              description={i18n.t("chart.operationQuality1Description")}
            />
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
            <LineChart
              isShow={isShowOperationQuality2Chart}
              data={operationQuality2ChartData}
              chartType={CHART_TYPE["OPERATION_QUALITY_2"]}
              description={i18n.t("chart.operationQuality2Description")}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mb-5 mb-xl-0" xl="6">
            <LineChart
              isShow={isShowOperationQuality3Chart}
              data={operationQuality3ChartData}
              chartType={CHART_TYPE["OPERATION_QUALITY_3"]}
              description={i18n.t("chart.operationQuality3Description")}
            />
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
            <LineChart
              isShow={isShowMakeMistakeChart}
              data={makeMistakeChartData}
              chartType={CHART_TYPE["MAKE_MISTAKE"]}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mb-5 mb-xl-0" xl="6">
            <LineChart
              isShow={isShowChartActiveTime}
              data={activeTimeChartData}
              chartType={CHART_TYPE["ACTIVE_TIME"]}
            />
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
            <LineChart
              isShow={isShowOrderTimeChart}
              data={orderTimeChartData}
              chartType={CHART_TYPE["ORDER_TIME"]}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
