import React from "react";
import LoadingOverlay from "react-loading-overlay";
import PropTypes from "prop-types";
import "./loading.scss";

const Loading = (props) => {
  const { disappear } = props;

  return (
    <div className={`loading ${disappear && "show-loading"}`}>
      <LoadingOverlay
        active={true}
        spinner={true}
      />
    </div>
  );
};

PropTypes.propTypes = {
  disappear: PropTypes.bool.isRequired,
  spinner: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Loading;
