export const FILTER_STATUS_OPTIONS = [
  { value: 1, label: "写真提出済み" },
  { value: 2, label: "確認中" },
  { value: 3, label: "完了" },
]

export const STATUS_OPTIONS = {
  UPLOADED_NOT_YET_EVALUATE: 1,
  WAIT_EVALUATE: 2,
  EVALUATED: 3
}

export const DUMMY_REPORTS = [
  {
    id: 0,
    locationName: "A店舗",
    point: null,
    uploadDate: "2022/3/1",
    confirmDate: null,
    status: 1,
  },
  {
    id: 1,
    locationName: "B店舗",
    point: null,
    uploadDate: "2022/3/1",
    confirmDate: null,
    status: 2,
  },
  {
    id: 2,
    locationName: "C店舗",
    point: 90,
    uploadDate: "2022/3/1",
    confirmDate: "2022/2/20",
    status: 3,
  },
];

export const MOCK_CHECKLIST = [
  {
    id: 1,
    order: 1,
    name: "個人衛生（手洗い場写真） 　：手洗い設備（手洗い石鹸・爪ブラシ・ペーパータオル・アルコール）",
    description: "※普段従業員の方が手洗いをしている場所の写真"
  },
  {
    id: 2,
    order: 2,
    name: "個人衛生（身だしなみ写真）　：身だしなみ（帽子またはバンダナ等着用）清潔度確認",
    description: "※正面は首から足元まで、後ろ姿は頭部から足元までを撮影"
  },
  {
    id: 3,
    order: 3,
    name: "個人衛生（健康チェック表）　：健康チェック表（毎日実施記録があるか）",
    description: "※普段使用している健康チェック表の写真"
  },
  {
    id: 4,
    order: 4,
    name: "個人衛生（トイレ内写真）　：従業員トイレの清掃状況（お客様と兼用の場合は客用トイレ）",
    description: "※トイレの清掃状況が分かる写真（便器内部、床が写っているもの）"
  },
  {
    id: 5,
    order: 5,
    name: "整理整頓清掃状況（キッチン全体写真①）　：キッチン全体の見通し写真",
    description: "※天井から床までが入るように撮影"
  },
  {
    id: 6,
    order: 6,
    name: "整理整頓清掃状況（キッチン全体写真②）　：キッチン全体の見通し写真",
    description: "※５　で撮影した写真と反対方向から天井から床まで入るように撮影"
  },
  {
    id: 7,
    order: 7,
    name: "害虫誘因関連（ダクト内側写真）　：キッチンダクト（レンジフード）の清掃状況",
    description: "※ガスコンロ等の上部にあるキッチンダクトの内側全体の状況が分かる写真"
  },
  {
    id: 8,
    order: 8,
    name: "害虫誘因関連（冷蔵庫の機器下写真）　：機器下の清掃状況",
    description: "※フラッシュONにしてスマホ等を床に垂直に立て機器の奥が写るように撮影"
  },
  {
    id: 9,
    order: 9,
    name: "害虫誘因関連（フライヤーの機器下写真）　：フライヤー・加熱調理機器類周辺の清掃状況",
    description: "※フラッシュONにしてスマホ等を床に垂直に立て機器の奥が写るように撮影\nフライヤーが無い場合はガスコンロなど加熱機器類の機器下を撮影"
  },
  {
    id: 10,
    order: 10,
    name: "害虫誘因関連（グリストラップ清掃状況）　：グリストラップ内部の清掃状況",
    description: "※グリストラップの蓋を開け、水槽全体が入るように撮影"
  },
  {
    id: 11,
    order: 11,
    name: "調理器具類衛生（まな板、包丁保管状況）　：まな板・包丁の使い分けおよび清潔度",
    description: "※まな板、包丁が保管されている状態が分かる写真"
  },
  {
    id: 12,
    order: 12,
    name: "調理器具類衛生（ザル、ボウル保管状況）　：調理器具・備品の使い分けおよび清潔度",
    description: "※ザル、ボウルが保管されている状態が分かる写真"
  },
  {
    id: 13,
    order: 13,
    name: "冷蔵・冷凍設備メンテナンス（フィルター、温度表示）　：温度が適正範囲か・フィルター清掃されているか",
    description: "※冷蔵庫、冷凍庫のフィルター写真および温度表示が分かる写真"
  },
  {
    id: 14,
    order: 14,
    name: "食材保管（冷蔵庫内部）　：冷蔵庫内の保管状況（衛生状態・棚割り・日付管理・保管方法）",
    description: "※冷蔵庫内部の保管状況が把握できる写真"
  },
  {
    id: 15,
    order: 15,
    name: "食材保管（冷凍庫内部）　：冷凍庫内の保管状況（衛生状態・棚割り・日付管理・保管方法）",
    description: "※冷凍庫内部の保管状況が把握できる写真"
  },
  {
    id: 16,
    order: 16,
    name: "異物混入（オペレーション中のセッティング）　：オペレーション中の異物混入リスク確認",
    description: "※営業中の調理機器類周辺の状況（セッティングなど）全体が分かる写真\n揚げ物業態→フライヤー周辺・ホットショーケース周辺\n湯煎業態　→湯煎機器周辺\n非加熱業態　→盛り付け使用食材保管状況・使用器具"
  },
  {
    id: 17,
    order: 17,
    name: "異物混入（包材清潔度）　：包材在庫（ストック）保管状況",
    description: "※包材のストック（在庫）を保管している場所と周辺環境が分かる写真"
  },
  {
    id: 18,
    order: 18,
    name: "工程リスク（タブレット周辺）　：受注から商品作成工程のリスク確認",
    description: "※タブレットが設置されている場所とオーダー票記載する場所周囲の写真"
  },
  {
    id: 19,
    order: 19,
    name: "工程リスク（商品梱包作業スペース）　：商品完成から梱包工程のリスク確認",
    description: "※出来上がった商品を包材に詰める作業をする場所周囲の写真"
  },
  {
    id: 20,
    order: 20,
    name: "工程リスク（配達員さんお渡しスペース）　：梱包から配達員受け渡し工程のリスク確認",
    description: "※完成品が配達員さんに受け渡しをしている場所に置かれている写真"
  }
];
