import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";

import "./styles.scss";
import { CommonService } from "../../../services/common-service";
import _ from "lodash";

const commonService = new CommonService();

const UploadItem = ({ index, image, setImage, checklistId }) => {
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (image) {
      setPreview(image);
    } else {
      setPreview("");
    }
  }, [image, checklistId]);

  const handleRemoveImage = () => {
    setImage(checklistId, "", index);
    setPreview("");
  };

  const onSelectFile = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    try {
      const res = await commonService.createUploadImage(formData);
      const url = _.get(res, "data[0].url", "");
      setImage(checklistId, url, index);
      setPreview(url);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  return (
    <div className="w-20 mx-2 my-2 image-box d-flex justify-content-center align-items-center bg-lighter custom-item-upload">
      {preview ? (
        <div className="wrapper">
          <img src={preview} alt="" className="img-fluid w-100" />
          <div id="cancel-btn" onClick={handleRemoveImage}>
            <i className="fas fa-times"></i>
          </div>
        </div>
      ) : (
        <div className="file-upload d-flex flex-column align-items-center p-3 w-100">
          <div className="icon">
            <i className="fas fa-cloud-upload-alt"></i>
          </div>
          <div className="upload-btn-wrapper">
            <Button className="bg-primary px-2 py-1 rounded text-white border-0 shadow-none shadow-none--hove">Choose file</Button>
            <Input type="file" accept=".jpg, .jpeg, .png" name="myfile" onChange={onSelectFile} />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadItem;
