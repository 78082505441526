import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Button, Col, Card, CardHeader } from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";

import i18n from "i18n/i18n";
import { CommonService } from "../../../services/common-service";
import { CONSTANT, ROLE, FREQUENCY_OF_HYGIENE_REPORTS } from "../../../constants/constant";
import Auth from "../../../helpers/auth";
import "./styles.scss";
import UploadItem from "./UploadItem";
import Validator from "../../../helpers/validator";

const commonService = new CommonService();

const perPage = 4;

const PhotoUpload = () => {
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companySelected, setCompanySelected] = useState("");
  const [locationSelected, setLocationSelected] = useState("");
  const [isValidLocation, setIsValidLocation] = useState(false);
  const [errorTypes, setErrorTypes] = useState([]);
  const [criterias, setCriterias] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [roleName, setRoleName] = useState("");

  useEffect(() => {
    fetchReportCriterias();
  }, []);

  const fetchReportCriterias = async () => {
    let params = {
      "pagination[limit]": CONSTANT.LIST_LIMIT,
      sort: "order",
    };
    try {
      const res = await commonService.getReportCriterias(params);
      let data = _.get(res, "data.data", []);
      for (let i = 0; i < data.length; i++) {
        data[i]["attributes"]["images"] = [];
        for (let j = 0; j < 5; j++) {
          data[i]["attributes"]["images"][j] = "";
        }
        data[i]["attributes"]["isValid"] = false;
      }
      setCriterias(data);
      setTotalPage(Math.ceil(data.length / perPage));
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const handleChooseLocation = () => {
    let params = {
      location: locationSelected.value,
    };

    const isValid = validate(params);
    if (isValid) {
      setCompanySelected(locationSelected.companyId);
      setIsValidLocation(true);
    }
  };

  const validate = (params) => {
    let errors = {};
    for (const item in params) {
      let errorType = Validator.emptyValue(item, params);
      if (errorType) {
        errors[item] = errorType;
      }
    }
    setErrorTypes(errors);
    return _.isEmpty(errors);
  };

  const handleSelectLocation = (e) => {
    setLocationSelected(e);
  };

  async function locationLoadOptions(search, loadOptions) {
    let result;
    let params = {
      populate: "company",
    };

    if (search) {
      params = {
        ...params,
        "filters[name][$contains]": search,
      };
      if (roleName === ROLE.MANAGE_COMPANY) {
        params = {
          ...params,
          "filters[company][id][$in]": companies,
        };
      } else if (roleName === ROLE.MANAGE_LOCATION) {
        params = {
          ...params,
          "filters[id][$in]": locations,
        };
      }
    } else {
      params = {
        ...params,
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      };
      if (roleName === ROLE.MANAGE_COMPANY) {
        params = {
          ...params,
          "filters[company][id][$in]": companies,
        };
      } else if (roleName === ROLE.MANAGE_LOCATION) {
        params = {
          ...params,
          "filters[id][$in]": locations,
        };
      }
    }
    result = await commonService.getLocations(params);
    return handleLoadOption(result);
  }

  const handleLoadOption = async (result) => {
    const data = _.get(result, "data.data", "");
    let list = [];
    if (data) {
      data.map((item) => {
        let companyId = _.get(item, "attributes.company.data.id", "");
        list.push({
          value: item["id"],
          label: item["attributes"]["name"],
          companyId: companyId,
          expectedDateHygieneReport: item["attributes"]["expected_date_hygiene_report"],
        });
        return list;
      });
    }
    return {
      options: list,
      hasMore: false,
    };
  };

  const getProfile = async () => {
    const userId = Auth.user().id;
    try {
      let params = {
        populate: "users_permissions_user, companies, locations",
        "filters[users_permissions_user][id][$eq]": userId,
      };

      const profile = await commonService.getListProfile(params);
      const roleName = _.get(profile, "data.data[0].attributes.role.data.attributes.name", "");
      setRoleName(roleName);

      if (roleName === ROLE.MANAGE_COMPANY) {
        const companiesUserManage = _.get(profile, "data.data[0].attributes.companies.data", []);
        let arrCompanyIds = [];

        for (let i = 0; i < companiesUserManage.length; i++) {
          let companyId = _.get(companiesUserManage[i], "id", "");
          arrCompanyIds = [...arrCompanyIds, companyId];
        }
        setCompanies([...arrCompanyIds]);
      }
      if (roleName === ROLE.MANAGE_LOCATION) {
        const locationsUserManage = _.get(profile, "data.data[0].attributes.locations.data", []);
        let arrLocationIds = [];
        for (let i = 0; i < locationsUserManage.length; i++) {
          let locationId = _.get(locationsUserManage[i], "id", "");
          arrLocationIds = [...arrLocationIds, locationId];
        }
        setLocations([...arrLocationIds]);
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const handleCreateReport = async () => {
    for (let i = 0; i < criterias.length; i++) {
      let count = _.get(criterias[i], "attributes.images", []).filter((item) => item !== "").length;
      if (count < criterias[i]["attributes"].min_image_upload) {
        criterias[i]["attributes"]["isValid"] = true;
        setCriterias([...criterias]);
      } else {
        criterias[i]["attributes"]["isValid"] = false;
        setCriterias([...criterias]);
      }
    }
    let countValid = criterias.filter((item) => item["attributes"]["isValid"] === false).length;

    if (countValid < criterias.length) {
    } else {
      createReport();
    }
  };

  /**
   *
   * @param {date} startDateReport
   * @returns
   */
  const getNextDateReport = (startDateReport) => {
    let nextDateReport = startDateReport;
    let now = moment().format("YYYY-MM-DD");

    // set report date to its end of month
    nextDateReport = moment(nextDateReport).endOf("month").format("YYYY-MM-DD");

    while (moment(nextDateReport).isBefore(now)) {
      nextDateReport = moment(nextDateReport).add(FREQUENCY_OF_HYGIENE_REPORTS, "month").endOf("month").format("YYYY-MM-DD");
    }
    return nextDateReport;
  };

  const createReport = async () => {
    setLoading(true);
    const expectedDateHygieneReport = _.get(locationSelected, "expectedDateHygieneReport", "");
    const nextDateReport = getNextDateReport(expectedDateHygieneReport);
    let paramsReport = {
      data: {
        user_created_id: Auth.user().id,
        date_report: nextDateReport,
        location: locationSelected.value,
        company: companySelected,
      },
    };

    try {
      const resReport = await commonService.createReport(paramsReport);
      const reportId = _.get(resReport, "data.data.id", "");

      for (let i = 0; i < criterias.length; i++) {
        let paramsCriteriaLink = {
          data: {
            report: reportId,
            report_criteria: criterias[i].id,
          },
        };
        const resReportCriteriaLink = await commonService.createReportCriteriaLink(paramsCriteriaLink);
        const reportCriteriaLinkId = _.get(resReportCriteriaLink, "data.data.id", "");

        const images = _.get(criterias[i], "attributes.images", []).filter((item) => item !== "");

        for (let k = 0; k < images.length; k++) {
          let paramsReportImage = {
            data: {
              url: images[k],
              report_criteria_link: reportCriteriaLinkId,
            },
          };
          await commonService.createReportImage(paramsReportImage);
        }
      }
      setLoading(false);
      history.push("/admin/hygiene/list");
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      throw error;
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const renderSelectLocation = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center mt-5 py-5">
        <Row className="w-50">
          <Col md="3" xs="12" className="d-flex align-items-center flex-row-reverse">
            <span>{i18n.t("photoUpload.targetLocation")}</span>
          </Col>
          <Col md="9" xs="12">
            <AsyncPaginate value={locationSelected} loadOptions={locationLoadOptions} onChange={(e) => handleSelectLocation(e)} />
          </Col>
        </Row>
        <Row className="w-50">
          <Col md="3" />
          <Col md="9">
            <small style={{ color: "#b00" }}>{i18n.t(errorTypes["location"]).replace("(param1)", i18n.t("photoUpload.targetLocation"))}</small>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Button className="mt-5" color="primary" type="button" onClick={handleChooseLocation}>
              {i18n.t("btnNext")}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const handleChangePageCriteria = (step, action) => {
    if (currentPage + step === 0) {
      setIsValidLocation(false);
    } else {
      if (action === "next") {
        const indexOfLast = currentPage * perPage;
        const indexOfFirst = indexOfLast - perPage;
        let currentCriterias = criterias.slice(indexOfFirst, indexOfLast);
        let countValid = 0;
        for (let i = 0; i < currentCriterias.length; i++) {
          let count = _.get(currentCriterias[i], "attributes.images", []).filter((item) => item !== "").length;
          let indexCriteria = criterias.findIndex((critera) => critera.id === currentCriterias[i].id);
          if (count < currentCriterias[i]["attributes"].min_image_upload) {
            criterias[indexCriteria]["attributes"]["isValid"] = true;
            setCriterias([...criterias]);
          } else {
            criterias[indexCriteria]["attributes"]["isValid"] = false;
            setCriterias([...criterias]);
            countValid += 1;
          }
        }
        if (countValid === indexOfLast - indexOfFirst) {
          setCurrentPage(currentPage + step);
        }
      } else if (action === "previous") {
        setCurrentPage(currentPage + step);
      }
    }
  };

  const renderAction = () => {
    return (
      <Row>
        <Col md="12" className="d-flex flex-row-reverse">
          {currentPage !== totalPage && (
            <Button className="mt-5 mx-2" color="primary" type="button" onClick={() => handleChangePageCriteria(1, "next")}>
              {i18n.t("btnNext")}
              <i className="fas fa-arrow-right ml-2"></i>
            </Button>
          )}
          {currentPage === totalPage && (
            <Button className="mt-5" color="success mx-4" type="button" onClick={handleCreateReport} disabled={loading}>
              {loading ? <i className="fa fa-spinner fa-spin"></i> : i18n.t("save")}
            </Button>
          )}
          {(currentPage !== 1 || isValidLocation) && (
            <Button className="mt-5" color="primary mx-4" type="button" onClick={() => handleChangePageCriteria(-1, "previous")}>
              <i className="fas fa-arrow-left mr-2"></i>
              {i18n.t("btnPrevious")}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  const setImage = (checklistId, file, indexImage) => {
    let index = criterias.findIndex((critera) => critera.id === checklistId);
    criterias[index]["attributes"]["images"][indexImage] = file;
  };

  const renderUploadImageItem = (images, checklistId) => {
    return images.map((image, index) => {
      return <UploadItem key={index} image={image} index={index} setImage={setImage} checklistId={checklistId} />;
    });
  };

  const renderUploadImages = () => {
    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;

    return (
      <>
        {criterias.slice(indexOfFirst, indexOfLast).map((criteria, index) => {
          return (
            <Fragment key={index}>
              <Row className="mt-5">
                <Col md="12">
                  <h3>
                    {criteria.attributes.order}
                    {i18n.t("comma")}
                    {criteria.attributes.name}
                  </h3>
                  <p style={{ whiteSpace: "pre-wrap" }}>{criteria.attributes.description}</p>
                </Col>
              </Row>
              <Row className="custom-row-upload">
                <Col md="12" className="d-flex w-100 custom-col-upload">
                  {renderUploadImageItem(_.get(criteria, "attributes.images", []), criteria.id)}
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <small style={{ color: "#b00" }}>
                    {_.get(criteria, "attributes.isValid", false) && (
                      <p>
                        {_.get(criteria, "attributes.min_image_upload", false)}
                        {i18n.t("minImage")}
                      </p>
                    )}
                  </small>
                </Col>
              </Row>
            </Fragment>
          );
        })}
        {renderAction()}
      </>
    );
  };

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader>{isValidLocation ? renderUploadImages() : renderSelectLocation()}</CardHeader>
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default PhotoUpload;
