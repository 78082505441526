import i18n from "i18n/i18n";
export const CHART_TYPE = {
  SALE_TRENDS: "saleTrends",
  RATING_STORE: "ratingStore",
  OPERATION_QUALITY_1: "operationQuality1",
  OPERATION_QUALITY_2: "operationQuality2",
  OPERATION_QUALITY_3: "operationQuality3",
  MAKE_MISTAKE: "makeMistake",
  ACTIVE_TIME: "activeTime",
  ORDER_TIME: "orderTime",
};
export const TIME_UNIT = {
  MONTH: 1,
  WEEK: 2,
};

export const CHART_DATA = {
  saleTrends: {
    datasets: [
      {
        label: i18n.t("chart.saleTrends.salesTitle"),
        lineTension: 0,
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
      },
      {
        label: i18n.t("chart.saleTrends.salesPredictTitle"),
        lineTension: 0,
        borderColor: "red",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
      },
    ],
  },
  ratingStore: {
    datasets: [
      {
        label: i18n.t("chart.target"),
        data: [4.7, 4.7, 4.7, 4.7, 4.7, 4.7, 4.7, 4.7, 4.7, 4.7, 4.7, 4.7],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
      {
        label: i18n.t("chart.ratingStore.title"),
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
    ],
  },
  operationQuality1: {
    datasets: [
      {
        label: i18n.t("chart.target"),
        data: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
      {
        label: i18n.t("chart.operationQuality1.shipperReviewTitle"),
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
      {
        label: i18n.t("chart.operationQuality1.productReviewTitle"),
        borderColor: "#4bc0c0",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
    ],
  },
  operationQuality2: {
    datasets: [
      {
        label: i18n.t("chart.target"),
        data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
      {
        label: i18n.t("chart.operationQuality2.onlineRateTitle"),
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
    ],
  },
  operationQuality3: {
    datasets: [
      {
        label: i18n.t("chart.target"),
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
      {
        label: i18n.t("chart.operationQuality3.numberPrepTimeIncreaseTitle"),
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
    ],
  },
  makeMistake: {
    datasets: [
      {
        label: i18n.t("chart.target"),
        data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
      {
        label: i18n.t("chart.makeMistake.title"),
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
    ],
  },
  activeTime: {
    datasets: [
      {
        label: i18n.t("chart.target"),
        data: [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
      {
        label: i18n.t("chart.activeTime.title"),
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
    ],
  },
  orderTime: {
    datasets: [
      {
        label: i18n.t("chart.target"),
        data: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
      {
        label: i18n.t("chart.orderTime.title"),
        borderColor: "green",
        backgroundColor: "rgba(0, 0, 0, 0)",
        fill: false,
        lineTension: 0,
      },
    ],
  },
};
