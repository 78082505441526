
/*eslint-disable*/
import { useState, useEffect } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { CommonService } from "../../services/common-service";
import { ROLE, CONSTANT } from "../../constants/constant";
import ModalChangePassword from "../Modal/ModalChangePassword";
import i18n from "i18n/i18n";

const commonService = new CommonService();

var ps;

const Sidebar = (props) => {
  const history = useHistory();
  const { locationIds, companyIds, role } = props;
  
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [isOpen, setIsOpen] = useState({});
  const [locations, setLocations] = useState([]);
  const [openLocation, setOpenLocation] = useState({});
  const [isOpenModalChangePass, setIsOpenModalChangePass] = useState(false);

  const handleOpenChangePassword = () => {
    setIsOpenModalChangePass(true);
  };

  const onCancelModalChangePassword = () => {
    setIsOpenModalChangePass(false);
  }

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const handleCollabSideBar = (key) => {
    setIsOpen({ [key]: !isOpen[[key]] })
  };

  const handleCollabLocation = (key) => {
    setOpenLocation({ [key]: !openLocation[[key]] })
  };

  const getLocations = async () => {
    let params = {};
    if (role === ROLE.MANAGE_COMPANY) {
      params = {
        populate: "company, stores",
        "filters[company][id][$in]": companyIds,
        "pagination[limit]": CONSTANT.LIST_LIMIT
      };
    }

    if (role === ROLE.MANAGE_LOCATION) {
      params = {
        populate: "stores",
        "filters[id][$in]": locationIds,
        "pagination[limit]": CONSTANT.LIST_LIMIT
      }
    }

    try {
      const res = await commonService.getLocations(params);
      setLocations(res['data']['data']);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  useEffect(() => {
    if (locationIds.length > 0 || companyIds.length > 0) {
      getLocations();
    }
  }, [locationIds, companyIds]);

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    let result = routes.map((prop, key) => {
      if (prop.subNav) {
        return (
          <NavItem key={key}>
            <NavLink onClick={e => handleCollabSideBar(key)}>
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
            <Collapse isOpen={isOpen[key]}>
              <Nav >
                {prop.subNav.map((item, key) => {
                  return (
                    <NavItem key={key}>
                      <NavLink
                        to={item.layout + item.path}
                        tag={NavLinkRRD}
                        onClick={closeCollapse}
                        activeClassName="active"
                      >
                        <i className={item.icon} />
                        {item.name}
                      </NavLink>
                    </NavItem>
                  )
                })}
              </Nav>
            </Collapse>
          </NavItem>
        )
      }
      if (prop.path === '/dashboard') {
        return (
          <NavItem key={key}>
            <NavLink onClick={e => handleCollabSideBar(key)}>
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
            <Collapse isOpen={isOpen[key]}>
              <Nav >
                <NavItem>
                  <NavLink
                    to={prop.layout + prop.path + '/all'}
                    tag={NavLinkRRD}
                    activeClassName="active"
                  >
                    <i className="ni ni-world text-primary" />
                    { i18n.t("sideBar.dashboard.all") }
                  </NavLink>
                </NavItem>
                {locations.map((location, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        to={prop.layout + prop.path + '/location/'  + location.id}
                        tag={NavLinkRRD}
                        onClick={e => handleCollabLocation(index)}
                      >
                        <i className="ni ni-world text-primary" />
                        { location.attributes.name }
                      </NavLink>
                      <Collapse isOpen={openLocation[index]}>
                        <Nav>
                          {
                            _.get(location, "attributes.stores.data", []).map((item, index) => {
                              return (
                                <NavItem key={key + '_' + index}>
                                  <NavLink
                                    to={prop.layout + prop.path + '/brand/' + location.id + '/' + item.id}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                  >
                                    <i className="fa fa-code-branch"></i>
                                    { item.attributes.name }
                                  </NavLink>
                                </NavItem>
                              )
                            })
                          }
                        </Nav>
                      </Collapse>
                    </NavItem>
                  )
                })}
              </Nav>
            </Collapse>
          </NavItem>
        )
      }
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
    return result;
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.replace("/auth/login");
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <i className="fa fa-cog text-dark"></i>
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem onClick={handleOpenChangePassword}>
                <i className='fas fa-key'></i>
                <span>{ i18n.t("changePassword") }</span>
              </DropdownItem>
              <DropdownItem onClick={handleLogout}>
                <i className="ni ni-user-run" />
                <span>{ i18n.t("logout") }</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6" />
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
      { isOpenModalChangePass && (
        <ModalChangePassword
          isOpen={isOpenModalChangePass}
          onCancel={onCancelModalChangePassword}
        />
      ) }
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
  role: PropTypes.string.isRequired,
  locationIds: PropTypes.arrayOf(PropTypes.number),
  companyIds: PropTypes.arrayOf(PropTypes.number),
};

export default Sidebar;
