import validator from 'validator';
import i18n from '../i18n/i18n';
import { ERORR_TYPE } from "../constants/errorType"
import { isArray, isString } from 'lodash'

export default class Validator {
  static isValidEmail(email) {
    return validator.isEmail(email);
  }

  static isValidPassword(password) {
    const { length } = password;

    if (length < 8) {
      return false;
    }
    return true;
  }
  static checkEmail(email) {
    let isValidEmail = true;
    let invalidEmailText = '';

    if (!validator.isEmail(email)) {
      isValidEmail = false;
      invalidEmailText = i18n.t('invalidEmail');
    }
    return ({
      isValidEmail,
      invalidEmailText,
    });
  }
  static emptyValue = (item, params) => {
    if (isArray(params[item]) && !params[item].length) {
      return ERORR_TYPE.IS_REQUIRED
    } else if (isString(params[item]) && !params[item]) {
      return ERORR_TYPE.IS_REQUIRED
    } else if (params[item] === undefined) {
      return ERORR_TYPE.IS_REQUIRED
    }
  }
}

