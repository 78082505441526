import { Card, CardBody, CardTitle, Row } from "reactstrap";
import i18n from "../../i18n/i18n";
import PropTypes from "prop-types";

const CardDashBoard = (props) => {
  const { data, loading } = props;

  return (
    <Card className="card-stats mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {i18n.t("dashboard." + props.cardType)}
            </CardTitle>
          </div>
        </Row>
        <span className="h2 font-weight-bold mb-0">{ !loading ? data : ""}</span>
      </CardBody>
    </Card>
  );
};

PropTypes.propTypes = {
  data: PropTypes.any.isRequired,
};

export default CardDashBoard;
