import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  CardBody,
} from "reactstrap";
import i18n from "../../../i18n/i18n";
import { CommonService } from "../../../services/common-service";
import Auth from "../../../helpers/auth";
import { ERORR_TYPE } from "../../../constants/errorType";
import InputText from "../../../components/Input/InputText";
import InputAutocomplete from "../../../components/Input/InputAutocomplete";
import InputDate from "../../../components/Input/InputDate";
import { PROVINCE_OPTIONS } from "constants/provinces";

const commonService = new CommonService();
const pathname = "/admin/location";

const CreateEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [errorTypes, setErrorTypes] = useState([]);
  const [userSelected, setUserSelected] = useState();
  const [companySelected, setCompanySelected] = useState({});
  const [zipcode, setZipcode] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [province, setProvince] = useState("");
  const [expectedDateHygieneReport, setExpectedDateHygieneReport] = useState("");

  async function companyLoadOptions(search) {
    let result;
    if (search) {
      result = await commonService.getCompanies({
        "filters[name][$contains]": search,
        "pagination[limit]": -1,
      });
    } else {
      result = await commonService.getCompanies({ "pagination[limit]": -1 });
    }
    const data = get(result, "data.data", "");
    return handleLoadCompanyOption(data);
  };

  async function userLoadOptions(search) {
    let result;
    let params = {
      populate: "users_permissions_user"
    };
    if (search) {
      params = {
        ...params,
        "filters[users_permissions_user][username][$contains]": search,
      }
      result = await commonService.getListProfile(params);
    } else {
      result = await commonService.getListProfile(params);
    }
    return handleLoadOptionUser(result);
  };

  const handleSelectCompany = (props) => {
    setCompanySelected(props);
  };

  const handleSelectUser = (props) => {
    setUsers(handleOptionSelected(props));
    setUserSelected(props);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    let param = {
      data: {
        name: locationName,
        company: companySelected.value,
        address: address,
        profiles: users,
        zip_code: zipcode,
        province: province.value,
        contact,
        expected_date_hygiene_report: expectedDateHygieneReport,
      },
    };

    const isValid = validate();
    if (isValid) {
      if (!id) {
        handleCreate(param);
      } else {
        handleEdit(id, param);
      }
    }
  };

  const handleCreate = async (param) => {
    param["data"]["user_created_id"] = Auth.user().id;
    await commonService.createLocation(param);
    history.push(`${pathname}/list`);
  };

  const handleEdit = async (id, param) => {
    param["data"]["user_updated_id"] = Auth.user().id;
    await commonService.editLocation(id, param);
    history.push(`${pathname}/list`);
  };

  const handleLoadCompanyOption = (data) => {
    let list = [];
    if (data.length) {
      list = data.map((item) => {
        const id = get(item, "id");
        const name = get(item, "attributes.name");
        return {
          value: id,
          label: name,
        };
      });
    }
    return {
      options: list,
      hasMore: false,
    };
  };

  const handleLoadOptionUser = async (result) => {
    const data = get(result, "data.data", "");
    let list = [];
    if (data) {
      data.map((item) => {
        let value = get(item, "id", "");
        let label = get(
          item,
          "attributes.users_permissions_user.data.attributes.username",
          ""
        );
        list.push({
          value: value,
          label: label,
        });
        return list;
      });
    }
    return {
      options: list,
      hasMore: false,
    };
  };

  const handleOptionSelected = (props) => {
    let selected = [];
    props.map((item) => {
      selected = [...selected, item.value];
      return selected;
    });
    return selected;
  };

  const validate = () => {
    let errors = {};
    if (!locationName) {
      errors["locationName"] = ERORR_TYPE.IS_REQUIRED;
    }
    if (!expectedDateHygieneReport) {
      errors["expectedDateHygieneReport"] = ERORR_TYPE.IS_REQUIRED;
    }
    setErrorTypes(errors);
    return isEmpty(errors);
  };

  const getLocation = async (id) => {
    const result = await commonService.getLocation(
      `${id}?populate=profiles, company`
    );
    const location = get(result, "data.data.attributes", "");
    const users = get(location, "profiles.data", "");
    let profileIds = [];
    for (let i = 0; i < users.length; i++) {
      profileIds = [...profileIds, users[i].id];
    }
    if (profileIds.length > 0) {
      const profiles = await commonService.getListProfile({
        populate: "users_permissions_user",
        "filters[id][$in]": profileIds,
      });
      const profileUsers = get(profiles, "data.data", []);
      if (profileUsers.length) {
        let locationUsers = [];
        let userDefaultState = [];
        profileUsers.map((item) => {
          let value = get(item, "id", "");
          let label = get(
            item,
            "attributes.users_permissions_user.data.attributes.username",
            ""
          );
          locationUsers.push({
            value: value,
            label: label,
          });
          userDefaultState.push(item["id"]);
          return locationUsers;
        });
        setUsers(userDefaultState);
        setUserSelected(locationUsers);
      }
    }
    const selectedCompany = get(location, "company.data");
    setCompanySelected({
      value: get(selectedCompany, "id"),
      label: get(selectedCompany, "attributes.name"),
    });
    setLocationName(location.name);
    setAddress(location.address);
    setZipcode(location.zip_code);
    setProvince({ value: location.province, label: location.province });
    setContact(location.contact);
    setExpectedDateHygieneReport(location.expected_date_hygiene_report);
  };

  useEffect(() => {
    console.log("🚀 ~ file: CreateEdit.js ~ line 186 ~ useEffect ~ id", id);
    if (id) {
      getLocation(id);
    }
  }, [id]);

  const renderNameField = () => (
    <InputText
      onChange={setLocationName}
      value={locationName}
      label="locationName"
      errors={errorTypes}
    />
  );

  const renderCompanyField = () => (
    <InputAutocomplete
      label="locationCompany"
      value={companySelected}
      loadOptions={companyLoadOptions}
      onChange={handleSelectCompany}
      isFormGroup={true}
    />
  );

  const renderZipcodeField = () => (
    <InputText onChange={setZipcode} value={zipcode} label="zipcode" />
  );

  const renderProvinceField = () => (
    <InputAutocomplete
      label="province"
      value={province}
      options={PROVINCE_OPTIONS}
      onChange={setProvince}
      isFormGroup={true}
    />
  );

  const renderAddressField = () => (
    <InputText onChange={setAddress} value={address} label="locationAddress" />
  );

  const renderManagerField = () => (
    <InputAutocomplete
      label="personInCharge"
      value={userSelected}
      loadOptions={userLoadOptions}
      onChange={handleSelectUser}
      selectMulti={true}
      isFormGroup={true}
    />
  );

  const renderContactField = () => (
    <InputText onChange={setContact} value={contact} label="contact" />
  );

  const renderExpectedDateHygieneReport = () => (
    <InputDate
      label="expectedDateHygieneReport"
      value={expectedDateHygieneReport}
      onChange={setExpectedDateHygieneReport}
      errors={errorTypes}
    />
  )

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    {id
                      ? i18n.t("screenTitle.locationEdit")
                      : i18n.t("screenTitle.locationCreate")}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form role="form">
                <div className="pl-lg-4">
                  {renderNameField()}
                  {renderCompanyField()}
                  {renderZipcodeField()}
                  {renderProvinceField()}
                  {renderAddressField()}
                  {renderManagerField()}
                  {renderContactField()}
                  {renderExpectedDateHygieneReport() }
                </div>
                <div className='text-center'>
                  { id && (
                    <Button
                      className="mt-4"
                      color="secondary"
                      type="button"
                      onClick={handleCancel}
                    >
                      {i18n.t("cancel")}
                    </Button>
                  ) }
                  <Button
                    className="mt-4"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {i18n.t("save")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Container>
  );
};
export default CreateEdit;
