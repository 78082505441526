import { AsyncPaginate } from "react-select-async-paginate";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  CardBody,
} from "reactstrap";
import i18n from "../../../i18n/i18n";
import { CommonService } from "../../../services/common-service";
import Auth from "../../../helpers/auth";
import { ERORR_TYPE } from "../../../constants/errorType";

const commonService = new CommonService();
const pathname = "/admin/company";

const CreateEdit = () => {
  const history = useHistory();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [errorTypes, setErrorTypes] = useState([]);
  const [userSelected, setUserSelected] = useState();

  async function userLoadOptions(search, loadOptions) {
    let result;
    let params = {
      populate: "users_permissions_user"
    }
    if (search) {
      params = {
        ...params,
        "filters[users_permissions_user][username][$contains]": search,
      }
      result = await commonService.getListProfile(params);
    } else {
      result = await commonService.getListProfile(params);
    }

    return handleLoadOptionUser(result);
  }

  const handleSelectUser = (props) => {
    setUsers(handleOptionSelected(props));
    setUserSelected(props);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    let param = {
      data: {
        name: companyName,
        address: companyAddress,
        profiles: users,
      },
    };
    
    const isValid = validate();

    if (isValid) {
        if (!id) {
          handleCreate(param);
        } else {
          handleEdit(id, param);
        }
    }
  };

  const handleCreate = async (param) => {
    param["data"]["user_created_id"] = Auth.user().id;
    await commonService.createCompany(param);
    history.push(`${pathname}/list`);
  };

  const handleEdit = async (id, param) => {
    param["data"]["user_updated_id"] = Auth.user().id;
    await commonService.editCompany(id, param);
    history.push(`${pathname}/list`);
  };

  const handleLoadOptionUser = async (result) => {
    const data = get(result, "data.data", "");
    let list = [];
    if (data) {
      data.map((item) => {
        let value = get(item, "id", "");
        let label = get(item, "attributes.users_permissions_user.data.attributes.username", "");
        list.push({
          value: value,
          label: label,
        });
        return list;
      });
    }
    return {
      options: list,
      hasMore: false,
    };
  };

  const handleOptionSelected = (props) => {
    let selected = [];
    props.map((item) => {
      selected = [...selected, item.value];
      return selected;
    });
    return selected;
  };

  const validate = () => {
    let errors = {};
    if (!companyName) {
      errors["companyName"] = ERORR_TYPE.IS_REQUIRED;
    }
    setErrorTypes(errors);
    return isEmpty(errors);
  };

  const getCompany = async (id) => {
    const result = await commonService.getCompany(
      `${id}?populate=profiles`
    );
    const company = get(result, "data.data.attributes", "");
    const users = get(company, "profiles.data", "");
    let profileIds = [];
    for (let i = 0; i < users.length; i++) {
      profileIds = [...profileIds, users[i].id];
    }

    if (profileIds.length > 0) {
      const profiles = await commonService.getListProfile({ populate: "users_permissions_user", "filters[id][$in]": profileIds });
      const profileUsers = get(profiles, "data.data", []);
      if (profileUsers.length) {
        let companyUsers = [];
        let userDefaultState = [];
        profileUsers.map((item) => {
          let value = get(item, "id", "");
          let label = get(item, "attributes.users_permissions_user.data.attributes.username", "");
          companyUsers.push({
            value: value,
            label: label,
          });
          userDefaultState.push(item["id"]);
          return companyUsers;
        });
        setUsers(userDefaultState);
        setUserSelected(companyUsers);
      }
    }
    setCompanyName(company["name"]);
    setCompanyAddress(company["address"]);
  };

  useEffect(() => {
    if (id) {
      getCompany(id);
    }
  }, [id]);

  return (
    <Container className="mt--7" fluid>
      {/* Table */}
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    {i18n.t("screenTitle.companyList") + " - "}{" "}
                    {id ? i18n.t("editCompany") : i18n.t("addCompany")}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form role="form">
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-companyName"
                        >
                          {i18n.t("companyName")}
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          value={companyName}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                        />
                        <small style={{ color: "#b00" }}>
                          {i18n
                            .t(errorTypes["companyName"])
                            .replace("(param1)", i18n.t("companyName"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-personInCharge"
                        >
                          {i18n.t("personInCharge")}
                        </label>
                        <AsyncPaginate
                          value={userSelected}
                          loadOptions={userLoadOptions}
                          isMulti="true"
                          onChange={(e) => handleSelectUser(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          {i18n.t("address")}
                        </label>
                        <Input
                          value={companyAddress}
                          className="form-control-alternative"
                          type="text"
                          onChange={(e) => {
                            setCompanyAddress(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="text-center">
                  { id && (
                    <Button
                      className="mt-4"
                      color="secondary"
                      type="button"
                      onClick={handleCancel}
                    >
                      {i18n.t("cancel")}
                    </Button>
                  ) }
                  <Button
                    className="mt-4"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {i18n.t("save")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Container>
  );
};
export default CreateEdit;
