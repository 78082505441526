import UserHeader from "components/Headers/UserHeader";
import { Switch, Route } from "react-router-dom";
import Detail from "./Detail";
import List from "./List";

const ManageHygiene = () => {
  const pathname = "/admin/manage-hygiene";
  return (
    <>
      <UserHeader />
      <Switch>
        <Route path={`${pathname}/edit/:id`} component={Detail} />
        <Route path={`${pathname}`} component={List} />
      </Switch>
    </>
  );
};

export default ManageHygiene;
