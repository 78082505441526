export const CARD_TYPE = {
    SUCCESSIVE_SALES_TOTAL_PREDICT: "successiveSalesTotalPredict",
    SUCCESSIVE_SALES_TOTAL : "successiveSalesTotal",
    SUCCESSIVE_SALES_TOTAL_PREDICT_UBEREATS: "successiveSalesTotalPredictUbereats",
    SUCCESSIVE_SALES_TOTAL_PREDICT_DEMAECAN: "successiveSalesTotalPredictDemaecan",
    SUCCESSIVE_SALES_TOTAL_UBEREATS: "successiveSalesTotalUbereats",
    SUCCESSIVE_SALES_TOTAL_DEMAECAN: "successiveSalesTotalDemaecan",
    AVERAGE_TIME: "averageTime",
    STORE_NUMBER: "storeNumber",
    STORE_NUMBER_ACTIVE: "storeNumberActive",
    STORE_NUMBER_STANDARD: "storeNumberStandard",
    ACTUAL_TIME_ACTIVE: "actualTimeActive",
    STORE_REVIEW: "storeReview",
    WRONG_ORDER_RATE: "wrongOrderRate",
    PREPTIME_ORDER: "prepTimeOrder",
    TARGET_TIME_ACTIVE: "targetTimeActive",
    SHIPPER_REVIEW: "shipperReview",
    PRODUCT_REVIEW: "productReview",
    ONLINE_RATE: "onlineRate",
    LOSS_DUE_TO_NOT_WORKING_8_HOURS: "lossDueToNotWorking8Hours",
    NUMBER_OF_TIMES_TO_EXTEND_PREP_TIME: "numberOfTimesToExtendPrepTime",
}
