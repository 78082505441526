import i18n from "i18n/i18n";

export const CONSTANT = {
  LIST_LIMIT: 100,
  PAGE_SIZE_LIMIT: 20,
};

export const ROLE = {
  SUPER_ADMIN: "管理者",
  MANAGE_COMPANY: "企業責任者",
  MANAGE_LOCATION: "拠点責任者",
};

export const HEADER_CSV_LOCATION = [
  { label: i18n.t("locationName"), key: "name" },
  { label: i18n.t("zipcode"), key: "zip_code" },
  { label: i18n.t("province"), key: "province" },
  { label: i18n.t("locationAddress"), key: "address" },
  { label: i18n.t("manager"), key: "profiles" },
  { label: i18n.t("address"), key: "contact" },
];

export const DATA_CSV_LOCATION_EXAMPLE = [{ name: "AAA", zip_code: "123", province: "北海道", address: "Japan", profiles: "admin@vradmin.com", contact: "AAA" }];

export const FREQUENCY_OF_HYGIENE_REPORTS = 3;
