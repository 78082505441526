import i18n from "i18n/i18n";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, FormGroup, Row } from "reactstrap";

const InputAutocomplete = ({
  label,
  value,
  loadOptions,
  options,
  onChange,
  className,
  isDisabled = false,
  selectMulti = false,
  isHorizontal = false,
  isFormGroup = false,
}) => {
  const style = {
    wrapper: "d-flex align-items-baseline",
    label: "w-25",
    input: "w-75",
  };
  return (
    <Row className={className}>
      <Col md="12" className={isHorizontal ? style.wrapper : ""}>
        { isFormGroup ? (
          <FormGroup>
            <label
              className={`form-control-label ${isHorizontal ? style.label : ""}`}
            >
              {i18n.t(label)}
            </label>
            <AsyncPaginate
              value={value}
              loadOptions={loadOptions}
              onChange={onChange}
              isMulti={selectMulti}
              className={isHorizontal ? style.input : ""}
              isDisabled={isDisabled}
              options={options}
            />
          </FormGroup>
        ) : (
          <>
            <label
              className={`form-control-label ${isHorizontal ? style.label : ""}`}
            >
              {i18n.t(label)}
            </label>
            <AsyncPaginate
              value={value}
              loadOptions={loadOptions}
              onChange={onChange}
              isMulti={selectMulti}
              className={isHorizontal ? style.input : ""}
              isDisabled={isDisabled}
              options={options}
            />
          </>
        ) }
      </Col>
    </Row>
  );
};

export default InputAutocomplete;
