import InputAutocomplete from "components/Input/InputAutocomplete";
import CustomPagination from "components/Pagination";
import { CONSTANT } from "constants/constant";
import { FILTER_STATUS_OPTIONS } from "constants/hygieneManage";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { get, isEqual, isEmpty, isNull } from "lodash";
import moment from "moment";
import { CommonService } from "services/common-service";
import i18n from "../../../i18n/i18n";

const commonService = new CommonService();

const OPTION_ALL = {
  value: -1,
  label: i18n.t("optionAll"),
};

const List = () => {
  const history = useHistory();
  const [reports, setReports] = useState([]);
  const [isDisableSelectLocation, setIsDisableLocation] = useState(true);
  const [companySelected, setCompanySelected] = useState(OPTION_ALL);
  const [locationSelected, setLocationSelected] = useState(OPTION_ALL);
  const [statusSelected, setStatusSelected] = useState(OPTION_ALL);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 1,
    pageCount: 1,
    total: 1,
  });
  const [filters, setFilters] = useState({
    pageSize: CONSTANT.PAGE_SIZE_LIMIT,
    page: 1,
  });
  const handleDetailReport = (id) => {
    history.push(`/admin/manage-hygiene/edit/${id}`);
  };
  const handleSelectCompany = (company) => {
    setCompanySelected(company);
    setIsDisableLocation(false);
    setLocationSelected(OPTION_ALL);
  };
  const handlePageChange = (newPage) => {
    const newFilters = {
      ...filters,
      page: newPage,
    };
    setFilters(newFilters);
  };
  const formatOptions = (rawOptions) => {
    const data = get(rawOptions, "data.data", []);
    const formattedOptions = data.map((item) => {
      return {
        value: get(item, "id"),
        label: get(item, "attributes.name"),
      };
    });
    return {
      options: [OPTION_ALL, ...formattedOptions],
      hasMore: false,
    };
  };
  const formatReports = (rawReports) => {
    return rawReports.map((report) => {
      const attributes = get(report, "attributes", {});
      const id = get(report, "id");
      if (isEmpty(attributes)) {
        return {};
      } else {
        const company = {
          id: get(attributes, "company.data.id"),
          name: get(attributes, "company.data.attributes.name"),
        };
        const location = {
          id: get(attributes, "location.data.id"),
          name: get(attributes, "location.data.attributes.name"),
        };
        const createdAt = get(attributes, "createdAt");
        const confirmedDate = get(attributes, "confirmed_date");
        const totalPoint = get(attributes, "total_point");
        const statusValue = get(attributes, "status", {});
        const status = FILTER_STATUS_OPTIONS.find(
          (s) => s.value === statusValue
        );
        return {
          id,
          company,
          location,
          confirmedDate: isNull(confirmedDate)
            ? ""
            : moment(report.confirmedDate).format("YYYY/MM/DD"),
          createdAt,
          totalPoint,
          status,
        };
      }
    });
  };
  const fetchCompanies = async (search) => {
    let result;
    if (search) {
      result = await commonService.getCompanies({
        populate: "locations",
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getCompanies({
        populate: "locations",
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return formatOptions(result);
  };
  const fetchLocations = async (search) => {
    let result;
    if (search) {
      result = await commonService.getLocations({
        populate: "company",
        "filters[company][id][$in]": companySelected.value,
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getLocations({
        populate: "company",
        "filters[company][id][$in]": companySelected.value,
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return formatOptions(result);
  };
  const fetchReports = useCallback(async () => {
    let param = {
      populate: "location, company",
    };
    const { pageSize, page, ...filterRest } = filters;
    param[`pagination[pageSize]`] = pageSize;
    param[`pagination[page]`] = page;
    for (const query in filterRest) {
      const filterValue = filters[query];
      if (filterValue !== -1) {
        param[`filters[${query}]`] = filters[query];
      }
    }
    const result = await commonService.getReports(param);
    const data = get(result, "data.data", "");
    const formattedReports = formatReports(data);
    const meta = get(result, "data.meta.pagination", "");

    setReports(formattedReports);
    setPagination(meta);
  }, [filters]);

  useEffect(() => {
    let newFilters = {
      ...filters,
    };
    if (companySelected.value) {
      newFilters.company = companySelected.value;
    }
    if (locationSelected.value) {
      newFilters.location = locationSelected.value;
    }
    if (statusSelected.value) {
      newFilters.status = statusSelected.value;
    }
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySelected.value, locationSelected.value, statusSelected.value]);

  useEffect(() => {
    fetchReports();
  }, [filters, fetchReports]);

  const renderFilter = () => {
    return (
      <div className="mt-3">
        <InputAutocomplete
          label="filter.company"
          value={companySelected}
          loadOptions={fetchCompanies}
          onChange={handleSelectCompany}
          isHorizontal
          className="mb-3"
        />
        <InputAutocomplete
          key={companySelected.value}
          label="filter.location"
          value={locationSelected}
          loadOptions={fetchLocations}
          onChange={(location) => setLocationSelected(location)}
          isHorizontal
          className="mb-3"
          isDisabled={isDisableSelectLocation}
        />
        <InputAutocomplete
          label="filter.status"
          value={statusSelected}
          options={[OPTION_ALL, ...FILTER_STATUS_OPTIONS]}
          onChange={(status) => setStatusSelected(status)}
          isHorizontal
          className="mb-3"
        />
      </div>
    );
  };

  const renderList = () => {
    if (!reports.length) return null;
    return reports.map((report) => (
      <tr key={report.id}>
        <td>{report?.company?.name}</td>
        <td>{report?.location?.name}</td>
        <td>{report?.totalPoint}</td>
        <td>{moment(report?.createdAt).format("YYYY/MM/DD")}</td>
        <td>{report?.confirmedDate}</td>
        <td>{report?.status?.label}</td>
        <td>
          <Button
            onClick={() => handleDetailReport(report.id)}
            className="bg-success px-3 py-1 rounded text-white border-0 shadow-none shadow-none--hover"
          >
            {i18n.t("confirm")}
          </Button>
        </td>
      </tr>
    ));
  };

  const renderTable = () => {
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">{i18n.t("companyName")}</th>
            <th scope="col">{i18n.t("locationName")}</th>
            <th scope="col">{i18n.t("point")}</th>
            <th scope="col">{i18n.t("uploadDate")}</th>
            <th scope="col">{i18n.t("confirmDate")}</th>
            <th scope="col">{i18n.t("status")}</th>
            <th scope="col">{i18n.t("action")}</th>
          </tr>
        </thead>
        <tbody>{renderList()}</tbody>
      </Table>
    );
  };

  const renderPagination = () => {
    return (
      <CardFooter className="py-4">
        <nav aria-label="...">
          <CustomPagination
            pagination={pagination}
            onPageChange={handlePageChange}
          />
        </nav>
      </CardFooter>
    );
  };

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col>
                  <h3 className="mb-0">
                    {i18n.t("screenTitle.hygieneManageList")}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col xs="8">{renderFilter()}</Col>
              </Row>
            </CardHeader>
            {renderTable()}
            {renderPagination()}
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default List;
