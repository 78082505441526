import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  Col,
  CardHeader,
} from "reactstrap";
import _ from "lodash";
import moment from "moment";

import i18n from "i18n/i18n";
import { CommonService } from "../../../services/common-service";
import { useParams } from "react-router-dom";

const commonService = new CommonService();

const Detail = () => {

  const { id } = useParams();
  const [report, setReport] = useState({});

  useEffect(() => {
    if (id) {
      fetchReportDetail(id);
    }
  }, [id]);

  const fetchReportDetail = async (id) => {
    try {
      const res = await commonService.getReport(`${id}?populate=location, company, report_criteria_links`);
      const data = _.get(res, "data.data", {});
      setReport(data);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const renderImage = (image) => {
    return (
      <div
        className="w-20 mx-2 image-box d-flex align-items-center bg-lighter"
      >
        <a href={image} target="_blank" rel="noreferrer" >
          <img src={ image ? image : "https://via.placeholder.com/1200x1200" } alt="" className="w-100" />
        </a>
      </div>
    )
  };

  const renderCriteria = (criteria) => {
    return (
      <>
        <Row className="mt-5">
          <Col md="12">
            <h3>{ _.get(criteria, "attributes.order", "") }. { _.get(criteria, "attributes.name", "") }</h3>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <h5>{ i18n.t("score") }（ { i18n.t("perfectScore") }：{ _.get(criteria, "attributes.maxPoint", "") }）</h5>
            <div className="border rounded d-flex justify-content-center align-items-center p-3 display-1">
              { _.get(criteria, "attributes.point", "") ? _.get(criteria, "attributes.point", "") : "-" }
            </div>
          </Col>
          <Col md="9">
            <h5>
              { i18n.t("comment") }
            </h5>
            <p>
              { _.get(criteria, "attributes.comment", "") }
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md="12" className="d-flex">
            { _.get(criteria, "attributes.report_images.data", []).map((value, index) => {
              return (
                <Fragment key={index}>
                  { renderImage(_.get(value, "attributes.url", "")) }
                </Fragment>
              )
            }) } 
          </Col>
        </Row>
      </>
    )
  };

  return (
    <Container className='mt--7' fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center my-2">
                <Col md="12" className="text-center mb-4">
                  <h2 className="mb-0">
                    {i18n.t("screenTitle.hygieneManage")}
                  </h2>
                </Col>
              </Row>
              <Row className="my-2">
                <Col md="12" className="d-flex">
                  <div className="font-weight-bold mr-5">
                    { i18n.t("locationName") } : { _.get(report, "attributes.location.data.attributes.name", "") }
                  </div>
                  <div className="font-weight-bold mr-5">
                    { i18n.t("uploadDate") } : { _.get(report, "attributes.createdAt", "") ? moment(_.get(report, "attributes.createdAt", "")).format("YYYY/MM/DD") : "-" }
                  </div>
                  <div className="font-weight-bold mr-5">
                    { i18n.t("confirmDate") } : { _.get(report, "attributes.confirmed_date", "") ? moment(_.get(report, "attributes.confirmed_date", "")).format("YYYY/MM/DD") : "-" }
                  </div>
                </Col>
              </Row>
              <Row className="my-4">
                <Col md="12">
                  <div className="border d-flex justify-content-center flex-column align-items-center p-4 rounded">
                    <h1>{ i18n.t("point") }: { _.get(report, "attributes.total_point", "") ? _.get(report, "attributes.total_point", "") : "-" }</h1>
                    <div>{ i18n.t("anotationStandardHygiene") }</div>
                  </div>
                </Col>
              </Row>
              <Row className="my-4">
                <Col md="12">
                  <div className="border p-4 rounded">
                    <h3>{ i18n.t("overview") }</h3>
                    <p>{ _.get(report, "attributes.overview", "") }</p>
                  </div>
                </Col>
              </Row>
              { _.get(report, "attributes.report_criteria_links.data", []).map((value, index) => {
                return (
                  <Fragment key={index}>
                    {  renderCriteria(value) }
                  </Fragment>
                )
              })}
            </CardHeader>
          </Card>
        </div>
      </Row>
    </Container>
  )
}

export default Detail;