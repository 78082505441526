import { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { get, isEmpty } from "lodash";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import i18n from "../../../i18n/i18n";
import { CommonService } from "../../../services/common-service";
import { CONSTANT, ROLE } from "../../../constants/constant";
import { ERORR_TYPE } from "../../../constants/errorType";
import Validator from "../../../helpers/validator";
import Auth from "helpers/auth";

const commonService = new CommonService();

const radioListStyle = {
  display: "flex",
  justifyContent: "left",
};

const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [companies, setCompanies] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationsSelected, setLocationsSelected] = useState([]);
  const [companyDisable, setCompanyDisable] = useState(true);
  const [locationDisable, setLocationDisable] = useState(true);
  const [accountType, setAccountType] = useState(1);
  const [accountTypeName, setAccountTypeName] = useState('');
  const [listAccountType, setListAccountType] = useState([]);
  const [status, setStatus] = useState("false");
  const [errorTypes, setErrorTypes] = useState({});
  const history = useHistory();
  const [userId, setUserId] = useState();
  const { id } = useParams();
  const isEditable = useMemo(() => !!id, [id]);
  const [showPassword, setShowPassword] = useState(false);

  const getProfile = async (id) => {
    const result = await commonService.getProfile(
      `${id}?populate=users_permissions_user, companies, locations`
    );
    const data = get(result, "data.data.attributes", {});
    const companies = get(data, "companies.data", "");
    if (companies.length) {
      let companyUsers = [];
      let userDefaultState = [];
      companies.map((item) => {
        companyUsers.push({
          value: item["id"],
          label: item["attributes"]["name"],
        });
        userDefaultState.push(item["id"]);
        return companyUsers;
      });
      setCompanies(userDefaultState);
      setCompaniesSelected(companyUsers);
    }
    const locations = get(data, "locations.data");
    if (locations.length) {
      let locationUsers = [];
      let locationDefaultState = [];
      locations.map((location) => {
        locationUsers.push({
          value: location.id,
          label: location.attributes.name
        });
        locationDefaultState.push(location.id);
        return locationUsers;
      });
      setLocations(locationDefaultState);
      setLocationsSelected(locationUsers);
    }

    const accountTypeName = get(data, "role.data.attributes.name", "");
    setAccountTypeName(accountTypeName);
    if (accountTypeName === ROLE.MANAGE_COMPANY) {
      setCompanyDisable(false);
    };
    
    if (accountTypeName === ROLE.MANAGE_LOCATION) {
      setCompanyDisable(false);
      if (companies.length) {
        setLocationDisable(false);
      }
    }

    const userInfo = get(data, "users_permissions_user.data.attributes");
    const userPermissionId = get(data, "users_permissions_user.data.id");
    setUserId(userPermissionId);
    const email = get(userInfo, "email");
    setEmail(email);
    const status = "" + get(userInfo, "blocked");
    setStatus(status);
    const role = get(data, "role.data.id");
    setAccountType(role);
    const username = get(userInfo, "username", "");
    setUsername(username);
  };

  async function companyLoadOptions(search, loadOptions) {
    let result;
    if (search) {
      result = await commonService.getCompanies({
        populate: "locations",
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getCompanies({
        populate: "locations",
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return handleLoadOption(result);
  }

  async function locationLoadOptions(search, loadOptions) {
    let result;
    if (search) {
      result = await commonService.getLocations({
        populate: "company",
        "filters[company][id][$in]": companies,
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getLocations({
        populate: "company",
        "filters[company][id][$in]": companies,
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return handleLoadOption(result);
  }

  const handleSelectCompany = async (props) => {
    setCompanies(handleOptionSelected(props));
    const activeLocation = props.length && accountTypeName !== ROLE.MANAGE_COMPANY ? false : true;
    setLocationDisable(activeLocation);
    setLocations([]);
    setCompaniesSelected(props);
  };

  const handleSelectLocation = async (props) => {
    setLocations(handleOptionSelected(props));
    setLocationsSelected(props);
  };

  const handleChangeAccountType = (e, roleName) => {
    const { value } = e.target;
    setAccountType(+value);
    setAccountTypeName(roleName);

    switch(roleName) {
      case ROLE.SUPER_ADMIN:
        setCompanyDisable(true);
        setLocationDisable(true);
        setCompaniesSelected([]);
        setCompanies([]);
        setLocationsSelected([]);
        setLocations([]);
        break;
      case ROLE.MANAGE_COMPANY:
        setCompanyDisable(false);
        setLocationDisable(true);
        setLocationsSelected([]);
        setLocations([]);
        break;
      case ROLE.MANAGE_LOCATION:
        setCompanyDisable(false);
        if (companiesSelected.length > 0) {
          setLocationDisable(false);
        }
        break;
      default:
        break;
    }
  };

  const handleLoadOption = async (result) => {
    const data = get(result, "data.data", "");
    let list = [];
    if (data) {
      data.map((item) => {
        list.push({
          value: item["id"],
          label: item["attributes"]["name"],
        });
        return list;
      });
    }
    return {
      options: list,
      hasMore: false,
    };
  };

  const handleOptionSelected = (props) => {
    let selected = [];
    props.map((item) => {
      selected = [...selected, item.value];
      return selected;
    });
    return selected;
  };

  useEffect(() => {
    async function fetchDataRole() {
      let result = await commonService.getRoles();
      let roles = get(result, "data.roles", "");
      roles = roles.filter((role) => role.name !== "Public");
      setListAccountType(roles);
    }
    fetchDataRole();
  }, [listAccountType.length]);

  useEffect(() => {
    if (id) getProfile(id);
  }, [id]);

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    let params = {
      email: email,
      username: username,
    };
    if (!isEditable) {
      params["password"] = password;
    }
    
    if (accountTypeName === ROLE.MANAGE_COMPANY || accountTypeName === ROLE.MANAGE_LOCATION) {
      params["companies"] = companies;
    }

    const isValid = validate(params);

    if (isValid) {
      const { companies, ...filteredParams } = params;
      if (isEditable) {
        filteredParams["user_updated_id"] = Auth.user().id;
        const paramsEdit = {
          ...filteredParams,
          role: accountType,
          blocked: status === "true",
        };
        handleEdit(id, paramsEdit);
      } else {
        filteredParams["user_created_id"] = Auth.user().id;
        const paramsCreate = {
          ...filteredParams,
          role: accountType,
          blocked: status === "true",
        };
        handleRegister(paramsCreate);
      }
    }
  };

  const handleRegister = async (params) => {
    try {
      const res = await commonService.createUser(params);
      const userId = get(res, "data.id", "");
      if (userId !== "") {
        let paramsProfile = {
          data: {
            users_permissions_user: userId,
            companies: companies,
            locations: locations,
          },
        };
        await commonService.createProfile(paramsProfile);
      }
      history.push("/admin/accounts/list");
    } catch (err) {
      setErrorTypes({
        server: ERORR_TYPE.SOMETHING_WENT_WRONG,
      });
    }
  };

  const handleEdit = async (id, params) => {
    await commonService.editUser(userId, params);
    // Edit profile
    const paramsProfile = {
      data: {
        companies,
        locations,
      },
    };
    await commonService.editProfile(id, paramsProfile);
    history.push("/admin/accounts/list");
  };

  const validate = (params) => {
    let errors = {};
    const { isValidEmail } = Validator.checkEmail(email);
    if (!isValidEmail) {
      errors["email"] = ERORR_TYPE.INVALID_EMAIL;
    }
    for (const item in params) {
      let errorType = Validator.emptyValue(item, params);
      if (errorType) {
        errors[item] = errorType;
      }
    }
    setErrorTypes(errors);
    return isEmpty(errors);
  };

  const handleGeneratePassword = () => {
    let letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&^?.";
    let password = "";

    for (let i = 0; i < 8; i++) {
        let generate = letters[Math.floor(Math.random() * 64)];
        password += generate;
    }

    setPassword(password);
  };

  const renderInputPassword = () => {
    if (isEditable) return null;
    return (
      <>
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-password">
                {i18n.t("password")}
              </label>
              <InputGroup>
                <Input
                  className="form-control-alternative"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="primary"
                    type="button"
                    onClick={handleGeneratePassword}
                  >
                    { i18n.t("btnGenerate") } 
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <small style={{ color: "#b00" }}>
                {" "}
                {i18n
                  .t(errorTypes["password"])
                  .replace("(param1)", i18n.t("password"))}
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="mb-5 mx-3">
              <Input type="checkbox" value={showPassword} onChange={() => setShowPassword(!showPassword)} /> { i18n.t("showPassword") }
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Container className='mt--7' fluid>
      {/* Table */}
      <Row>
        <div className='col'>
          <Card className='shadow'>
            <CardHeader className='border-0'>
              <Row className='align-items-center'>
                <Col xs='8'>
                  <h3 className='mb-0'>
                    {i18n.t("screenTitle.accountList") +
                      " - " +
                      i18n.t("registerAccount")}
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <div className='text-center'>
              <small style={{ color: "#b00" }}>
                {" "}
                {i18n.t(errorTypes["server"])}{" "}
              </small>
            </div>
            <CardBody>
              <Form role='form'>
                <div className='pl-lg-4'>
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='input-email'
                        >
                          {i18n.t("email")}
                        </label>
                        <Input
                          className='form-control-alternative'
                          type='text'
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <small style={{ color: "#b00" }}>
                          {i18n
                            .t(errorTypes["email"])
                            .replace("(param1)", i18n.t("email"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='input-username'
                        >
                          {i18n.t("username")}
                        </label>
                        <Input
                          className='form-control-alternative'
                          type='text'
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                        />
                        <small style={{ color: "#b00" }}>
                          {i18n
                            .t(errorTypes["username"])
                            .replace("(param1)", i18n.t("username"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  {renderInputPassword()}
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='input-store'
                        >
                          {i18n.t("accountType")}
                        </label>
                        <div style={radioListStyle}>
                          {listAccountType.map((option) => {
                            return (
                              <div style={{ margin: "0 15px" }} key={option.id}>
                                <label>
                                  <Input
                                    type='radio'
                                    name='dynamic-radio'
                                    key={option.id}
                                    value={option.id}
                                    checked={accountType === option.id}
                                    onChange={(e) => handleChangeAccountType(e, option.name)}
                                  />
                                  {option.name}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                        <small style={{ color: "#b00" }}>
                          {" "}
                          {i18n
                            .t(errorTypes["role"])
                            .replace("(param1)", i18n.t("accountType"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='input-company'
                        >
                          {i18n.t("company")}
                        </label>
                        <AsyncPaginate
                          value={companiesSelected}
                          loadOptions={companyLoadOptions}
                          isMulti='true'
                          isDisabled={companyDisable}
                          onChange={(e) => handleSelectCompany(e)}
                        />
                        <small style={{ color: "#b00" }}>
                          {" "}
                          {i18n
                            .t(errorTypes["companies"])
                            .replace("(param1)", i18n.t("company"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='input-store'
                        >
                          {i18n.t("location")}
                        </label>
                        <AsyncPaginate
                          value={locationsSelected}
                          key={companies}
                          loadOptions={locationLoadOptions}
                          isMulti='true'
                          isDisabled={locationDisable}
                          onChange={(e) => handleSelectLocation(e)}
                        />
                        <small style={{ color: "#b00" }}>
                          {" "}
                          {i18n
                            .t(errorTypes["locations"])
                            .replace("(param1)", i18n.t("location"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <label
                          className='form-control-label'
                          htmlFor='input-store'
                        >
                          {i18n.t("status")}
                        </label>
                        <div style={radioListStyle}>
                          <div style={{ padding: "15px" }}>
                            <label>
                              <Input
                                type='radio'
                                value={false}
                                checked={status === "false"}
                                className='form-control-alternative'
                                onChange={(e) => setStatus(e.target.value)}
                              />
                              {i18n.t("active")}
                            </label>
                          </div>
                          <div style={{ padding: "15px" }}>
                            <label>
                              <Input
                                type='radio'
                                value={true}
                                checked={status === "true"}
                                className='form-control-alternative'
                                onChange={(e) => setStatus(e.target.value)}
                              />
                              {i18n.t("inactive")}
                            </label>
                          </div>
                        </div>
                        <small style={{ color: "#b00" }}>
                          {" "}
                          {i18n
                            .t(errorTypes["block"])
                            .replace("(param1)", i18n.t("status"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className='text-center'>
                  { id && (
                    <Button
                      className="mt-4"
                      color="secondary"
                      type="button"
                      onClick={handleCancel}
                    >
                      {i18n.t("cancel")}
                    </Button>
                  ) }
                  <Button
                    className='mt-4'
                    color='primary'
                    type='button'
                    onClick={handleSubmit}
                  >
                    {i18n.t("save")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Container>
  );
};
export default Register;
