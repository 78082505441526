import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import UserHeader from "components/Headers/UserHeader";
import ListStore from "./List";
import CreateEditStore from "./CreateEdit";

const Stores = () => {
  const pathname = '/admin/brand';
  return (
    <>
      <UserHeader />
      <Switch>
        <Route path={`${pathname}/list`} component={ListStore}/>
        <Route path={`${pathname}/create`} component={CreateEditStore}/>
        <Route path={`${pathname}/edit/:id`} component={CreateEditStore}/>
        <Redirect from="*" to={`${pathname}/list`} />
      </Switch>
    </>
  )
}

export default Stores;
