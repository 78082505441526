import { get, isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { CommonService } from "../../../services/common-service";
import i18n from "../../../i18n/i18n";
import CustomPagination from "../../../components/Pagination";
import { CONSTANT } from "../../../constants/constant";
import ModalConfirm from "../../../components/Modal/Modal";
import InputText from "../../../components/Input/InputText";

const commonService = new CommonService();
const pathname = "/admin/company";

const List = () => {
  const history = useHistory();
  const [listCompany, setListCompany] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 1,
    pageCount: 2,
    total: 2,
  });

  const [filters, setFilters] = useState({
    pageSize: CONSTANT.PAGE_SIZE_LIMIT,
    page: 1,
  });
  const [isShowModal, setIsShowModal] = useState(false);
  const [companyIdDelete, setCompanyIdDelete] = useState("");
  const [companyName, setCompanyName] = useState("");

  const getListCompany = async () => {
    let param = {
      populate: "profiles",
    };
    const { pageSize, page, name } = filters;
    param[`pagination[pageSize]`] = pageSize;
    param[`pagination[page]`] = page;

    if (name) {
      param[`filters[name][$contains]`] = name;
    }
    const result = await commonService.getCompanies(param);
    const data = get(result, "data.data", "");
    const meta = get(result, "data.meta.pagination", "");

    setPagination(meta);
    setListCompany(data);
  };

  const handleCreateCompany = () => {
    history.push(`${pathname}/create`);
  };

  const handleEdit = (id) => {
    history.push({
      pathname: `${pathname}/edit/${id}`,
    });
  };

  const handleOpenModal = async (id) => {
    setIsShowModal(true);
    setCompanyIdDelete(id);
    
  };

  const handleDelete = async () => {
    await commonService.deleteCompany(companyIdDelete);
    getListCompany();
    setIsShowModal(false);
  };

  const onCancel = () => {
    setIsShowModal(false);
  }

  const handlePageChange = (newPage) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  useEffect(() => {
    getListCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    let newFilters = {
      ...filters,
    };
    if (companyName) {
      newFilters.name = companyName;
    } else {
      delete newFilters.name;
    }
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  const renderFilter = () => {
    return (
      <div className="mt-3">
        <InputText
          label="label.search"
          isHorizontal
          isFormGroup={false}
          value={companyName}
          onChange={setCompanyName}
          placeholder="filter.placeholder"
        />
      </div>
    )
  };

  return (
    <>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      {i18n.t("screenTitle.companyList")}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={handleCreateCompany}
                      type="button"
                      size="sm"
                    >
                      {i18n.t("register")}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs='8'>
                    { renderFilter() } 
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{i18n.t("companyName")}</th>
                    <th scope="col">{i18n.t("personInCharge")}</th>
                    <th scope="col">{i18n.t("address")}</th>
                    <th scope="col">{i18n.t("action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {listCompany.map((company) => {
                    return (
                      <tr key={company.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {get(company, "attributes.name", "")}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        {get(company, "attributes.profiles") ? (
                          <td>
                            { get(company, "attributes.profiles.data", []).map(
                              (user, index) => {
                                return <div key={index}>{ get(user, "attributes.username") }</div>;
                              }
                            )}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td>{ get(company, "attributes.address", "")}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              right
                            >
                              <DropdownItem
                                // href="#pablo"
                                onClick={(e) => {
                                  handleEdit(company.id);
                                }}
                              >
                                {i18n.t("change")}
                              </DropdownItem>
                              <DropdownItem
                                // href="#pablo"
                                onClick={(e) => {
                                  handleOpenModal(company.id);
                                }}
                              >
                                {i18n.t("delete")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <CustomPagination
                    pagination={pagination}
                    onPageChange={handlePageChange}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <ModalConfirm
        isOpen={isShowModal}
        onCancel={onCancel}
        onDelete={handleDelete}
      />
    </>
  );
};
export default List;
