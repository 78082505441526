import React from "react";
import { Redirect, Route } from "react-router-dom";
import AdminLayout from "../../layouts/Admin"
import Auth from "../../helpers/auth"

function AdminRoute(props) {
  const isAuthenticated = Auth.jwt();
  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated ? <AdminLayout {...props} /> : <Redirect to="/auth/login" />
      }
    />
  );
}
export default AdminRoute;