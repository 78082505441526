
import axios from "axios";

const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');

const baseGraphQL = axios.create({
  baseURL: process.env.REACT_APP_VRADMIN_GRAPHQL || "http://localhost:1337/graphql",
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + token
  }
});

export default baseGraphQL;
