import i18n from "i18n/i18n";
import { Col, FormGroup, Input, Row } from "reactstrap";

const InputDate = ({ onChange, value, label, errors = [] }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };
  return (
    <Row>
      <Col md="12">
        <FormGroup>
          <label className="form-control-label">{i18n.t(label)}</label>
          <Input
            className="form-control-alternative"
            type="date"
            value={value}
            onChange={handleChange}
          />
          <small style={{ color: "#b00" }}>
            {i18n.t(errors[label]).replace("(param1)", i18n.t(label))}
          </small>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default InputDate;
