import UserHeader from "../../../components/Headers/UserHeader";
import { Switch, Redirect, Route } from "react-router-dom";
import List from "./List";
import CreateEdit from "./CreateEdit";
import ImportCSV from "./ImportCSV";

const Locations = () => {
  const pathname = '/admin/location';
  return (
    <>
      <UserHeader />
      <Switch>
        <Route path={`${pathname}/list`} component={List} />
        <Route path={`${pathname}/create`} component={CreateEdit} />
        <Route path={`${pathname}/edit/:id`} component={CreateEdit} />
        <Route path={`${pathname}/import-csv`} component={ImportCSV} />
        <Redirect from="*" to={`${pathname}/list`} />
      </Switch>
    </>
  )
};

export default Locations;
