import UserHeader from "../../../components/Headers/UserHeader";
import { Switch, Redirect, Route } from "react-router-dom";
import List from "./List";
import Register from "./Register";

const Accounts = () => {
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Switch>
        <Route path='/admin/accounts/list' component={List} />
        <Route path='/admin/accounts/register' component={Register} />
        <Route path={`/admin/accounts/edit/:id`} component={Register} />
        <Redirect from='*' to='/admin/accounts/list' />
      </Switch>
    </>
  );
};

export default Accounts;
