import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import UserHeader from "../../../components/Headers/UserHeader";
import List from "./List";
import Detail from "./Detail";

const Hygienes = () => {
  const pathname = "/admin/hygiene";
  return (
    <>
      <UserHeader />
      <Switch>
        <Route path={`${pathname}/list`} component={List} />
        <Route path={`${pathname}/detail/:id`} component={Detail} />
        <Redirect from="*" to={`${pathname}/list`} />
      </Switch>
    </>
  )
}

export default Hygienes;