import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  Col,
  Button,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";

import i18n from "../../../i18n/i18n";
import { CommonService } from "../../../services/common-service";
import { CONSTANT } from "../../../constants/constant";
import Auth from "../../../helpers/auth";
import { ERORR_TYPE } from "../../../constants/errorType";

const pathname = "/admin/brand";

const commonService = new CommonService();

const CreateEditStore = () => {
  const { id } = useParams();
  const history = useHistory();

  const [storeName, setStoreName] = useState("");
  const [locationSelected, setLocationSelected] = useState();
  const [locations, setLocations] = useState([]);
  const [uberEats, setUberEats] = useState({ isDisable: true, value: "" });
  const [demaecan, setDemaecan] = useState({ isDisable: true, value: "" });
  const [didi, setDidi] = useState({ isDisable: true, value: "" });
  const [menu, setMenu] = useState({ isDisable: true, value: "" });
  const [doordash, setDoordash] = useState({ isDisable: true, value: "" });
  const [errorTypes, setErrorTypes] = useState([]);
  const [locationDisable, setLocationDisable] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);

  async function companyLoadOptions(search, loadOptions) {
    let result;
    if (search) {
      result = await commonService.getCompanies({
        populate: "locations",
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getCompanies({
        populate: "locations",
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return handleLoadOption(result);
  };

  const handleSelectCompany = async (props) => {
    setCompanies(handleOptionSelected(props));
    const activeLocation = props.length ? false : true;
    setLocationDisable(activeLocation);
    if (!props.length) {
      setLocations([]);
      setLocationSelected([]);
    }
    setCompaniesSelected(props);
  };

  const locationLoadOptions = async (search, loadOptions) => {
    let results;
    if (search) {
      results = await commonService.getLocations({
        populate: "company",
        "filters[company][id][$in]": companies,
        "filters[name][$contains]": search,
      });
    } else {
      results = await commonService.getLocations({
        populate: "company",
        "filters[company][id][$in]": companies,
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return handleLoadOption(results);
  }

  const handleLoadOption = async (result) => {
    const data = _.get(result, "data.data", "");
    let list = [];
    if (data) {
      data.map(item => {
        list.push({
          value: item["id"],
          label: item["attributes"]["name"]
        });
        return list;
      })
    }
    return {
      options: list,
      hasMore: false,
    };
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    let params = {
      "data": {
        "name": storeName,
        "locations": locations,
        "ubereats_shop_name": uberEats.value,
        "didi_id": didi.value,
        "menu_id": menu.value,
        "doordash_id": doordash.value,
        "demaecan_id": demaecan.value
      }
    };

    const isValid = validate();
    if (isValid) {
      if (!id) {
        handleCreate(params);
      } else {
        handleEdit(id, params);
      }
    }
  };

  const validate = () => {
    let errors = {};
    if (!storeName) {
      errors["storeName"] = ERORR_TYPE.IS_REQUIRED;
    }
    setErrorTypes(errors);
    return _.isEmpty(errors);
  };

  const handleCreate = async (param) => {
    param["data"]["user_created_id"] = Auth.user().id;
    await commonService.createStore(param);
    history.push(`${pathname}/list`);
  };

  const handleEdit = async (id, param) => {
    param["data"]["user_updated_id"] = Auth.user().id;
    await commonService.editStore(id, param);
    history.push(`${pathname}/list`);
  };

  const handleSelectLocation = (props) => {
    setLocations(handleOptionSelected(props));
    setLocationSelected(props);
  };

  const handleOptionSelected = (props) => {
    let selected = [];
    props.map((item) => {
      selected = [...selected, item.value];
      return selected;
    })
    return selected;
  }

  const getStore = async (id) => {
    try {
      const result = await commonService.getStore(
        `${id}?populate=locations`
      );
      const data = _.get(result, "data.data.attributes", {});
      const name = _.get(data, "name");
      setStoreName(name);

      const companies = _.get(data, "companies.data", []);
      if (companies.length) {
        let companyStores = [];
        let companyDefaultState = [];
        companies.map((item) => {
          companyStores.push({
            value: item["id"],
            label: item["attributes"]["name"]
          });
          companyDefaultState.push(item["id"]);
          return companyStores;
        });
        setCompanies(companyDefaultState);
        setCompaniesSelected(companyStores);
      };

      const locations = _.get(data, "locations.data", []);
      if (locations.length) {
        let locationStores = [];
        let locationDefaultState = [];
        locations.map((item) => {
          locationStores.push({
            value: item["id"],
            label: item["attributes"]["name"]
          });
          locationDefaultState.push(item["id"]);
          return locationStores;
        });
        setLocations(locationDefaultState);
        setLocationSelected(locationStores);
        setLocationDisable(false);
      }

      const serviceUberEats = _.get(data, "ubereats_shop_name", "");
      if (serviceUberEats) {
        setUberEats({ value: serviceUberEats, isDisable: false });
      }
      const serviceMenu = _.get(data, "menu_id", "");
      if (serviceMenu) {
        setMenu({ value: serviceMenu, isDisable: false });
      }
      const serviceDemaecan = _.get(data, "demaecan_id", "");
      if (serviceDemaecan) {
        setDemaecan({ value: serviceDemaecan, isDisable: false });
      }
      const serviceDoordash = _.get(data, "doordash_id", "");
      if (serviceDoordash) {
        setDoordash({ value: serviceDoordash, isDisable: false });
      }
      const serviceDidiId  = _.get(data, "didi_id", "");
      if (serviceDidiId) {
        setDidi({ value: serviceDidiId, isDisable: false });
      }

    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  useEffect(() => {
    if (id) {
      getStore(id);
    }
  }, [id]);

  return (
    <Container className="mt--7" fluid>
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">
                    { id ? i18n.t("store.editStore") : i18n.t("store.createStore") }
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form role="form">
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-storeName"
                        >
                          { i18n.t("store.name") }
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          value={storeName}
                          onChange={(e) => setStoreName(e.target.value)}
                        />
                        <small style={{ color: "#b00" }}>{i18n.t(errorTypes["storeName"]).replace("(param1)", i18n.t("store.name"))}</small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-company"
                        >
                          {i18n.t("company")}
                        </label>
                        <AsyncPaginate
                          value={companiesSelected}
                          loadOptions={companyLoadOptions}
                          isMulti="true"
                          key={locations}
                          onChange={(e) => handleSelectCompany(e)}
                        />
                        <small style={{ color: "#b00" }}>
                          {" "}
                          {i18n
                            .t(errorTypes["companies"])
                            .replace("(param1)", i18n.t("company"))}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-baseLocation"
                        >
                          { i18n.t("store.baseLocation") }
                        </label>
                        <AsyncPaginate
                          placeholder={ i18n.t("store.baseLocation") }
                          key={companies}
                          value={locationSelected}
                          isMulti="true"
                          loadOptions={locationLoadOptions}
                          onChange={(props) =>handleSelectLocation(props)}
                          isDisabled={locationDisable}
                        />
                        <small style={{ color: "#b00" }}></small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-appDelivery"
                        >
                          { i18n.t("store.appDelivery") }
                        </label>
                        <div>
                          <FormGroup
                            check
                            inline
                          >
                            <Input
                              type="checkbox"
                              bsSize="lg"
                              checked={!uberEats.isDisable}
                              onChange={(e) => setUberEats({ isDisable: !uberEats.isDisable })}
                            />
                            <Label check size="12">
                              { i18n.t("store.uberEats") }
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input
                              type="checkbox"
                              bsSize="lg"
                              checked={!demaecan.isDisable}
                              onChange={(e) => setDemaecan({ isDisable: !demaecan.isDisable })}
                            />
                            <Label check>
                              { i18n.t("store.demaecan") }
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input
                              type="checkbox"
                              bsSize="lg"
                              checked={!didi.isDisable}
                              onChange={(e) => setDidi({ isDisable: !didi.isDisable })}
                            />
                            <Label check>
                              { i18n.t("store.didi") }
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input
                              type="checkbox"
                              bsSize="lg"
                              checked={!menu.isDisable}
                              onChange={(e) => setMenu({ isDisable: !menu.isDisable })}
                            />
                            <Label check>
                              { i18n.t("store.menu") }
                            </Label>
                          </FormGroup>
                          <FormGroup
                            check
                            inline
                          >
                            <Input
                              type="checkbox"
                              bsSize="lg"
                              checked={!doordash.isDisable}
                              onChange={(e) => setDoordash({ isDisable: !doordash.isDisable })}
                            />
                            <Label check>
                              { i18n.t("store.doordash") }
                            </Label>
                          </FormGroup>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-uberEats"
                        >
                          { i18n.t("store.uberEats") }
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          disabled={uberEats.isDisable}
                          value={uberEats.value}
                          onChange={(e) => setUberEats({ value: e.target.value })}
                        />
                        <small style={{ color: "#b00" }}></small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-demaecan"
                        >
                          { i18n.t("store.demaecan") }
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          disabled={demaecan.isDisable}
                          value={demaecan.value}
                          onChange={(e) => setDemaecan({ value: e.target.value })}
                        />
                        <small style={{ color: "#b00" }}></small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-didi"
                        >
                          { i18n.t("store.didi") }
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          disabled={didi.isDisable}
                          value={didi.value}
                          onChange={(e) => setDidi({ value: e.target.value })}
                        />
                        <small style={{ color: "#b00" }}></small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-menu"
                        >
                          { i18n.t("store.menu") }
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          disabled={menu.isDisable}
                          value={menu.value}
                          onChange={(e) => setMenu({ value: e.target.value })}
                        />
                        <small style={{ color: "#b00" }}></small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-doordash"
                        >
                          { i18n.t("store.doordash") }
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          disabled={doordash.isDisable}
                          value={doordash.value}
                          onChange={(e) => setDoordash({ value: e.target.value })}
                        />
                        <small style={{ color: "#b00" }}></small>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="text-center">
                    { id && (
                    <Button
                      className="mt-4"
                      color="secondary"
                      type="button"
                      onClick={handleCancel}
                    >
                      {i18n.t("cancel")}
                    </Button>
                  ) }
                  <Button
                    className="mt-4"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {i18n.t("save")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Container>
  )
}

export default CreateEditStore;
