import baseGraphQL from "./base";
import _ from "lodash";

export const getUberEatsInactiveTimes = (params) => {
  const arrVradminStoreIds = _.get(params, "arrVradminStoreIds", `[]`);

  // format date[from, to]
  const date = _.get(params, "date", `[]`);
  const pagination = _.get(params, "pagination", "{ limit: -1 }");
  
  const query = `
    query {
      ubereatsInactiveTimes(
        filters: {
          vradmin_store_id: {
            in: ${arrVradminStoreIds}
          },
          date: {
            between: ${date}
          }
        },
        pagination: ${pagination}
      ) {
        data {
          id,
          attributes {
            restaurant_online,
            menu,
            date
          }
        },
        meta {
          pagination {
            total,
            page,
            pageSize,
            pageCount
          }
        }
      }
    }
  `;

  return baseGraphQL.post("", { query: query });
};

export const getUbereatsFeedbackServices = (params) => {

  const arrVradminStoreIds = _.get(params, "arrVradminStoreIds", `[]`);
  const ratingType = _.get(params, "ratingType", "");
  const ratingScore = _.get(params, "ratingScore", "");

  let paramRatingScore = `rating_score: { eq: ${ratingScore} }`;

  // format date[from, to]
  const date = _.get(params, "date", `[]`);
  const pagination = _.get(params, "pagination", "{ limit: -1 }");

  const query = `
    query {
      ubereatsFeedbackServices(
        filters: {
          vradmin_store_id: {
            in: ${arrVradminStoreIds}
          },
          rating_date: {
            between: ${date}
          },
          rating_type: {
            eq: "${ratingType}"
          },
          ${ ratingScore && paramRatingScore }
        },
        pagination: ${pagination}
      ) {
        data {
          id,
          attributes {
            rating_score
            rating_date
          }
        },
        meta {
          pagination {
            total
          }
        }
      }
    }
  `;

  return baseGraphQL.post("", { query: query });
};

export const getUbereatsOrderProblems = (params) => {
  const arrVradminStoreIds = _.get(params, "arrVradminStoreIds", `[]`);
  const orderProblem = _.get(params, "orderProblem", `[]`);

  // format date[from, to]
  const date = _.get(params, "date", `[]`);
  const pagination = _.get(params, "pagination", "{ limit: -1 }");

  const query = `
    query {
      ubereatsOrderProblems(
        filters: {
          vradmin_store_id: {
            in: ${arrVradminStoreIds}
          },
          order_time: {
            between: ${date}
          },
          order_problem: {
            in: ${orderProblem}
          }
        },
        pagination: ${pagination}
      ) {
        data {
          attributes {
            order_time
          }
        },
        meta {
          pagination {
            total
          }
        }
      }
    }
  `;

  return baseGraphQL.post("", { query: query });
};

export const getUbereatsPaymentInfos = (params) => {

  const arrVradminStoreIds = _.get(params, "arrVradminStoreIds", `[]`);

  // format date[from, to]
  const date = _.get(params, "date", `[]`);
  const pagination = _.get(params, "pagination", "{ limit: -1 }");

  const orderStatus = _.get(params, "orderStatus", `[]`);
  const productPriceWithtax = _.get(params, "productPriceWithtax", "");
  let query = "";

  if (productPriceWithtax === 0) {
    query = `
      query {
        ubereatsPaymentInfos(
          filters: {
            vradmin_store_id: {
              in: ${arrVradminStoreIds}
            },
            payment_date: {
              between: ${date}
            },
            product_price_withtax: {
              gt: ${productPriceWithtax}
            }
          }
          pagination: ${pagination}
        ) {
          data {
            attributes {
              product_price_withtax
              payment_date
            }
          }
        }
      }
    `;
  } else {
    query = `
      query {
        ubereatsPaymentInfos(
          filters: {
            vradmin_store_id: {
              in: ${arrVradminStoreIds}
            },
            order_id: { notNull: true },
            order_status: {
              in: ${orderStatus}
            },
            payment_date: {
              between: ${date}
            }
          }
          pagination: ${pagination}
        ) {
          data {
            attributes {
              payment_date
            }
          }
          meta {
            pagination {
              total
            }
          }
        }
      }
    `;
  }
  
  return baseGraphQL.post("", { query: query });
};

export const getUbereatsOrderHistories = (params) => {
  const arrVradminStoreIds = _.get(params, "arrVradminStoreIds", `[]`);

  const prepTimeIncrease = _.get(params, "prepTimeIncrease", "");
  let paramPrepTimeIncrease = `preptime_increase: { gt: ${prepTimeIncrease} }`;

  // format date[from, to]
  const date = _.get(params, "date", `[]`);
  const pagination = _.get(params, "pagination", "{ limit: -1 }");

  const query = `
    query {
      ubereatsOrderHistories(
        filters: {
          vradmin_store_id: {
            in: ${arrVradminStoreIds}
          },
          order_date: {
            between: ${date}
          },
          ${ prepTimeIncrease !== "" ? paramPrepTimeIncrease : "" }
        }
        pagination: ${pagination}
      ) {
        data {
          attributes {
            receive_time
            order_date
          }
        },
        meta {
          pagination {
            total
          }
        }
      }
    }
  `;

  return baseGraphQL.post("", { query: query });
};

export const getUbereatsFeedbackMenus = (params) => {

  const arrVradminStoreIds = _.get(params, "arrVradminStoreIds", `[]`);

  const ratingScore = _.get(params, "ratingScore", "");
  let paramRatingScore = `rating_score: { eq: ${ratingScore} }`;

  // format date[from, to]
  const date = _.get(params, "date", `[]`);
  const pagination = _.get(params, "pagination", "{ limit: -1 }");

  const query = `
    query {
      ubereatsFeedbackMenus(
        filters: {
          vradmin_store_id: {
            in: ${arrVradminStoreIds}
          },
          rating_time: {
            between: ${date}
          },
          ${ ratingScore && paramRatingScore }
        }
        pagination: ${pagination}
      ) {
        data {
          attributes {
            rating_score
            rating_time
          }
        }
        meta {
          pagination {
            total
          }
        }
      }
    }
  `;

  return baseGraphQL.post("", { query: query });
};

export const getDemaecanOrderInfos = (params) => {

  const arrVradminStoreIds = _.get(params, "arrVradminStoreIds", `[]`);

  // format date[from, to]
  const date = _.get(params, "date", `[]`);
  const pagination = _.get(params, "pagination", "{ limit: -1 }");

  const query = `
    query {
      demaecanOrderInfos(
        filters: {
          vradmin_store_id: {
            in: ${arrVradminStoreIds}
          },
          order_total: { ne: "" }
          order_time: {
            between: ${date}
          }
        }
        pagination: ${pagination}
      ) {
        data {
          attributes {
            order_total
            order_time
          }
        }
      }
    }
  `;

  return baseGraphQL.post("", { query: query });
};
