import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Input,
  FormGroup,
} from "reactstrap";
import _ from "lodash";
import { toast } from "react-toastify";

import i18n from "i18n/i18n";
import { ERORR_TYPE } from "../../constants/errorType";
import { CommonService } from "../../services/common-service";

const commonService = new CommonService();

const ModalChangePassword = ({
  isOpen,
  onCancel,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorTypes, setErrorTypes] = useState([]);
  const [errorResponse, setErrorResponse] = useState("");

  const handleChangePassword = async () => {
    const isValid = validate();
    if (isValid) {
      fetchChangePassword();
    }
  };

  const fetchChangePassword = async () => {
    try {
      let params = {
        currentPassword: currentPassword,
        newPassword: newPassword,
        passwordConfirmation: confirmNewPassword
      }
      const res = await commonService.changePassword(params);
      const jwt = _.get(res, "data.jwt", "");
      sessionStorage.setItem('jwt', jwt);
      onCancel();
      toast.success(i18n.t("notificationChangePasswordSuccess"));
    } catch (error) {
      const errorMessage = _.get(error.response.data, "error.message");
      setErrorResponse(errorMessage);
    }
  }

  const validate = () => {
    let errors = {};
    if (!currentPassword) {
      errors["currentPassword"] = ERORR_TYPE.IS_REQUIRED;
    }
    if (!newPassword) {
      errors["newPassword"] = ERORR_TYPE.IS_REQUIRED;
    }
    if (!confirmNewPassword) {
      errors["confirmNewPassword"] = ERORR_TYPE.IS_REQUIRED;
    }
    if (confirmNewPassword !== newPassword) {
      errors["confirmNewPassword"] = ERORR_TYPE.THE_SAME;
    }

    setErrorTypes(errors);
    return _.isEmpty(errors);
  };

  const renderInputPassword = (value, onChange, label, errors = []) => {
    return (
      <Row>
        <Col md="12">
          <FormGroup>
            <label className="form-control-label">{i18n.t(label)}</label>
            <Input
              className="form-control-alternative"
              type={ showPassword ? "text" : "password" }
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
            <small style={{ color: "#b00" }}>
              { i18n.t(errors[label]).replace("(param1)", i18n.t(label)) }
            </small>
          </FormGroup>
        </Col>
      </Row>
    )
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      size="lg"
    >
      <ModalHeader
        toggle={onCancel}
      >
        { i18n.t("changePassword") }
      </ModalHeader>
      <ModalBody>
        <div className="text-center text-muted mb-4">
          <br /><small style={{ color: "#b00" }}>{errorResponse}</small>
        </div>
        { renderInputPassword(currentPassword, setCurrentPassword, "currentPassword", errorTypes) }
        { renderInputPassword(newPassword, setNewPassword, "newPassword", errorTypes) }
        { renderInputPassword(confirmNewPassword, setConfirmNewPassword, "confirmNewPassword", errorTypes) }
        <Row>
          <Col md="12">
            <div className="mb-5 mx-3">
              <Input type="checkbox" value={showPassword} onChange={() => setShowPassword(!showPassword)} /> { i18n.t("showPassword") }
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="mt-4"
          color="secondary"
          type="button"
          onClick={onCancel}
        >
          { i18n.t("cancel") }
        </Button>
        <Button
          className="mt-4"
          color="primary"
          type="button"
          onClick={handleChangePassword}
        >
          { i18n.t("rememberLogin") }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalChangePassword;
