import React, { useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import _ from "lodash";
// reactstrap components
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { CommonService } from "../services/common-service";
import { ROLE } from "../constants/constant";
import 'react-toastify/dist/ReactToastify.css';

const commonService = new CommonService();

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [role, setRole] = useState("");

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.subNav) {
          return prop.subNav.map((item, index) => {
            return (
              <Route
                path={item.layout + item.path}
                key={key + '.' + index}
                render={() => {
                  if (prop.roles.indexOf(role) === -1) {
                    return <Redirect to="/admin/index" />
                  }
                  return <item.component />
                }}
              />
            )
          })
        }
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={() => {
              if (prop.roles.indexOf(role) === -1) {
                return <Redirect to="/admin/index" />
              }
              return <prop.component />
            }}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    let adminRoutes = getListRoutes(routes)
    for (let i = 0; i < adminRoutes.length; i++) {
      if (
        props.location.pathname.indexOf(adminRoutes[i].layout + adminRoutes[i].path) !==
        -1
      ) {
        return adminRoutes[i].name;
      }
    }
    return "Brand";
  };

  const getListRoutes = (routes) => {
    let listRoutes = []
    routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.subNav) {
          prop.subNav.forEach((item, index) => {
            listRoutes.push(item)
          })
        } else {
          listRoutes.push(prop)
        }
      }
      return listRoutes;
    });
    return listRoutes;
  } ;

  const getAdminRoutes =  (routes) => {
    let adminRoutes = []
    
    routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        adminRoutes.push(prop)
      }
      return adminRoutes;
    });
    const privateRoutes = adminRoutes.filter(route => route.roles.indexOf(role) > -1);
    return privateRoutes;
  };

  const [locationIds, setLocationIds] = useState([]);
  const [companyIds, setCompanyIds] = useState([]);

  const getMe = async () => {
    try {
      const res = await commonService.getMe();
      const role = _.get(res, "data.user.role.name", "");
      setRole(role);

      if (role !== ROLE.SUPER_ADMIN) {
        const userId = _.get(res, "data.user.id", "");
        let params = {
          populate: "users_permissions_user, companies, locations",
          "filters[users_permissions_user][id][$eq]": userId,
        };

        const profile = await commonService.getListProfile(params);

        if (role === ROLE.MANAGE_COMPANY) {
          const companies = _.get(profile, "data.data[0].attributes.companies.data", []);
          let arrCompanyIds = [];
          for (let i = 0; i < companies.length; i++) {
            arrCompanyIds = [...arrCompanyIds, companies[i].id];
          }
          setCompanyIds([...arrCompanyIds]);
        }

        if (role === ROLE.MANAGE_LOCATION) {
          const locations = _.get(profile, "data.data[0].attributes.locations.data", []);
          let arrLocationIds = [];
          for (let i = 0; i < locations.length; i++) {
            arrLocationIds = [...arrLocationIds, locations[i].id];
          }
          setLocationIds([...arrLocationIds]);
        }
      }
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <>
      <Sidebar
        {...props}
        routes={role ? getAdminRoutes(routes) : []}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
        role={role}
        locationIds={locationIds}
        companyIds={companyIds}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        { role !== "" && (
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
        ) }
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Admin;
