import _, { get, isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { CommonService } from "../../../services/common-service";
import i18n from "../../../i18n/i18n";
import CustomPagination from "../../../components/Pagination";
import { CONSTANT } from "../../../constants/constant";
import ModalConfirm from "../../../components/Modal/Modal";
import InputAutocomplete from "../../../components/Input/InputAutocomplete";
import InputText from "../../../components/Input/InputText";

const commonService = new CommonService();
const pathname = "/admin/location";

const OPTION_ALL = {
  value: -1,
  label: i18n.t("optionAll"),
};

const List = () => {
  const history = useHistory();
  const [locations, setLocations] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 1,
    pageCount: 2,
    total: 2,
  });

  const [filters, setFilters] = useState({
    pageSize: CONSTANT.PAGE_SIZE_LIMIT,
    page: 1,
  });
  const [isShowModal, setIsShowModal] = useState(false);
  const [locationIdDelete, setLocationIdDelete] = useState("");
  const [companySelected, setCompanySelected] = useState(OPTION_ALL);
  const [locationName, setLocationName] = useState("");

  const getLocations = async () => {
    let param = {
      populate: "company, profiles",
    };
    const { pageSize, page, company, name } = filters;
    param[`pagination[pageSize]`] = pageSize;
    param[`pagination[page]`] = page;

    if (company !== -1) {
      param[`filters[company][id]`] = company;
    }
    if (name) {
      param[`filters[name][$contains]`] = name;
    }

    const result = await commonService.getLocations(param);
    const data = get(result, "data.data", "");
    const meta = get(result, "data.meta.pagination", "");

    setPagination(meta);
    setLocations(data);
  };

  const handleCreateLocation = () => {
    history.push(`${pathname}/create`);
  };

  const handleEditLocation = (id) => {
    history.push({
      pathname: `${pathname}/edit/${id}`,
    });
  };

  const handleImportCSV = () => {
    history.push(`${pathname}/import-csv`);
  };

  const handleOpenModal = (id) => {
    setIsShowModal(true);
    setLocationIdDelete(id);
  }

  const handleDeleteLocation = async () => {
    await commonService.deleteLocation(locationIdDelete);
    await getLocations();
    setIsShowModal(false);
  };

  const onCancel = () => {
    setIsShowModal(false);
  };

  const handlePageChange = (newPage) => {
    const newFilters = {
      ...filters,
      page: newPage,
    }
    setFilters(newFilters);
  };

  const getCompanyByLocation = (location) => {
    return _.get(location, "attributes.company.data.attributes.name", "");
  };

  const getLocationAddress = (location) => {
    const address = _.get(location, "attributes.address");
    const addressStr = _.isNull(address) ? "" : `${address} `;
    const province = _.get(location, "attributes.province");
    const provinceStr = _.isNull(province) ? "" : `${province}`;
    return `${addressStr}${provinceStr}`;
  };

  const getManagersByLocation = (location) => {
    const managers = _.get(
      location,
      "attributes.profiles.data",
      []
    );
    if (managers.length) {
      return managers.map((m) => m.attributes.username).join(", ");
    }
    return "";
  };

  useEffect(() => {
    getLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    let newFilters = {
      ...filters,
    };
    if (companySelected.value) {
      newFilters.company = companySelected.value;
    }
    if (locationName) {
      newFilters.name = locationName;
    } else {
      delete newFilters.name;
    }
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySelected.value, locationName]);

  const formatOptions = (rawOptions) => {
    const data = get(rawOptions, "data.data", []);
    const formattedOptions = data.map((item) => {
      return {
        value: get(item, "id"),
        label: get(item, "attributes.name"),
      };
    });
    return {
      options: [OPTION_ALL, ...formattedOptions],
      hasMore: false,
    };
  };

  const fetchCompanies = async (search) => {
    let result;
    if (search) {
      result = await commonService.getCompanies({
        populate: "locations",
        "filters[name][$contains]": search,
      });
    } else {
      result = await commonService.getCompanies({
        populate: "locations",
        "pagination[limit]": CONSTANT.LIST_LIMIT,
      });
    }
    return formatOptions(result);
  };

  const handleSelectCompany = (company) => {
    setCompanySelected(company);
  };

  const renderFilter = () => {
    return (
      <div className="mt-3">
        <InputAutocomplete
          label="filter.company"
          value={companySelected}
          loadOptions={fetchCompanies}
          onChange={handleSelectCompany}
          isHorizontal
          className="mb-3"
        />
        <InputText
          label="label.search"
          isHorizontal
          isFormGroup={false}
          value={locationName}
          onChange={setLocationName}
          placeholder="filter.placeholder"
        />
      </div>
    );
  };

  return (
    <Container className='mt--7' fluid>
      <Row>
        <div className='col'>
          <Card className='shadow'>
            <CardHeader className='border-0'>
              <Row className='align-items-center'>
                <Col xs='8'>
                  <h3 className='mb-0'>{i18n.t("screenTitle.locationList")}</h3>
                </Col>
                <Col className='text-right' xs='4'>
                  <Button
                    color='success'
                    onClick={handleImportCSV}
                    type='button'
                    size='sm'
                  >
                    {i18n.t("importCSV")}
                  </Button>
                  <Button
                    color='primary'
                    onClick={handleCreateLocation}
                    type='button'
                    size='sm'
                  >
                    {i18n.t("register")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs='8'>{renderFilter()}</Col>
              </Row>
            </CardHeader>
            <Table className='align-items-center table-flush' responsive>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>{i18n.t("locationName")}</th>
                  <th scope='col'>{i18n.t("locationCompany")}</th>
                  <th scope='col'>{i18n.t("locationAddress")}</th>
                  <th scope='col'>{i18n.t("manager")}</th>
                  <th scope='col'>{i18n.t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {locations.map((location) => {
                  return (
                    <tr key={location.attributes.name}>
                      <th scope='row'>
                        <Media className='align-items-center'>
                          <Media>
                            <span className='mb-0 text-sm'>
                              {location.attributes.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{getCompanyByLocation(location)}</td>
                      <td>{getLocationAddress(location)}</td>
                      <td>{getManagersByLocation(location)}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className='btn-icon-only text-light'
                            href='#pablo'
                            role='button'
                            size='sm'
                            color=''
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className='fas fa-ellipsis-v' />
                          </DropdownToggle>
                          <DropdownMenu className='dropdown-menu-arrow' right>
                            <DropdownItem
                              onClick={(e) => {
                                handleEditLocation(location.id);
                              }}
                            >
                              {i18n.t("change")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                handleOpenModal(location.id);
                              }}
                            >
                              {i18n.t("delete")}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <CardFooter className='py-4'>
              <nav aria-label='...'>
                <CustomPagination
                  pagination={pagination}
                  onPageChange={handlePageChange}
                />
              </nav>
            </CardFooter>
          </Card>
        </div>
      </Row>
      <ModalConfirm
        isOpen={isShowModal}
        onCancel={onCancel}
        onDelete={handleDeleteLocation}
      />
    </Container>
  );
};
export default List;
