import i18n from "i18n/i18n";
import { Col, FormGroup, Input, Row } from "reactstrap";

const InputText = ({
  onChange,
  value,
  label,
  errors = [],
  isHorizontal = false,
  isFormGroup = true,
  placeholder = "",
}) => {
  const style = {
    wrapper: "d-flex align-items-baseline",
    label: "w-25",
    input: "w-75",
  };
  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };
  return (
    <Row>
      <Col md='12' className={isHorizontal ? style.wrapper : ""}>
        { isFormGroup ? (
          <FormGroup>
            <label
              className={`form-control-label ${isHorizontal ? style.label : ""}`}
            >
              {i18n.t(label)}
            </label>
            <Input
              className={`form-control-alternative ${isHorizontal ? style.input : ""}`}
              type='text'
              value={value}
              onChange={handleChange}
              placeholder={i18n.t(placeholder)}
            />
            <small style={{ color: "#b00" }}>
              {i18n.t(errors[label]).replace("(param1)", i18n.t(label))}
            </small>
          </FormGroup>
        ) : (
          <>
            <label
              className={`form-control-label ${isHorizontal ? style.label : ""}`}
            >
              {i18n.t(label)}
            </label>
            <Input
              className={`form-control-alternative ${isHorizontal ? style.input : ""}`}
              type='text'
              value={value}
              onChange={handleChange}
              placeholder={i18n.t(placeholder)}
            />
          </>
        ) }
      </Col>
    </Row>
  );
};

export default InputText;
